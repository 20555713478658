import React, { useState,useRef, Component } from 'react';
import { Alert, Dimensions, Image, Pressable, StyleSheet, Text, TextInput, TouchableOpacity, 
    useWindowDimensions, View ,Button,TouchableWithoutFeedback,Keyboard, Platform } from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import FontAwesome from '@expo/vector-icons/FontAwesome';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from 'expo-checkbox';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { globalStyles } from '../../style/globalstyles';
import InternetConnectionAlert from "react-native-internet-connection-alert";
import {
    Appbar , Provider as PaperProvider,
} from 'react-native-paper';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Constant from '../utils/constant';
import { SafeAreaView } from 'react-native-safe-area-context';
const windowHeight = Dimensions.get('window').height
// const dimensionHeight = useWindowDimensions()
import AwesomeAlert from 'react-native-awesome-alerts';
import { useFocusEffect } from '@react-navigation/native';
import i18n from 'i18n-js';
import { zh, en } from '../utils/supportedLanguages';
import *  as api  from "../utils/api.js";
export default function Register ({ navigation,route }) {
    const params = route.params || {};
    const { personDetailsId, personId } = params;
    const dispatch = useDispatch()
    const [toggleCheckBox, setToggleCheckBox] = useState(false)
    const [createUserModel, setCreateUserModel] = useState({
        name: '',
        phoneNumber: '',
        password: '',
        repassword:'',
        // identificationNumber: ''
    })
    const [textInputHolder, settextInputHolder] = useState(0);
    const [captchaHolder, setcaptchaHolder] = useState(0);
    const [randomNumberOne, setrandomNumberOne] = useState(Math.floor(Math.random() * 1000000) + 1);
    const generateCaptcha = () => {
        setrandomNumberOne(Math.floor(Math.random() * 1000000) + 1);   
        setcaptchaHolder(randomNumberOne) ;
      }
      const [msgalert1,setMsgAlert1] = useState(false);
      const [alertMessage, setAlertMessage] = useState("");
      i18n.fallbacks = true;
      i18n.translations = { en, zh };
      //i18n.locale = language;
      const getlanguage= async()=>{
        let lang = await AsyncStorage.getItem('language');
        console.log(lang);
        i18n.locale = lang;
    }
    useFocusEffect(
        React.useCallback(()=>{
           
            getlanguage();
           /** 
            * for farmer app register
            if(Platform.OS === "web"){
                const params = new Proxy(new URLSearchParams(window.location.search), {
                    get: (searchParams, prop) => searchParams.get(prop),
                  });
                  // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
                  let value = params.id;
                  console.log("🚀 ~ file: register.js ~ line 61 ~ React.useCallback ~ value", value)
            }*/
            
            
              
        },[])
    )

    const onRegisterClicked =async () => {
        // navigation.navigate('Admin')
        // if(!isLoggedIn){
        //     navigation.navigate('Login')
        // }
        const msg = await AsyncStorage.getItem('msg')

        const temp = randomNumberOne;
        if(createUserModel.phoneNumber==""){
            setMsgAlert1(true);
            setAlertMessage(i18n.t('Phone_blank'))
           
        }else if(createUserModel.phoneNumber.length <10 || createUserModel.phoneNumber.length > 11){
            
            setMsgAlert1(true);
            setAlertMessage(i18n.t('phonebetween'))
        }else if(createUserModel.name==""){
            setMsgAlert1(true);
            setAlertMessage(i18n.t('icblank'))
           
        }else if(createUserModel.password==""){
            setMsgAlert1(true);
            setAlertMessage(i18n.t('passblank'))
            
        }else if(createUserModel.password.length <6 || createUserModel.password.length >16 ){
            setMsgAlert1(true);
            setAlertMessage(i18n.t('password_register'))
        }else if(createUserModel.repassword!=createUserModel.password){
            setMsgAlert1(true);
            setAlertMessage(i18n.t('passwordnotmatch'))
        }else if (textInputHolder!=temp){
            setMsgAlert1(true);
            setAlertMessage(i18n.t('captcha'))
           
        }else if(!toggleCheckBox){
            setMsgAlert1(true);
            setAlertMessage(i18n.t('user_loan_agreement'))
            
        }else{

            var res = await api.register(createUserModel.phoneNumber,createUserModel.password,createUserModel.name);
            if(res == 200){
                 navigation.navigate('Home');
            }else{
                setMsgAlert1(true);
                setAlertMessage((i18n.t('inuse')))
            }
            console.log("🚀 ~ file: register.js ~ line 141 ~ onRegisterClicked ~ res", res)
            
        }
        generateCaptcha();
    }

    const onHomeClicked = () => {
        navigation.navigate('Login');
    }



    const onTermsOfUsePressed = () => {
        console.warn('onTermsOfUsePressed');
    };

    const onPrivacyPressed = () => {
        console.warn('onPrivacyPressed');
    };

    return (
        <InternetConnectionAlert
        onChange={(connectionState) => {
            console.log("Connection State: ", connectionState);
        }}
        >
            <SafeAreaView style={styles.Container}>
        <TouchableWithoutFeedback onPress={()=>{
            if(Platform.OS !== "web") Keyboard.dismiss();
            console.log('dissmiss');
          }}>
        <View style={styles.body}>
            
            <View style={styles.body}>
        <Appbar.Header style={globalStyles.appHeader}>
            <Appbar.BackAction onPress={onHomeClicked} />
            <Appbar.Content
                    title={(i18n.t('register'))}
                    />
            </Appbar.Header>
            </View>
            <View style={styles.loginContainer}>
                <View style={styles.inputContainer}>
                    <Ionicons name='phone-portrait-outline' size={15} color={'#A0A0A0'} />
                    <TextInput
                        style={styles.input}
                        keyboardType='numeric'
                        value={createUserModel.phoneNumber}
                        placeholder={i18n.t('phoneNum')}
                        onChangeText={(text) => {
                            setCreateUserModel((prevState) => {
                                return { ...prevState, phoneNumber: text }
                            })
                        }} />
                </View>

                <View style={styles.inputContainer}>
                    <FontAwesome name='user-o' size={15} color={'#A0A0A0'} />
                    <TextInput
                        style={styles.input}
                        placeholder={i18n.t('name')}
                        value={createUserModel.name}
                        onChangeText={(text) => {
                            setCreateUserModel((prevState) => {
                                return { ...prevState, name: text }
                            })
                        }} />
                </View>

                <View style={styles.inputContainer}>
                    <Ionicons name='lock-closed-outline' size={15} color={'#A0A0A0'} />
                    <TextInput
                        style={styles.input}
                        placeholder={i18n.t('password_register')}
                        value={createUserModel.password}
                        secureTextEntry={true}
                        onChangeText={(text) => {
                            setCreateUserModel((prevState) => {
                                return { ...prevState, password: text }
                            })
                        }} />
                </View>

                <View style={styles.inputContainer}>
                    <Ionicons name='lock-closed-outline' size={15} color={'#A0A0A0'} />
                    <TextInput
                        style={styles.input}
                        placeholder={i18n.t('rePassword')}
                        value={createUserModel.repassword}
                        secureTextEntry={true}
                        onChangeText={(text) => {
                            setCreateUserModel((prevState) => {
                                return { ...prevState, repassword: text }
                            })
                        }} />
                </View>
  
                <View style={styles.captchaContainerView}>                   
                    <View  style={ styles.captchaChildContainer}>
                    <Icon name='shield-account-outline' size={15} color={'#A0A0A0'} />
                        
                    <TextInput
                        placeholder={i18n.t('verifyCode')}
                        onChangeText={data => settextInputHolder( data )}
                        keyboardType='numeric'
                        style={styles.textInputStyle}
                        underlineColorAndroid='transparent'
                    />
                    </View>

                    
                    <View style={ styles.captchaChildContainer}>
                    <Image
                        style={{ width: 100, height: 60, resizeMode: 'contain' }}
                        source={{ uri: 'https://dummyimage.com/150x40/0091ea/fafafa.png&text=' + randomNumberOne }}
                    />
                    <TouchableOpacity onPress={generateCaptcha} >
                        <Image source={require('../../assets/refresh.png')}
                        style={{ width: 30, height: 25, resizeMode: 'contain' }} />
                    </TouchableOpacity>
                    </View>
                </View>
                <View style={styles.body}>
                <View style={{ backgroundColor:"#ffffff"}}>
                <View style={styles.container}>
                    
                <View style={styles.checkboxContainer}>
                    <Checkbox value={toggleCheckBox} onValueChange={(newValue => setToggleCheckBox(newValue))}/>
             </View>
             <Text style={styles.text}>
                    {i18n.t('term_register')}{' \n'}
                    <Text style={styles.link}onPress={onTermsOfUsePressed}> {i18n.t('term1_register')}</Text> {i18n.t('and')} 
                    <Text style={styles.link}onPress={onPrivacyPressed}> {i18n.t('Privacy_Policy')} </Text>
                    </Text>
                    </View>
         </View>
         </View>
   
                <TouchableOpacity style={styles.loginBtn} onPress={onRegisterClicked}>
                    <Text style={styles.loginText}> {(i18n.t('register'))} </Text>
                </TouchableOpacity>
            </View>

        </View>
        </TouchableWithoutFeedback>
        <AwesomeAlert
          show={msgalert1}
          showProgress={false}
          message={alertMessage}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}

          showConfirmButton={true}
          confirmText={i18n.t('close')}
          confirmButtonColor="#DD6B55"

          onConfirmPressed={() => {
           setMsgAlert1(false)
          }}
        />
        </SafeAreaView>
        </InternetConnectionAlert>
    )
}

const styles = StyleSheet.create({
    Container: {
        flex: 1,
      },
      text: {
        color: '#FFF',
        fontSize: 24,
        textAlign: 'center',
        padding: 5,
      },
      captchaContainerView: {
        flexDirection: 'row',
        flex: 1,
        height: 50,
        borderBottomWidth: 0.5,
        paddingStart: 10
      },
      captchaChildContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
      },
      textInputStyle: {
          flex: 1,
          height: 50,
          paddingStart: 10
      },
      button: {
        width: '80%',
        paddingTop: 2,
        paddingBottom: 2,
        backgroundColor: '#ec407a',
        borderRadius: 3,
        marginTop: 20
      },
    body: {
        flex: 1,
        backgroundColor: '#F7F7F7'
    },
    titleContainer: {
        width: '100%',
        height: 150,
        position: 'relative',
        backgroundColor: '#FEE16E',
        alignItems: 'center',
        fontWeight: 'bold',
        height: 50,
        backgroundColor: '#6200ee',
        alignItems: 'center',
        fontWeight: 'bold',
        bottom: 240,
    },
    title: {
        fontSize: 20,
        color: '#FFFFFF',
        marginTop: 35
    },
    loginContainer: {
        flex: 1,
        width: '95%',
        backgroundColor: '#FFFFFF',
        position: 'absolute',
        alignSelf: 'center',
        justifyContent: 'center',
        top: 100,
    },
    inputContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 10,
        marginVertical: 5
    },
    input: {
        flex: 1,
        height: 50,
        borderBottomWidth: 0.5,
        paddingStart: 10
    },
    uploadIcContainer: {
        marginTop: 10,
        height: 200,
        borderRadius: 10,
        borderColor: '#000000',
        borderWidth: 0.5,
        backgroundColor: '#FEE16E',
        justifyContent: 'center',
        alignItems: 'center'
    },
    imageIC: {
        width: '100%',
        height: undefined,
        maxHeight: 200,
        aspectRatio: 1,
        resizeMode: 'contain'
    },
    registerBtn: {
        marginTop: 5,
        alignSelf: 'flex-end',
        marginEnd: 5,
        color:"#ff0033"
    },
    registerText: {
        fontSize: 13,
        color: '#98A5FD',
        fontWeight: 'bold'
    },
    loginBtn: {
        width: '95%',
        borderRadius: 20,
        borderWidth:1,
        borderColor: '#ff0033',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        marginTop: 20,
        paddingVertical: 10
    },
    loginText: {
        fontSize: 15,
        color: '#ff0033'
    },
    footer: {
        width: '100%',
        position: 'absolute',
        backgroundColor: '#F7F7F7',
        bottom: -50,
        alignItems: 'center'
    },
    footerText: {
        fontSize: 15,
        color: '#FEE16E'
    },
    text: {
        color: 'gray',
        marginVertical:10,
    },
    link: {
        color: '#FDB075'
    },
    container: {
        flexDirection: "row",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
    },
    checkboxContainer: {
        flexDirection: "row",
        padding:10,
    },
    
    checkbox: {
        alignSelf: "center",
    },

})