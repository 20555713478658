import React, { useEffect, useState } from 'react';
import {ImageBackground, Alert, Dimensions, StyleSheet, Text, TextInput, TouchableOpacity, KeyboardAvoidingView, View , Image,TouchableWithoutFeedback,Keyboard, Platform, ScrollView, Pressable  } from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoggedIn } from '../redux/actions';
import * as Constant from '../utils/constant';
import { globalStyles } from '../../style/globalstyles';
import {
    Appbar , Provider as PaperProvider,
} from 'react-native-paper';
import CustomButton from '../components/CustomButton';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import InternetConnectionAlert from "react-native-internet-connection-alert";
import { SafeAreaView } from 'react-native-safe-area-context';
import AwesomeAlert from 'react-native-awesome-alerts';
const windowHeight = Dimensions.get('window').height
import i18n from 'i18n-js';
import { zh, en } from '../utils/supportedLanguages';
import *  as log  from "../utils/api.js";
import * as WebBrowser from 'expo-web-browser';
import { useFocusEffect } from '@react-navigation/native';
import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';
export default function Login({ navigation }) {
    const dispatch = useDispatch()
    const[login,setLogin] = useState(true);
    const [currentLoginModel, setCurrentLoginModel] = useState({
        phoneNumber: '',
        password: ''
    })
    const [msgalert1,setMsgAlert1] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [language,setLanguage] = useState("");
    i18n.fallbacks = true;
    i18n.translations = { en, zh };
    
    const getlanguage= async()=>{
        let lang = await AsyncStorage.getItem('language');
        console.log(lang);
        i18n.locale = lang;
    }
    useFocusEffect(
        React.useCallback(()=>{
            getlanguage();
        },[])
    )

    const onRegisterClicked = () => {
        navigation.navigate('Register')
    }

    const onLoginClicked = async() => {
        const msg = await AsyncStorage.getItem('msg')
        if (currentLoginModel.phoneNumber === '') {
            setMsgAlert1(true);
            setAlertMessage(i18n.t('phonerequired'));
            return
        }

        if (currentLoginModel.password === '') {
            setMsgAlert1(true);
            setAlertMessage(i18n.t('passwordrequired'));
            return
        }
        try{
            var logins = await  log.login(currentLoginModel.phoneNumber,currentLoginModel.password);
            if(logins == 200){
                navigation.navigate(i18n.t('home'));
            }else{
                setMsgAlert1(true);
                setAlertMessage(i18n.t('numbernotexist'))
            }
      }catch(e){
          console.error(e);
      }
    }
    const onHomeClicked = () => {
        navigation.navigate('Root');
    }
    const download= async()=>{
        let result = await WebBrowser.openBrowserAsync('https://www.easycredit2u.com/app/apk');
    }
    const onForgetPasswordClicked = () => {
        navigation.navigate('ForgotPasswordScreen');
    };
    return (
        <InternetConnectionAlert
            onChange={(connectionState) => {
                console.log("Connection State: ", connectionState);
            }}
            >
                 <KeyboardAvoidingView
                behavior={Platform.OS === "ios" ? "padding" : undefined}
                style={{ flex: 1 }}
                keyboardVerticalOffset={Platform.select({ios :120, android : 500})}
                enabled>
                
 <SafeAreaView style={styles.Container}>
        <TouchableWithoutFeedback onPress={()=>{
           if(Platform.OS !== "web") Keyboard.dismiss();
            console.log('dissmiss');
          }}> 
          
        <View style={styles.body}>
        <Appbar.Header style={globalStyles.appHeader}>
            <Appbar.BackAction onPress={onHomeClicked} />
            <Appbar.Content
                    title={i18n.t('Login')}
                    />
            </Appbar.Header>
           
                <View style={styles.childContainer}>
                                <Image style={{flex: 1, 
                                                height: undefined, 
                                                width: undefined,}}
                                        source={require('../../assets/logo1.png')} 
                                />
                </View>
               
                <View style={styles.inputContainer}>
                    <Ionicons name='phone-portrait-outline' size={15} color={'#ff0033'} />
                    <TextInput
                        style={styles.input}
                        keyboardType='numeric'
                        placeholder={i18n.t('phoneNum')}
                        placeholderTextColor={"#606060"}
                        onChangeText={(text) => {
                            setCurrentLoginModel((prevState) => {
                                return { ...prevState, phoneNumber: text }
                            })
                        }} />
                </View>

                <View style={styles.inputContainer}>
                    <Ionicons name='lock-closed-outline' size={15} color={'#ff0033'} />
                    <TextInput
                        style={styles.input}
                        placeholder={i18n.t('password')}
                        secureTextEntry={true}
                        placeholderTextColor={"#606060"}
                        onChangeText={(text) => {
                            setCurrentLoginModel((prevState) => {
                                return { ...prevState, password: text }
                            })
                        }} />
                </View>
                <CustomButton text={i18n.t('Forgot_password')} 
                onPress={onForgetPasswordClicked}
                type="TERTIARY"
                bgColor={''}
                
                />
                <Pressable onPress={download} style={ { width: '35%',padding: 5,paddingRight:10,paddingTop:0,alignSelf: 'flex-end',borderRadius: 5,}}>
                <Text>Download apk</Text>
                </Pressable>

                <TouchableOpacity style={styles.loginBtn} onPress={onLoginClicked}>
                    <Text style={styles.loginText}> {i18n.t('Login')} </Text>
                </TouchableOpacity>

                <TouchableOpacity style={styles.registerBtn} onPress={onRegisterClicked}>
                    <Text style={styles.registerText}> {i18n.t('register')} </Text>
                </TouchableOpacity>
                
            
        </View>
        {/* </ImageBackground> */}
        </TouchableWithoutFeedback>
        <AwesomeAlert
          show={msgalert1}
          showProgress={false}
          message={alertMessage}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}

          showConfirmButton={true}
          confirmText={i18n.t('close')}
          confirmButtonColor="#DD6B55"

          onConfirmPressed={() => {
           setMsgAlert1(false)
          }}
        />
        </SafeAreaView>
        
        </KeyboardAvoidingView>
        </InternetConnectionAlert>
    )
}

const styles = StyleSheet.create({
    body: {
        flex: 1,
    },
    root: {
        alignItems: 'center',
        textAlign: 'center',
        padding:20,
    },
    titleContainer: {
        width: '100%',
        height: 40,
        backgroundColor: 'gold',
        alignItems: 'center',
        fontWeight: 'bold',
    },
    title: {
        fontSize: 20,
        color: 'black',
        marginTop: 5    
    },
    loginContainer: {
        flex: 1,
        width: '95%',
        backgroundColor: '#ff0033',
        alignSelf: 'center',
        justifyContent: 'center',
    },
    inputContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 5,
        marginVertical: 5,
        width: '80%',
        height:'6%',
        borderWidth: 1,
        borderRadius: 30,
        borderColor: '#ff0033',
        justifyContent: 'flex-end',
        alignItems: 'center',
        alignSelf: 'center',
        textShadowColor:'#ff0033',
        marginTop: 10,
        paddingVertical: 10,
        marginEnd: 10,
        
    },
    input: {
        flex: 1,
        height: 50,
        paddingStart: 10,
        color:"#000000"
    },
    registerBtn: {
        width: '45%',
        borderRadius: 20,
        borderWidth: 1,
        borderColor:'#ff0033',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        marginTop: 10,
        paddingVertical: 10,
        marginEnd: 10
    },
    registerText: {
        fontSize: 13,
        color: '#ff0033',
        fontWeight: 'bold'
    },
    loginBtn: {
        width: '45%',
        borderRadius: 20,
        backgroundColor: '#ff0033',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        marginTop: 10,
        paddingVertical: 10,
        marginEnd: 10
    },
    loginText: {
        fontSize: 15,
        color: '#eeeeee',
        fontWeight: 'bold'
    },
    footer: {
        width: '100%',
        position: 'absolute',
        bottom: -30,
        alignItems: 'center'
    },
    footerText: {
        fontSize: 10,
        color: 'black'
    },
    childContainer: {
      flex: 0.9,
    },
    Container: {
        flex: 1,
      },
})