import React,{useState} from 'react';
import { Text, View ,} from 'react-native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import Home from './home';
import Wallet from './wallet';
import Support from './support';
import Me from './me';
import Profile from './profile';
import EditProfile from './editprofile';
import Ionicons from '@expo/vector-icons/Ionicons';
import i18n from 'i18n-js';
import { zh, en } from '../utils/supportedLanguages';
import { useFocusEffect } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';

const Tab = createBottomTabNavigator();

export default function Root(){
    i18n.fallbacks = true;
    i18n.translations = { en, zh };
    
    const [language,setLanguage] = useState("");
    const [languageText,setLanguageText] = useState("");
    const getlanguage = async()=>{
        const lang = await AsyncStorage.getItem('language');
        console.log("🚀 ~ file: root.js ~ line 26 ~ getlanguage ~ lang", lang)
        console.log("me getlanguage",lang);
        if(!lang){
            setLanguageText("English")
            setLanguage('en')
        }
        switch(lang){
            case 'zh':
                setLanguageText("English")
                setLanguage('zh')
                break;
            case 'en':
                setLanguageText("Chinese");
                setLanguage('en')
                break;
            default:
                break;        
        }
        i18n.locale = language;
    }
    useFocusEffect( React.useCallback(() => {
        
        getlanguage();
        
       
    },[]))
    return(
        <Tab.Navigator
        screenOptions={({route}) => ({
            tabBarIcon: ({focused, color, size}) => {
                let iconName
                if (route.name === (i18n.t('home'))) {
                    iconName = 'home-outline'
                } else if (route.name === (i18n.t('wallet'))) {
                    iconName = 'wallet-outline'
                } else if ( route.name === (i18n.t('support'))){
                    iconName = 'people-outline'
                } else if (route.name === (i18n.t('me'))){
                    iconName = 'person-outline'
                }
                return <Ionicons name={iconName} size={size} color={color}/>
            },
            tabBarActiveTintColor:'#ffffff',
            tabBarInactiveTintColor:'#ff0033',
            tabBarInactiveBackgroundColor:"#ffffff",
            tabBarActiveBackgroundColor:"#ff0033",
            headerShown: false,
            tabBarHideOnKeyboard: true
        })}>
            <Tab.Screen name={(i18n.t('home'))} component={Home}/>
            <Tab.Screen name={(i18n.t('wallet'))} component={Wallet}/>
            <Tab.Screen name={(i18n.t('support'))} component={Support}/>
            <Tab.Screen name={(i18n.t('me'))} component={Me}/>
        </Tab.Navigator>
    )
}