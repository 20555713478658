import React, { useState } from 'react';
import { Alert, Dimensions, Image, Pressable, StyleSheet, Text, TextInput, TouchableOpacity, 
    useWindowDimensions, View } from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import FontAwesome from '@expo/vector-icons/FontAwesome';
import { useDispatch, useSelector } from 'react-redux';
import * as ImagePicker from 'expo-image-picker';
import {
    Appbar , Provider as PaperProvider,
} from 'react-native-paper';
import InternetConnectionAlert from "react-native-internet-connection-alert";

import * as Constant from '../../utils/constant'

const windowHeight = Dimensions.get('window').height
// const dimensionHeight = useWindowDimensions()

export default function MobileAuthen({ navigation }) {

    const dispatch = useDispatch()
    const [createUserModel, setCreateUserModel] = useState({
        name: '',
        phoneNumber: '',
        password: '',
        identificationNumber: ''
    })
    const [selectedImage, setSelectedImage] = useState(null)

    const onHomeClicked = () => {
        navigation.navigate('Login');
    }

    const openImagePickerAsync = async () => {
        let permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync()

        if (permissionResult.granted === false) {
            Alert.alert('Permission to access gallery is required')
            return
        }

        let pickerResult = await ImagePicker.launchImageLibraryAsync()

        if (pickerResult.cancelled === true) {
            return
        }

        setSelectedImage({ localUri: pickerResult.uri })
    }

    const renderICfrontView = () => {

        if (selectedImage === null) {
            return <Text style={{ color: '#FFFFFF' }}>Upload your front IC photo here</Text>
        } else {
            console.log(selectedImage.localUri)
            return <Image source={{ uri: selectedImage.localUri }} style={styles.imageIC}></Image>
        }
    }

    const renderICbackView = () => {

        if (selectedImage === null) {
            return <Text style={{ color: '#FFFFFF' }}>Upload your Back IC photo here</Text>
        } else {
            console.log(selectedImage.localUri)
            return <Image source={{ uri: selectedImage.localUri }} style={styles.imageIC}></Image>
        }
    }

    const renderICContainerView = () => {

        if (selectedImage === null) {
            return <Text style={{ color: '#FFFFFF' }}>Upload your hand held IC photo here</Text>
        } else {
            console.log(selectedImage.localUri)
            return <Image source={{ uri: selectedImage.localUri }} style={styles.imageIC}></Image>
        }
    }

    const onUpdateClicked = async () => {
        // dispatch(createNewUser(createUserModel))
        try {
            const result = await fetch(Constant.BASE_API_URL_USER + "create", {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(
                    {
                        name: createUserModel.name,
                        phoneNumber: createUserModel.phoneNumber,
                        password: createUserModel.password,
                        identificationNumber: createUserModel.identificationNumber
                    }
                )
            })
            const json = await result.json()
            if (json) {
                Alert.alert(json.message)
                // if success create account, reset data to clear textinput
                if (json.data) {
                    setCreateUserModel({
                        name: '',
                        phoneNumber: '',
                        password: '',
                        identificationNumber: ''
                    })
                }
            } else {
                Alert.alert("Unable to fetch data")
            }
        } catch (error) {
            console.log(error)
            Alert.alert(error)
        }
    }

    const onTermsOfUsePressed = () => {
        console.warn('onTermsOfUsePressed');
    };

    const onPrivacyPressed = () => {
        console.warn('onPrivacyPressed');
    };

    return (
        <InternetConnectionAlert
        onChange={(connectionState) => {
            console.log("Connection State: ", connectionState);
        }}
        >
        <View style={styles.body}>
            <View style={styles.body}>
        <Appbar.Header>
            <Appbar.BackAction onPress={onHomeClicked} />
            <Appbar.Content
                    title="Update Identity"
                    />
            </Appbar.Header>
            </View>
            <View style={styles.loginContainer}>
                <View style={styles.inputContainer}>
                    <Ionicons name='phone-portrait-outline' size={15} color={'#A0A0A0'} />
                    <TextInput
                        style={styles.input}
                        keyboardType='numeric'
                        value={createUserModel.phoneNumber}
                        placeholder='Please enter the phone number'
                        onChangeText={(text) => {
                            setCreateUserModel((prevState) => {
                                return { ...prevState, phoneNumber: text }
                            })
                        }} />
                </View>

                <View style={styles.inputContainer}>
                    <FontAwesome name='user-o' size={15} color={'#A0A0A0'} />
                    <TextInput
                        style={styles.input}
                        placeholder='Please enter your name'
                        value={createUserModel.name}
                        onChangeText={(text) => {
                            setCreateUserModel((prevState) => {
                                return { ...prevState, name: text }
                            })
                        }} />
                </View>

                <View style={styles.inputContainer}>
                    <FontAwesome name='id-card-o' size={15} color={'#A0A0A0'} />
                    <TextInput
                        style={styles.input}
                        placeholder='Please enter your ic number'
                        value={createUserModel.identificationNumber}
                        keyboardType='numeric'
                        onChangeText={(text) => {
                            setCreateUserModel((prevState) => {
                                return { ...prevState, identificationNumber: text }
                            })
                        }} />
                </View>

                <TouchableOpacity style={styles.uploadIcContainer} onPress={openImagePickerAsync}>
                    {renderICfrontView()}
                </TouchableOpacity>
                <TouchableOpacity style={styles.uploadIcContainer} onPress={openImagePickerAsync}>
                    {renderICbackView()}
                </TouchableOpacity>
                <TouchableOpacity style={styles.uploadIcContainer} onPress={openImagePickerAsync}>
                    {renderICContainerView()}
                </TouchableOpacity>
                <TouchableOpacity style={styles.loginBtn} onPress={onUpdateClicked}>
                    <Text style={styles.loginText}> Update </Text>
                </TouchableOpacity>
            </View>

        </View>
        </InternetConnectionAlert>
    )
}

const styles = StyleSheet.create({
    body: {
        flex: 1,
        backgroundColor: '#F7F7F7'
    },
    titleContainer: {
        width: '100%',
        height: 150,
        position: 'relative',
        backgroundColor: '#FEE16E',
        alignItems: 'center',
        fontWeight: 'bold',
        height: 50,
        backgroundColor: '#6200ee',
        alignItems: 'center',
        fontWeight: 'bold',
        bottom: 240,
    },
    title: {
        fontSize: 20,
        color: '#FFFFFF',
        marginTop: 35
    },
    loginContainer: {
        flex: 1,
        width: '95%',
        backgroundColor: '#FFFFFF',
        position: 'absolute',
        alignSelf: 'center',
        justifyContent: 'center',
        top: 100,
    },
    inputContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 10,
        marginVertical: 5
    },
    input: {
        flex: 1,
        height: 50,
        borderBottomWidth: 0.5,
        paddingStart: 10
    },
    uploadIcContainer: {
        marginTop: 10,
        height: 200,
        borderRadius: 10,
        borderColor: '#000000',
        borderWidth: 0.5,
        backgroundColor: '#FEE16E',
        justifyContent: 'center',
        alignItems: 'center'
    },
    imageIC: {
        width: '100%',
        height: undefined,
        maxHeight: 200,
        aspectRatio: 1,
        resizeMode: 'contain'
    },
    registerBtn: {
        marginTop: 5,
        alignSelf: 'flex-end',
        marginEnd: 5
    },
    registerText: {
        fontSize: 13,
        color: '#98A5FD',
        fontWeight: 'bold'
    },
    loginBtn: {
        width: '95%',
        borderRadius: 20,
        backgroundColor: '#6200ee',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        marginTop: 20,
        paddingVertical: 10
    },
    loginText: {
        fontSize: 15,
        color: '#FFFFFF'
    },
    footer: {
        width: '100%',
        position: 'absolute',
        backgroundColor: '#F7F7F7',
        bottom: -50,
        alignItems: 'center'
    },
    footerText: {
        fontSize: 15,
        color: '#FEE16E'
    },
    text: {
        color: 'gray',
        marginVertical:10,
    },
    link: {
        color: '#FDB075'
    },
    container: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
    },
    checkboxContainer: {
        flexDirection: "row",
        marginBottom: 5,
    },
    
    checkbox: {
        alignSelf: "center",
    },

})