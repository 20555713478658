import React from 'react';
import { View, StyleSheet, Text, TouchableOpacity} from 'react-native';
import {
    Title,
} from 'react-native-paper';
import { SafeAreaView } from 'react-native-safe-area-context';
import {globalStyles} from '../../style/globalstyles';
import {
  Appbar , Provider as PaperProvider,
} from 'react-native-paper';
import AsyncStorage from '@react-native-async-storage/async-storage';

import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import InternetConnectionAlert from "react-native-internet-connection-alert";
import i18n from 'i18n-js';
import { zh, en } from '../utils/supportedLanguages';
import { useFocusEffect } from '@react-navigation/native';
const ProfileScreen = ({navigation}) => {
    i18n.fallbacks = true;
    i18n.translations = { en, zh };
    const getlanguage= async()=>{
        let lang = await AsyncStorage.getItem('language');
        console.log(lang);
        i18n.locale = lang;
    }
    useFocusEffect(
        React.useCallback(()=>{
            getlanguage();
        },[])
    )


  const onEditProfileClicked = () => {
          navigation.push('EditProfileScreen')
      }
      const onMyProfileClicked = () => {
          navigation.push('Root')
      }

      const SignClicked = () => {
        navigation.push('SignScreen')
    }

    const MobileClicked = () => {
        navigation.push('MobileAuthenScreen')
    }

    const IdentityClicked = () => {
        navigation.push('IdentityScreen')
    }

    const DataClicked = () => {
        navigation.push('DataScreen')
    }

    const CashBankClicked = () => {
        navigation.push('CashBankScreen')
    }

    const AdditionalClicked = () => {
        navigation.push('Additional')
    }

      const onHomeClicked = () => {
        navigation.navigate('Root');
      }
        return (
            <InternetConnectionAlert
            onChange={(connectionState) => {
                console.log("Connection State: ", connectionState);
            }}
            >
            <SafeAreaView style={styles.container}>
              <View style={styles.body}>
                <Appbar.Header style={globalStyles.appHeader} > 
                <Appbar.BackAction onPress={onHomeClicked} />
                <Appbar.Content
                    title={i18n.t('My_Profile')}
                    />
            </Appbar.Header>
            <Text style={[styles.Textstyles,{
                                marginTop: 10,
                            }]}>{i18n.t('profile_1')}</Text>
            <TouchableOpacity onPress={IdentityClicked}>
                <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Icon style={{width: 40,
                              height: 40,
                              borderRadius: 40 / 2,
                              backgroundColor:"#87cefa",
                              padding:5,
                              marginTop:8}}  name="card-account-details-outline" color="white" size={30}/>
                        <View style={{marginLeft: 10}}>
                            <Title style={[styles.title,{
                                marginBottom: -5,
                            }]}>{i18n.t('Identity_Information')}</Title>
                            <View style={styles.row}>
                        <Text style={{color:"#777777",fontSize: 12}}>*{i18n.t('profile_2')}</Text>
                    </View>
                        </View>
                    </View>
                </View>
                </TouchableOpacity>
                <TouchableOpacity onPress={DataClicked}>
                <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Icon style={{width: 40,
                              height: 40,
                              borderRadius: 40 / 2,
                              backgroundColor:"#ff6347",
                              padding:5,
                              marginTop:8}}  name="id-card" color="white" size={30}/>
                        <View style={{marginLeft: 10}}>
                            <Title style={[styles.title,{
                                marginBottom: -5,
                            }]}>{i18n.t('Data_Information')}</Title>
                            <View style={styles.row}>
                        <Text style={{color:"#777777",fontSize: 12}}>*{i18n.t('profile_3')}</Text>
                    </View>
                        </View>
                    </View>
                </View>
                </TouchableOpacity>
                <TouchableOpacity onPress={CashBankClicked}>
                <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Icon style={{width: 40,
                              height: 40,
                              borderRadius: 40 / 2,
                              backgroundColor:"#daa520",
                              padding:5,
                              marginTop:8}} name="credit-card-multiple" color="white" size={30}  />
                        <View style={{marginLeft: 10}}>
                            <Title style={[styles.title,{
                                marginBottom: -5,
                            }]}>{i18n.t('bank_card')}</Title>
                            <View style={styles.row}>
                        <Text style={{color:"#777777",fontSize: 12}}>*{i18n.t('profile_4')}</Text>
                    </View>
                        </View>
                    </View>
                </View>
                </TouchableOpacity>
                <TouchableOpacity onPress={SignClicked}>
                <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Icon style={{width: 40,
                              height: 40,
                              borderRadius: 40 / 2,
                              backgroundColor:"#1e90ff",
                              padding:5,
                              marginTop:8}}  name="signature-freehand" color="white" size={30}/>
                        <View style={{marginLeft: 10}}>
                            <Title style={[styles.title,{
                                marginBottom: -5,
                            }]}>{i18n.t('Sign')}</Title>
                            <View style={styles.row}>
                        <Text style={{color:"#777777",fontSize: 12}}>*{i18n.t('profile_5')}</Text>
                    </View>
                        </View>
                    </View>
                </View>
                </TouchableOpacity>   
                {/* <TouchableOpacity onPress={MobileClicked}> */}
                <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Icon style={{width: 40,
                              height: 40,
                              borderRadius: 40 / 2,
                              backgroundColor:"#b8860b",
                              padding:5,
                              marginTop:8}}  name="cellphone-android" color="white" size={30}/>
                        <View style={{marginLeft: 10}}>
                            <Title style={[styles.title,{
                                marginBottom: -5,
                            }]}>{i18n.t('phoneNum_auth')}</Title>
                            <View style={styles.row}>
                        <Text style={{color:"#777777",fontSize: 12}}>*{i18n.t('profile_6')}</Text>
                    </View>
                        </View>
                    </View>
                </View>
                {/* </TouchableOpacity> */}
                <TouchableOpacity onPress={AdditionalClicked}>
                <Text style={styles.Textstyles}>{i18n.t('profile_7')}</Text>
                <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Icon style={{width: 40,
                              height: 40,
                              borderRadius: 40 / 2,
                              backgroundColor:"#20b2aa",
                              padding:5,
                              marginTop:8}}  name="camera" color="white" size={30}/>
                        <View style={{marginLeft: 10}}>
                            <Title style={[styles.title,{
                                marginBottom: -5,
                            }]}>{i18n.t('Additional_materials')}</Title>
                            <View style={styles.row}>
                        <Text style={{color:"#777777",fontSize: 12}}>*{i18n.t('profile_8')}</Text>
                    </View>
                        </View>
                    </View>
                </View>
                </TouchableOpacity>
                      <Text style={styles.headerstyles}>{i18n.t('profile_9')}</Text>
                <TouchableOpacity style={styles.BorrowBtn} onPress={onMyProfileClicked}>
                    <Text style={styles.BorrowText}> {i18n.t('Borrow_now')} </Text>
                </TouchableOpacity>
            {/* </View> */}
            </View>
        </SafeAreaView>
        </InternetConnectionAlert>
        );
 };
// }

 export default ProfileScreen;

 const styles = StyleSheet.create({
    container: {
      flex: 1,
    },
    body: {
      flex: 1,
      backgroundColor: 'white',
    },
    userInfoSection: {
      paddingHorizontal: 10,
      marginBottom: 0,
      backgroundColor: "#ffffff",
      borderBottomColor: '#eeeeee',
      borderBottomWidth: 1,
    },
    title: {
      fontSize: 18
    },
    caption: {
      fontSize: 14,
      lineHeight: 14,
      fontWeight: '500',
    },
    row: {
      flexDirection: 'row',
      marginBottom: 7,
    },
    infoBoxWrapper: {
      borderBottomColor: '#dddddd',
      borderBottomWidth: 1,
      borderTopColor: '#dddddd',
      borderTopWidth: 1,
      flexDirection: 'row',
      height: 100,
    },
    infoBox: {
      width: '50%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    menuWrapper: {
      marginTop: 10,
    },
    menuItem: {
      flexDirection: 'row',
      paddingVertical: 15,
      paddingHorizontal: 30,
    },
    menuItemText: {
      color: '#777777',
      marginLeft: 20,
      fontWeight: '600',
      fontSize: 16,
      lineHeight: 26,
    },
    circle: {
        width: 40,
        height: 40,
        borderRadius: 40 / 2,
        backgroundColor: "#ffcdd2"
    },
    Textstyles: {
        color:"#777777",
        fontSize: 10,
        textAlign: 'center', 
        padding:5,
        backgroundColor: "#eeeeee",
        borderWidth:1,
        borderColor:"#e0e0e0",
        marginLeft:8,
        marginRight:5,
    },
    headerstyles: {
        color:"#777777",
        fontSize: 10,
        textAlign: 'center', 
        padding:5,
    },
    BorrowBtn: {
        width: '70%',
        borderRadius: 30,
        borderWidth:1,
        borderColor: '#ff0033',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        paddingVertical: 10,
        marginEnd: 10,
        marginTop:10
    },
    BorrowText: {
        fontSize: 15,
        color: '#ff0033'
    },

  });

 