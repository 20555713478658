import React, {useState} from 'react';
import {Alert,View , Text, StyleSheet, ScrollView,TouchableOpacity,TextInput} from 'react-native';
import CustomInput from '../../components/CustomInput'
import CustomButton from '../../components/CustomButton'
import { useNavigation } from '@react-navigation/native';
import {
    Appbar , Provider as PaperProvider,
} from 'react-native-paper';
import { globalStyles } from '../../../style/globalstyles';
import InternetConnectionAlert from "react-native-internet-connection-alert";
import { SafeAreaView } from 'react-native-safe-area-context';
import Ionicons from '@expo/vector-icons/Ionicons';
import axios from 'axios';
import AwesomeAlert from 'react-native-awesome-alerts';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Constant from '../../utils/constant';
import i18n from 'i18n-js';
import { zh, en } from '../../utils/supportedLanguages';
const NewPasswordScreen = ({route}) => {
    const [code, setCode] = useState('');
    const [newPassword,setNewPassword] = useState('');
    const [rePassword, setRePassword] =  useState('');
    const navigation = useNavigation();
    const [msgalert1,setMsgAlert1] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    i18n.fallbacks = true;
    i18n.translations = { en, zh };
    const { phone} = route.params;
const onSubmitPressed = async() => {
    if (newPassword === '') {
        setMsgAlert1(true);
        setAlertMessage(i18n.t('newpass'))
        return
    }else if(rePassword === ''){
        setMsgAlert1(true);
        setAlertMessage(i18n.t('renewpass'))
    }else if(newPassword !== rePassword){
        setMsgAlert1(true);
        setAlertMessage(i18n.t('passwordnotmatch'))
    }else if(rePassword !== newPassword){
        setMsgAlert1(true);
        setAlertMessage(i18n.t('passwordnotmatch'))
    }else{
        var ids = await AsyncStorage.getItem("id");
        console.log(phone);
        axios.put(Constant.Base_Api+'api/auth/resets/'+ phone, {
            "password": newPassword,
          }).then((respond)=>{
            console.log(respond.data.message);
            setMsgAlert1(true);
            setAlertMessage("Your password have been change")
           
          }).catch((e)=>{
              console.log(e)
          })
    }
};
const onSigninPress = () => {
    navigation.navigate('Login');
};

const onHomeClicked = () => {
    navigation.navigate('ForgotPasswordScreen')
}

return (
    <InternetConnectionAlert
            onChange={(connectionState) => {
                console.log("Connection State: ", connectionState);
            }}
            >
                <SafeAreaView style={globalStyles.container}>
    <ScrollView showsVerticalScrollIndicator={false}>
         <View style={globalStyles.body}>
        <Appbar.Header style={globalStyles.appHeader}>
            <Appbar.BackAction onPress={onHomeClicked} />
            <Appbar.Content
                    title={(i18n.t('newpassword'))}
                    />
            </Appbar.Header>
            </View>
        <View style = {styles.root}>
            <Text style={styles.title}>{(i18n.t('setnewpass'))}</Text>
        
            <View style={styles.inputContainer}>
                    <Ionicons name='phone-portrait-outline' size={15} color={'#ff0033'} />
                    <TextInput
                        style={styles.input}
                        keyboardType='numeric'
                        placeholder={(i18n.t('enternewpass'))}
                        placeholderTextColor={"#606060"}
                        secureTextEntry={true}
                        onChangeText={(text) => {
                           setNewPassword(text)
                        }} />
                </View>
                <View style={styles.inputContainer}>
                    <Ionicons name='phone-portrait-outline' size={15} color={'#ff0033'} />
                    <TextInput
                        style={styles.input}
                        keyboardType='numeric'
                        placeholder={(i18n.t('reenternewpass'))}
                        placeholderTextColor={"#606060"}
                        secureTextEntry={true}
                        onChangeText={(text) => {
                           setRePassword(text);
                        }} />
                </View>

            <TouchableOpacity style={styles.loginBtn} onPress={onSubmitPressed}>
                    <Text style={styles.loginText}> {(i18n.t('submit'))} </Text>
                </TouchableOpacity>

        </View>
    </ScrollView>
    <AwesomeAlert
          show={msgalert1}
          showProgress={false}
          message={alertMessage}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}

          showConfirmButton={true}
          confirmText={(i18n.t('close'))}
          confirmButtonColor="#DD6B55"

          onConfirmPressed={() => {
           setMsgAlert1(false)
           navigation.navigate('Login');
          }}
        />
    </SafeAreaView>
    </InternetConnectionAlert>
    );
};

const styles = StyleSheet.create({
    root: {
        alignItems: 'center',
    },
    title: {
        fontSize:24,
        fontWeight:'bold',
        color: '#051C60',
        margin: 10,
    },
    text: {
        color: 'gray',
        marginVertical: 10,
    },
    link: {
        color: '#FDB075',
    },
    inputContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 5,
        marginVertical: 5,
        width: '80%',
        height:'6%',
        borderWidth: 1,
        borderRadius: 30,
        borderColor: '#ff0033',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        textShadowColor:'#ff0033',
        marginTop: 10,
        paddingVertical: 10,
        marginEnd: 10
    },
    input: {
        flex: 1,
        height: 50,
        paddingStart: 10,
        color:"#000000"
    },
    loginBtn: {
        width: '50%',
        borderRadius: 20,
        backgroundColor: '#ff0033',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        marginTop: 10,
        paddingVertical: 10,
        marginEnd: 10
    },
    loginText: {
        fontSize: 15,
        color: '#FFFFFF'
    },
});

export default NewPasswordScreen;