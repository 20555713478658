import React, { useState , useEffect } from "react";
import { Pressable, StyleSheet, Text, TouchableOpacity, View ,ScrollView,Alert,FlatList } from "react-native";
import FontAwesome from '@expo/vector-icons/FontAwesome';
import { LinearGradient } from 'expo-linear-gradient';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useFocusEffect } from '@react-navigation/native';
import axios from 'axios';
import * as Constant from '../utils/constant';
import {globalStyles} from '../../style/globalstyles';
import ContentLoader from "react-native-easy-content-loader";
import InternetConnectionAlert from "react-native-internet-connection-alert";
import { SafeAreaView } from "react-native-safe-area-context";
import Withdrawal from "./Screen/WithdrawalScreen";
import AwesomeAlert from 'react-native-awesome-alerts';
import i18n from 'i18n-js';
import { zh, en } from '../utils/supportedLanguages';
import { Button } from "react-native-paper";
import { LogBox } from 'react-native';
import *  as api  from "../utils/api.js";
export default function Wallet({navigation}) {
    const[username,SetUsername] = useState();
    const[login,setLogin] = useState(true);
    const [bankAcc, setBankAcc] = useState();
    const [loading, setLoading] = useState(false);
    const [totalDepo, setTotalDepo] = useState("");
    const [wd,setWd] = useState("");
    const [date, setDate] = useState(null);
    const [Month, setMonth] = useState(null);
    const[withdraw,setWithdraw] = useState("");
    const [msgalert1,setMsgAlert1] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [Listwithdrawl,setListWithdraw] = useState();
    const[Listdepo, setListDepo] = useState();

    i18n.fallbacks = true;
    i18n.translations = { en, zh };

    const getData = async () => {
       
        try {
        const value = await AsyncStorage.getItem('id');
        const name = await AsyncStorage.getItem('name');
        let lang = await AsyncStorage.getItem('language');
        console.log("🚀 ~ file: wallet.js ~ line 44 ~ getData ~ lang", lang)
        console.log(lang);
        i18n.locale = lang;
        setLogin(true)
        if(value !== null) {
            console.log(value);
            if(name !== null){
                console.log(name)
                SetUsername(name)
            }
        }else{ 
            setLogin(false)
        }
        } catch(e) {
        // error reading value
        }
    }
    useEffect(() => {
        LogBox.ignoreLogs(['VirtualizedLists should never be nested']);
    }, [])

    useEffect(() => {
        const today = new Date();
        const date = today.getDate();
        setDate(date);
        const Month = (today.getMonth()+1)
        setMonth(Month);
      }, []);
    

    const GridListItems =  [
        { key: "Skptricks" },
        { key: "Sumit"  },
        { key: "Amit"  },
        { key: "React" },
        { key: "React Native"},
        { key: "Java"},
      ]

      const onTranClicked = () => {
            navigation.navigate('Transaction')
    }

    const onLoanClicked = () => {
        if(!login){
            setMsgAlert1(true);
            setAlertMessage(i18n.t('PleaseLoginFirst'))
            //Alert.alert('Please Login First')
        }
        else{
            navigation.navigate('Payslip')
        }
    }

    const onChatClicked = () => {
        if(!login){
            setMsgAlert1(true);
            setAlertMessage(i18n.t('PleaseLoginFirst'))
            //Alert.alert('Please Login First')
        }
        else{
            let data = "deposit";
            AsyncStorage.setItem('depo',data )
            navigation.navigate('Support')
            
        }
    }

    const onWithdrawalClicked = () => {
        if(!login){
            setMsgAlert1(true);
            setAlertMessage(i18n.t('PleaseLoginFirst'))
           // Alert.alert('Please Login First')
        }
        else{
            navigation.navigate('Withdrawal')
        }
    }
    /**  get 4 digit back account num
    const fetchLoanDataFromApi = async () => {
        try {   
          var ids = await AsyncStorage.getItem("id");
          if(ids != null){
            const response = await axios.get(
                Constant.Base_Api + "api/userprofile/" + ids + "/acc"
              );
              if (response.status === 200) {
                //console.log("sss",response.data);
                if(response.data.message !== "No Data"){
                   
                    var acc = response.data.userAccount;
                    console.log(acc.toString().slice(-4))
                    setBankAcc(acc.toString().slice(-4));
                }
              } else {
                throw new Error("Failed to fetch users");
              }
          }
        } catch (error) {
          console.log(error);
        }
      };*/
      const FetchDepoCal = async () => {
        try {
          console.log("okay depo masuk");
          var ids = await AsyncStorage.getItem("id");
          if(ids != null){
            const res  = await api.cal_loan();
            setWithdraw(res);
          }else{
              setWithdraw(0)
          }
        } catch (error) {
          console.log(error);
        }
      };
      const transsaction = async()=>{
          try {
            var ids = await AsyncStorage.getItem("id");
            console.log("🚀 ~ file: wallet.js ~ line 163 ~ transsaction ~ ids", ids)
            if(ids != null){
                const list_depo = await api.depoList();
                const list_withdraw = await api.withdrawList();
                const i = list_withdraw.concat(list_depo)
                const u = i.sort((a, b) => (b.createdAt > a.createdAt) ? 1 : -1)
                const a = u.slice(0,3)
                setListDepo(a);
                setListWithdraw(a)
            }
            setLoading(false);
          } catch (error) {
              
          }
      }
      const listofdepo= ()=>{
        let i  = [];
        Listdepo.map((e)=>{
            if(e.DepositeAmount){
                i.push(e)
            }
        })
        // console.log("listoddepo",i)
        setListWithdraw(i)
      }
      const listofwith = ()=>{
        let i  = [];
        Listdepo.map((e)=>{
            if(e.amount){
                i.push(e)
            }
        })
        // console.log("listoddepo",i)
        setListWithdraw(i)
      }
      const listall = () =>{
          setListWithdraw(Listdepo)
      }
   
    useFocusEffect(
        React.useCallback(() => {
           // setLoading(true);
            getData();
            // /fetchLoanDataFromApi()
            FetchDepoCal();
            transsaction();
            console.log('wallet is focused')
        },[])
    )
    return (
        <InternetConnectionAlert
        onChange={(connectionState) => {
            console.log("Connection State: ", connectionState);
        }}
        >
        <SafeAreaView style={styles.container}>
        <ScrollView>
                        <View style={globalStyles.Header}>
                <Text style={globalStyles.Headtitle}> {i18n.t('wallet')} </Text></View>
                <ContentLoader active loading={loading}>

            <View style={styles.firstView}>
                {/* <View style={globalStyles.userContainer}>
                    <FontAwesome name="user-circle" size={40} color={'#ff0033'} />
                    {login && <Text style={globalStyles.textAccount}>{(username == "" )? "Account": username}{"\n"}
                    </Text>}
                </View> */}

                <View style={styles.loanContainer}>
                    <Text style={styles.textBalanceNum}>RM {(withdraw)?withdraw:  "0"}.00</Text>
                    <Text style={styles.textBalance}>{i18n.t('Balance')}</Text>
                    <TouchableOpacity style={styles.btnLoan} onPress={onWithdrawalClicked}>
                    <Text style={styles.textBtn}> {i18n.t('Withdrawal')}</Text>
                </TouchableOpacity>
                </View>

                {/* <View style={styles.totalContainer}>
                    <View style={styles.totalItemContainer}>
                        <Text style={{ fontSize: 20,color:"#ff0033" }}>0.00</Text>
                        <Text style={{ marginTop: 5,color:"#ff0033"}}>{i18n.t('Amount_Due')}</Text>
                    </View>
                   
                </View> */}
                <View style={styles.totalContainer}>
                <TouchableOpacity style={styles.btnWithdraw}
                    onPress={onChatClicked}>
                    <Text style={styles.textWithdraw}>{i18n.t('Deposit')}</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.btnWithdraw}
                    onPress={onLoanClicked}>
                    <Text style={styles.textWithdraw}>{i18n.t('Upload_Slip')}</Text>
                </TouchableOpacity>
                </View>
            </View>

            {/* <View style={styles.secondView}>
                <Text style={styles.textViewTitle}>{i18n.t('Loan_progress')}</Text>
                <Text style={styles.textLoan}>{i18n.t('No_loans')}</Text>
            </View> */}

            { login &&
                <View style={{padding:10}}>
                {/* <LinearGradient
                    style={styles.bankCardContainer}
                    colors={['#ff0033', '#df1b1b','#ce2525']}
                    start={{ x: 0, y: 0 }}
                    end={{ x: 1, y: 0 }}>
                        <FontAwesome name="credit-card-alt" color={'#ffffff'} size={35}style={{ margin: 15,marginBottom:5}}></FontAwesome>
                        <Text style={styles.textCardNum}> **** **** **** {bankAcc}</Text>
                        <Text style={{fontSize:12,color:"#ffffff",marginLeft:15}}>{i18n.t('CARD_HOLDER')}</Text>
                        <View style={globalStyles.row}>
                            <View style={{width:"80%"}}>
                        <Text style={{fontSize:16,color:"#ffffff",marginLeft:15,marginTop:5,fontWeight: "bold"}}>{(username == "" )? "Account": username}</Text>
                        </View>
                        <View style={{ width: "30%" }}>
                        <FontAwesome name="cc-mastercard" color={'#ffffff'} size={35}></FontAwesome>
                        </View>
                        </View>
                </LinearGradient> */}
            
            
            <View style={{flex:1.0,width: '100%',borderWidth:1,borderColor:"#ff0033",borderRadius:10}}>
            <Text style={{marginTop:5,color:"#ff0033",fontSize:16,fontWeight:"bold",marginLeft:10,borderBottomColor:"#ff0033",borderBottomWidth:1,width:"45%"}}> {i18n.t('Recent')} </Text>
            <View style={{ width: '90%', marginBottom: 5 ,flexDirection:"row"}}>
            <TouchableOpacity style={{flexDirection:'row',margin:10,backgroundColor:"#ffffff",borderRadius:5,shadowColor: "#000",
            shadowOffset: {width: 0,height: 8,},shadowOpacity: 0.44,shadowRadius: 10.32,elevation: 16,}} onPress={listall} >
                <Text style={{fontWeight: 'bold',margin:5,marginLeft:10,marginRight:10}}>{i18n.t('all')}</Text>
            </TouchableOpacity>
                
            <TouchableOpacity style={{flexDirection:'row',margin:10,backgroundColor:"#ffffff",borderRadius:5,shadowColor: "#000",shadowOffset: {width: 0,height: 8,},
            shadowOpacity: 0.44,shadowRadius: 10.32,elevation: 16,}} onPress={listofdepo}>
              <FontAwesome name="circle" color={"#00ff00"} size={10} style={{marginTop:10,marginLeft:10}}></FontAwesome>
              <Text style={{fontWeight: 'bold',margin:5,marginRight:10,color:"#00ff00"}}> {i18n.t('income')} </Text>
            </TouchableOpacity>
            <TouchableOpacity style={{flexDirection:'row',margin:10,backgroundColor:"#ffffff",borderRadius:5,shadowColor: "#000",shadowOffset: {width: 0,height: 8,},
            shadowOpacity: 0.44,shadowRadius: 10.32,elevation: 16,}} onPress={listofwith}>
              <FontAwesome name="circle" color={"#ff0033"} size={10} style={{marginTop:10,marginLeft:10}}></FontAwesome>
              <Text style={{fontWeight: 'bold',margin:5,marginRight:10,color:"#ff0033"}}> {i18n.t('expense')} </Text>
            </TouchableOpacity>
            </View>
            <FlatList
            data={ Listwithdrawl }
            renderItem={({ item }) => (
                <View style={{ flex: 1, flexDirection: 'row', margin: 10,marginTop:0,backgroundColor: '#ffffff',borderWidth:0,borderColor:"#ff0033",borderRadius:5,shadowColor: "#000",
                shadowOffset: {width: 0,height: 8,},shadowOpacity: 0.44,shadowRadius: 10.32,elevation: 16,}}>

            <View style={{flexDirection:"column",width: "70%"}}>
                <Text style={[styles.GridViewInsideTextItemStyle,{width: "100%",fontWeight: 'bold',marginLeft:10,fontSize:20}]} >{ username} </Text>
                <Text style={{width: "100%",fontWeight: 'bold',marginLeft:10,fontSize:16}} >**** **** **** {bankAcc} </Text>
            </View>
            <View style={{flexDirection:"column",width: "40%"}}>
                <View style={{flexDirection:"row"}}>
                <Text style={[{color:"#028a0f" , width: "75%",textAlign:"right" ,paddingRight:15,fontSize:16,fontWeight: 'bold'},(item.amount)? styles.withdrawal: styles.depo]}>{(item.amount)?"-"+item.amount:"+"+item.DepositeAmount}</Text>
                </View>
                <Text style={{color:"#808080" , width: "75%",textAlign:"right" ,paddingRight:15,fontWeight: 'bold',fontSize:10}}>{   item.createdAt.slice(0,10) }</Text>
                <Text style={{color:"#808080" , width: "75%",textAlign:"right" ,paddingRight:15,fontWeight: 'bold',fontSize:10}}>{item.createdAt.slice(10).slice(0,-3)}</Text>
               
                </View>
            </View>
            )}
            //Setting the number of column
            keyExtractor={(item, index) => index}      />
            <TouchableOpacity onPress={onTranClicked}><Text style={{alignSelf:"center",padding:10,color:"#ff0033",fontSize:14,fontWeight:"bold"}}>{ i18n.t("showmore")} </Text></TouchableOpacity>
             </View>
            </View>}

            <View style={[globalStyles.footer,{marginTop:5}]}>
                <FontAwesome 
                name="shield" color={'#00A992'}>
                    <Text style={{color:'#000000'}}>{i18n.t('Account_fund_security')}</Text>
                </FontAwesome>
            </View>
            </ContentLoader>
            <AwesomeAlert
          show={msgalert1}
          showProgress={false}
          message={alertMessage}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}

          showConfirmButton={true}
          confirmText={(i18n.t('close'))}
          confirmButtonColor="#DD6B55"

          onConfirmPressed={() => {
           setMsgAlert1(false)
          }}
        />
        </ScrollView>
        </SafeAreaView>
        </InternetConnectionAlert>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#F7F7F7'
    },
    firstView: {
        width: '100%',
        backgroundColor: '#FFFFFF',
        alignItems: 'center',
        padding: 10
    },
    loanContainer: {
        width: '100%',
        borderRadius: 10,
        backgroundColor: '#ff0033',
        paddingVertical: 10,
        alignItems: 'center',

        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 8,
        },
        shadowOpacity: 0.46,
        shadowRadius: 11.14,

        elevation: 17,
    },
    textBalanceNum: {
        fontSize: 20,
        color: '#FFFFFF'
    },
    textBalance: {
        color: '#FFFFFF',
        marginTop: 5,
    },
    textRecentLoan: {
        color: '#FFFFFF'
    },
    totalContainer: {
        width: '100%',
        flexDirection: 'row',
        justifyContent:'center',
        marginTop: 20
    },
    totalItemContainer: {
        alignItems: 'center',
        width: '20%',
        marginHorizontal: 30,
    },
    btnWithdraw: {
        width: '40%',
        borderRadius: 20,
        padding: 5,
        marginTop: 5,
        borderWidth: 1,
        marginLeft:15,
        marginRight:15,
        borderColor:'#ff0033',
        alignItems: 'center'
    },
    textWithdraw: {
        fontSize: 15,
        color: '#ff0033',
        fontWeight: 'bold',
    },

    secondView: {
        width: '100%',
        backgroundColor: '#FFFFFF',
        marginTop: 10,
        padding: 10
    },
    textViewTitle: {
        fontWeight: 'bold',
        fontSize: 15,
    },
    textLoan: {
        color: '#BDBBC2',
        marginTop: 5,
        alignSelf: 'center'
    },
    bankCardContainer: {
        marginTop: 10,
        borderRadius: 10,
        height:150,
    },
    textCardNum: {
        margin: 15,
        marginBottom:5,
        marginTop:5,
        fontSize:24,
        color: '#FFFFFF'
    },
    textBtn: {
        fontSize: 15,
        color: '#ffffff',
        fontWeight: 'bold'
    },
    btnLoan: {
        width: '70%',
        borderRadius: 20,
        padding: 5,
        marginTop: 20,
        borderWidth: 1,
        borderColor:'#ffffff',
        alignItems: 'center'
    },
    depo:{
        color:"#00ff00"
    },
    withdrawal:{
        color: "#ff0033"
    },
})