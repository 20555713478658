import React, { useState, useRef, useEffect } from "react";
import {
  Alert,
  Dimensions,
  Image,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  Button,
  View,
} from "react-native";
import Ionicons from "@expo/vector-icons/Ionicons";
import FontAwesome from "@expo/vector-icons/FontAwesome"
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { useDispatch, useSelector } from "react-redux";
import { Appbar, Provider as PaperProvider } from "react-native-paper";
import axios from "axios";
import * as Constant from "../../utils/constant";
import { useFocusEffect } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import ContentLoader from "react-native-easy-content-loader";
import { globalStyles } from "../../../style/globalstyles";
import InternetConnectionAlert from "react-native-internet-connection-alert";
import { SafeAreaView } from "react-native-safe-area-context";
import i18n from 'i18n-js';
import { zh, en } from '../../utils/supportedLanguages';
import AwesomeAlert from 'react-native-awesome-alerts';
import * as api from '../../utils/api';

const windowHeight = Dimensions.get("window").height;
// const dimensionHeight = useWindowDimensions()

export default function Withdrawal({ navigation }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [withdrawalamount, setWithdrawalamount] = useState("");
  const [withdrawal, setWithdrawal] = useState("");
  const [bankname,setbankname] = useState("");
  const [bankacc,setbankacc] = useState("");
  const [accnumber,setaccnumber] = useState("");
  const [msgalert1,setMsgAlert1] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  i18n.fallbacks = true;
  i18n.translations = { en, zh };

  
  const onHomeClicked = () => {
    navigation.navigate(i18n.t('wallet'));
  };

  const onUpdateClicked = async () => {
    if(withdrawalamount === ""){
      setMsgAlert1(true);
      setAlertMessage(i18n.t('withrequired'));
      // Alert.alert(i18n.t('withrequired'));
      return;
    }else if(withdrawalamount >withdrawal ){
      setMsgAlert1(true);
      setAlertMessage(i18n.t('withbalance'));
      // Alert.alert(i18n.t('withbalance'))
    }else {
        setLoading(true);
        console.log("okay masuk post");
        var ids = await AsyncStorage.getItem('id');
      try {
        var res = await api.p_withdraw(withdrawalamount);
        if(res ==200){
          setLoading(false);
          setMsgAlert1(true);
          setAlertMessage(i18n.t('Complete'));
          // Alert.alert("Complete"); 
          navigation.navigate("Wallet")
          //navigation.navigate('Home');
        }else{
          console.log("error",res);
        }
      } catch (err) {
       console.log(err);    
      }
    } 
  };

  const fetchLoanDataFromApi = async () =>{
    try {
        console.log("okay masuk")
        var ids = await AsyncStorage.getItem('id');
        const response = await api.userdetail_bank();
        
        console.log(response);
        if(!response.userCompanyname){
            
            Alert.alert(i18n.t('updatedetail'));
            navigation.push('DataScreen')
        }
        setbankname(response.BankName)
        setaccnumber(response.BankAccount)
        setLoading(false);
        
    } catch (error) {
        console.log(error)
    }
    
}

const fetchNameFromApi = async () => {
    try {
      console.log("okay masuk");
      var ids = await AsyncStorage.getItem("id");
      const response = await api.user()
      setbankacc(response.name)
      setLoading(false);  
    } catch (error) {
      console.log(error);
    }
  };


const getData = async () => {
    try {
    const value = await AsyncStorage.getItem('id')
    if(value !== null) {
       setID(value);
    }
    } catch(e) {
    // error reading value
    }
}

  const FetchDepoCal = async () => {
    try {
      var ids = await AsyncStorage.getItem("id");
      const res  = await api.cal_loan();
      setWithdrawal(res);
    } catch (error) {
      console.log(error);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      //setLoading(true);
      FetchDepoCal();
      console.log("Withdrawal is focused");
      fetchLoanDataFromApi();
      getData();
      fetchNameFromApi();
    }, [])
  );

  return (
    <InternetConnectionAlert
    onChange={(connectionState) => {
        console.log("Connection State: ", connectionState);
    }}
    >
      <SafeAreaView style={globalStyles.container}>
    <View style={globalStyles.body}>
      <Appbar.Header style={globalStyles.appHeader}>
        <Appbar.BackAction onPress={onHomeClicked} />
        <Appbar.Content title={i18n.t('Upload_Slip')} />
      </Appbar.Header>
      <ContentLoader active loading={loading}>
      <View style={globalStyles.bodycontainer}>
      <View style={styles.inputContainer}>
          <Ionicons name="server-outline" size={15} color={"#A0A0A0"} />
          <Text
            style={{marginLeft:10,color:"#000000"}}>RM {(withdrawal)?withdrawal:  "0"}.00</Text>
        </View>
        <View style={styles.inputContainer}>
          <Ionicons name="cash-outline" size={15} color={"#A0A0A0"} />
          <TextInput
            style={styles.input}
            keyboardType="numeric"
            placeholder={i18n.t('WithdrawalAmount')}
            onChangeText={(text) => {
              setWithdrawalamount(text);
            }}
          />
        </View>

        <Text style={{marginLeft:10,color:"#000000"}}>{i18n.t('bankname')} </Text>
        <View style={{flexDirection:"row",alignSelf:"flex-end", paddingHorizontal: 10,marginVertical: 5,width: "80%",borderRadius:5,backgroundColor:"#bdbdbd", marginRight:10}}>
          <Text
            style={{color:"#000000"}}>{(bankname)?bankname:  "Please update in My Profile"}</Text>
        </View>

        <Text style={{marginLeft:10,color:"#000000"}}>{i18n.t('accountnumber')} </Text>
        <View style={{flexDirection:"row",alignSelf:"flex-end", paddingHorizontal: 10,marginVertical: 5,width: "80%",borderRadius:5,backgroundColor:"#bdbdbd", marginRight:10}}>
          <Text
            style={{color:"#000000"}}>{(accnumber)?accnumber:  "Please update in My Profile"}</Text>
        </View>
        <Text style={{marginLeft:10,color:"#000000"}}>{i18n.t('accountname')} </Text>
        <View style={{flexDirection:"row",alignSelf:"flex-end", paddingHorizontal: 10,marginVertical: 5,width: "80%",borderRadius:5,backgroundColor:"#bdbdbd", marginRight:10}}>
          
          <Text
            style={{color:"#000000"}}>{(bankacc)?bankacc:  "Please update in My Profile"}</Text>
        </View>

        <TouchableOpacity style={globalStyles.outlineBtn} onPress={onUpdateClicked}>
          <Text style={globalStyles.outlineText}> {i18n.t('submit')}</Text>
        </TouchableOpacity>
      </View>
      </ContentLoader>
    </View>
    <AwesomeAlert
          show={msgalert1}
          showProgress={false}
          message={alertMessage}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}

          showConfirmButton={true}
          confirmText={i18n.t('close')}
          confirmButtonColor="#DD6B55"

          onConfirmPressed={() => {
           setMsgAlert1(false)
          }}
        />
    </SafeAreaView>
    </InternetConnectionAlert>
  );
}

const styles = StyleSheet.create({
  body: {
    flex: 1,
    backgroundColor: "#F7F7F7",
    marginTop: 20,
  },
  titleContainer: {
    width: "100%",
    height: 150,
    position: "relative",
    backgroundColor: "#FEE16E",
    alignItems: "center",
    fontWeight: "bold",
    height: 50,
    backgroundColor: "#6200ee",
    alignItems: "center",
    fontWeight: "bold",
    bottom: 240,
  },
  title: {
    fontSize: 20,
    color: "#FFFFFF",
    marginTop: 35,
  },
  userInfoSection: {
    paddingHorizontal: 70,
    marginBottom: 0,
    backgroundColor: "#ffffff",
    borderBottomColor: "#eeeeee",
    borderBottomWidth: 1,
  },
  row: {
    flexDirection: "row",
    marginBottom: 7,
  },
  loginContainer: {
    flex: 1,
    width: "95%",
    backgroundColor: "#FFFFFF",
    position: "absolute",
    alignSelf: "center",
    justifyContent: "center",
    top: 100,
  },
  inputContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 10,
    marginVertical: 5,
    width: "100%",
  },
  input: {
    flex: 1,
    height: 50,
    borderBottomWidth: 0.5,
    paddingStart: 10,
  },
  uploadIcContainer: {
    borderWidth:1,
    borderColor:"#ff0033",
    borderRadius:10,
    width:200,
    height:200,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    margin:20,
    padding:50
  },
  imageIC: {
    width: "200%",
    height: undefined,
    maxHeight: 200,
    aspectRatio: 1,
    resizeMode: "contain",
  },
  registerBtn: {
    marginTop: 5,
    alignSelf: "flex-end",
    marginEnd: 5,
  },
  registerText: {
    fontSize: 13,
    color: "#98A5FD",
    fontWeight: "bold",
  },
  loginBtn: {
    width: "95%",
    borderRadius: 20,
    borderWidth: 1,
    borderColor: "#ff0033",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    marginTop: 20,
    paddingVertical: 10,
  },
  loginText: {
    fontSize: 15,
    color: "#ff0033",
  },
  updateText: {
    fontSize: 15,
    color: "#ffffff",
  },
  footer: {
    width: "100%",
    position: "absolute",
    backgroundColor: "#F7F7F7",
    bottom: -50,
    alignItems: "center",
  },
  footerText: {
    fontSize: 15,
    color: "#FEE16E",
  },
  text: {
    color: "gray",
    marginVertical: 10,
  },
  link: {
    color: "#FDB075",
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  checkboxContainer: {
    flexDirection: "row",
    marginBottom: 5,
  },

  checkbox: {
    alignSelf: "center",
  },
  chooseBtn: {
    borderWidth: 1,
    padding: 3,
    borderColor: "#ff0033",
    backgroundColor: "#ff0033",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    alignSelf: "flex-start",
    borderRadius: 10,
    justifyContent:"center"
  },
  updateBtn: {
    borderWidth: 1,
    padding: 3,
    borderColor: "#ff0033",
    backgroundColor: "#ff0033",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    alignSelf: "flex-end",
    borderRadius: 5,
  },
});
