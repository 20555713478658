import React, { useEffect, useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View ,Pressable,Alert,Image, Platform} from "react-native";
import FontAwesome from '@expo/vector-icons/FontAwesome';
import MaterialIcon from '@expo/vector-icons/MaterialIcons';
import { useFocusEffect } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import InternetConnectionAlert from "react-native-internet-connection-alert";
import { SafeAreaView } from 'react-native-safe-area-context';
import axios from "axios";
import * as Constant from "../utils/constant";
import AwesomeAlert from 'react-native-awesome-alerts';
import * as Localization from 'expo-localization';
import i18n from 'i18n-js';
import { zh, en } from '../utils/supportedLanguages';
import *  as api  from "../utils/api.js";


export default function My({navigation}) {
    
    const[login,setLogin] = useState(true);
    const [username,setUsername] = useState();
    const[loanstatus,setLoanStatus] = useState(true);
    const[withdraw, setWithdraw] = useState();
    const [withdrawSta, setWithdrawSta] = useState(true);
    const [msgalert1,setMsgAlert1] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [language,setLanguage] = useState("");
    const [languageText,setLanguageText] = useState("");
        const onSignInClicked = () => {
        navigation.push('Login')
    }
    i18n.fallbacks = true;
    i18n.translations = { en, zh };
    i18n.locale = language;
    const getData = async () => {
        try {
        const value = await AsyncStorage.getItem('id');
        const name = await AsyncStorage.getItem('name');
        console.log("getdata me",name)
        setLogin(true)
        if(value !== null) {
            console.log(value);
           
            if(name !== null){
                
                setUsername(name)
            }
        }else{ 
            setLogin(false)
        }
        } catch(e) {
        }
    }

    const logout = async () =>{
        try {
         
            // await AsyncStorage.removeItem('id');
            // await AsyncStorage.removeItem('name');
            await AsyncStorage.clear();
            setUsername();
            navigation.navigate(i18n.t('home'));

          } catch(e) {
            console.log(e)
          }
    }

    const onProfileClicked = () => {
        if(!login){
            setMsgAlert1(true);
            setAlertMessage(i18n.t('PleaseLoginFirst'))
        }
        else{
            navigation.push('ProfileScreen')
        }
    }

    const onDetailClicked = () => {
        console.log("loana"+loanstatus);
        console.log("login me", login)
        if(!login){
            setMsgAlert1(true);
            setAlertMessage(i18n.t('PleaseLoginFirst'))
        }else if(!loanstatus){
            setMsgAlert1(true);
            setAlertMessage(i18n.t('No_loans'))
        }
        else{
            navigation.push('loanDetails')
        }
    }

    const onRepayment = () => {
        if(!login){
            setMsgAlert1(true);
            setAlertMessage(i18n.t('PleaseLoginFirst'))
        }else if(!loanstatus){
            setMsgAlert1(true);
            setAlertMessage(i18n.t('No_loans'))
        }else{
            navigation.navigate('Repayment');
        }
       
    }

    const onChangeLang = () => {
        console.log("me language",languageText)
        switch(languageText){
            case "Chinese":
                AsyncStorage.setItem('language',"zh" );
                setLanguageText("English");
                setLanguage('zh');
                navigation.navigate(i18n.t('home'));
                break;
            case "English":
                AsyncStorage.setItem('language',"en")
                setLanguageText("Chinese");
                setLanguage('en');
                navigation.navigate(i18n.t('home'));
                break;
            default:
                break;        
        }
        
    }

    const onWithdrawal = () => {
        if(!login){
            setMsgAlert1(true);
            setAlertMessage(i18n.t('PleaseLoginFirst'))
        }else if(!withdrawSta){
            setMsgAlert1(true);
                setAlertMessage(i18n.t('notwithdrawal'))
        }else{
            navigation.push('myWithdrawal')
        }
    }
    const fetchLoanDataFromApi = async () => {
        try {
          console.log("okay masuk");
    
          var ids = await AsyncStorage.getItem("id");
          if(ids !=null){
            const response = await api.listofloan();
            //console.log("ss",response.data);
            if(response.message === "No Data"){
              setLoanStatus(false)
            }else{
                setLoanStatus(true);
            }
          
          }
         
        } catch (error) {
          console.log(error);
        }
      };
      const fetchWithdrawData = async()=>{
          try{
            var ids = await AsyncStorage.getItem("id");
            if(ids != null){
                const res = await api.withdraw();
                if(res.message === "No Data"){
                    setWithdrawSta(false);
                }else{
                    setWithdrawSta(true)
                }
            }else{
                setWithdrawSta(true)
            }
            
          }catch (errors){
            console.log(errors)
          }
      }
      const FetchDepoCal = async () => {
        try {
          console.log("okay depo masuk");
          var ids = await AsyncStorage.getItem("id");
          if(ids != null){
            const res  = await api.cal_loan();
            setWithdraw(res);
          }else{
              setWithdraw(0)
          }
        } catch (error) {
          console.log(error);
        }
      };
    const getlanguage = async()=>{
        const lang = await AsyncStorage.getItem('language');
        console.log("me getlanguage",lang);
        if(!lang || lang == null){
        console.log("🚀 ~ file: me.js ~ line 174 ~ getlanguage ~ lang", lang)
            
            setLanguageText("Chinese")
            setLanguage('en')
        }else{
            switch(lang){
                case 'zh':
                    setLanguageText("English")
                    setLanguage('zh')
                    break;
                case 'en':
                    setLanguageText("Chinese");
                    setLanguage('en')
                    break;
                default:
                    break;        
            }
        }
        
    }
    useFocusEffect(
        React.useCallback(() => {
            console.log('my is focused')
            getData();
            fetchLoanDataFromApi();
            fetchWithdrawData();
            console.log("me username", username)
            getlanguage();
            FetchDepoCal();
           
        },[])
    )


    return (
        <InternetConnectionAlert
        onChange={(connectionState) => {
            console.log("Connection State: ", connectionState);
        }}
        >
        <SafeAreaView style={styles.container}>
            <View style={styles.header}>
                <Text style={styles.title}>{i18n.t('me')}</Text>
            </View>

            <View style={styles.profileContainer}>
                <View style={styles.userContainer}>
                <FontAwesome
                    name="user-circle" color={'#ff0033'} size={40} marginStart={20}></FontAwesome>
                        <View style={styles.userContainer}>
                        <Pressable>
                     <Text style={styles.textAccount}>{(username == "" )? "Account": username}
                     </Text>
                     </Pressable>
                        {!login && 
                            <Pressable onPress={onSignInClicked}>
                            <Text style={styles.loginAccount}>{i18n.t('signIn')}</Text>
                        </Pressable>}
                    </View>
        
                </View>
                <View style={styles.balanceContainer}>
                <Text style={styles.textBalance}>{i18n.t('Withdrawable_balance')}: <Text style={{ fontWeight: 'bold' }}> {withdraw}</Text></Text>
            </View>
                {/* <View style={styles.profileActionContainer}>
                    <FontAwesome name="money" size={20}>
                        <Text style={styles.textAction}> Coupon</Text>
                    </FontAwesome>
                    <FontAwesome name="gear" size={20}>
                        <Text style={styles.textAction}> Set up</Text>
                    </FontAwesome>
                </View> */}
            </View>

  

            <View style={styles.actionContainer}>
                <TouchableOpacity onPress={ onProfileClicked}>
                    <View style={styles.actionItemContainer}>
                        <View style={styles.iconContainer}>
                            <FontAwesome name="home" color={'#ff0033'} size={25}></FontAwesome>
                        </View>
                        <View style={styles.textActionContainer}>
                            <Text style={styles.textAction}>{i18n.t('My_Profile')}</Text>
                        </View>
                        <View style={styles.arrowContainer}>
                            <MaterialIcon name="keyboard-arrow-right" color={'#ff0033'} size={25} />
                        </View>
                    </View>
                </TouchableOpacity>

                <TouchableOpacity onPress={ onDetailClicked}>
                    <View style={[styles.actionItemContainer]}>
                        <View style={styles.iconContainer}>
                            <FontAwesome name="cny" color={'#ff0033'} size={25}></FontAwesome>
                        </View>
                        <View style={styles.textActionContainer}>
                            <Text style={styles.textAction}>{i18n.t('My_Loan')}</Text>
                        </View>
                        <View style={styles.arrowContainer}>
                            <MaterialIcon name="keyboard-arrow-right" color={'#ff0033'} size={25} />
                        </View>
                    </View>
                </TouchableOpacity>

                <TouchableOpacity onPress={ onRepayment}>
                    <View style={styles.actionItemContainer} >
                        <View style={styles.iconContainer}>
                            <FontAwesome name="circle-o" color={'#ff0033'} size={25}></FontAwesome>
                        </View>
                        <View style={styles.textActionContainer}>
                            <Text style={styles.textAction}>{i18n.t('My_Repayment')}</Text>
                        </View>
                        <View style={styles.arrowContainer}>
                            <MaterialIcon name="keyboard-arrow-right" color={'#ff0033'} size={25} />
                        </View>
                    </View>
                </TouchableOpacity>

                <TouchableOpacity onPress={ onWithdrawal}>
                    <View style={styles.actionItemContainer} >
                        <Image
                            source={require('../../assets/withdrawal-icon.png')}
                            style={styles.iconContainer}
                            />

                        <View style={styles.textActionContainer}>
                            <Text style={styles.textAction}>{i18n.t('My_Withdrawal')}</Text>
                        </View>
                        <View style={styles.arrowContainer}>
                            <MaterialIcon name="keyboard-arrow-right" color={'#ff0033'} size={25} />
                        </View>
                    </View>
                </TouchableOpacity>

            </View>

            <View style={styles.actionContainer}>
                <TouchableOpacity onPress={ onChangeLang}>
                    <View style={styles.actionItemContainer}>
                        <View style={styles.iconContainer}>
                            <MaterialIcon name="translate" color={'#ff0033'} size={25} />
                        </View>
                        <View style={styles.textActionContainer}>
                            <Text style={styles.textAction}>{i18n.t('me_lang')} {languageText}</Text>
                        </View>
                        <View style={styles.arrowContainer}>
                            <MaterialIcon name="keyboard-arrow-right" color={'#ff0033'} size={25} />
                        </View>
                    </View>
                </TouchableOpacity> 

                <TouchableOpacity onPress={ logout}>
                    <View style={styles.actionItemContainer}>
                        <View style={styles.iconContainer}>
                            <MaterialIcon name="help" color={'#ff0033'} size={25} />
                        </View>
                        <View style={styles.textActionContainer}>
                            <Text style={styles.textAction}>Logout</Text>
                        </View>
                        <View style={styles.arrowContainer}>
                            <MaterialIcon name="keyboard-arrow-right" color={'#ff0033'} size={25} />
                        </View>
                    </View>
                </TouchableOpacity>
            </View>
            <AwesomeAlert
          show={msgalert1}
          showProgress={false}
          message={alertMessage}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showConfirmButton={true}
          confirmText={i18n.t('close')}
          confirmButtonColor="#DD6B55"

          onConfirmPressed={() => {
           setMsgAlert1(false)
          }}
        />
        </SafeAreaView>
        </InternetConnectionAlert>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#F7F7F7'
    },
    header: {
        height: (Platform.OS == "web")? 160: 150,
        backgroundColor: '#ff0033',
        position: 'relative',
        padding:5
    },
    title: {
        fontWeight: 'bold',
        color:"#ffffff",
        fontSize:20,
        alignSelf: 'center'
    },
    profileContainer: {
        position: 'absolute',
        backgroundColor: '#FFFFFF',
        width: '90%',
        marginTop: 80,
        paddingTop: 10,
        paddingBottom: 20,
        paddingStart: 20,
        paddingEnd: 20,
        marginStart: 20,
        borderRadius: 10,
        shadowColor: "#FFFFFF",
        shadowOffset: {
            width: 0,
            height: 8,
        },
        shadowOpacity: 0.46,
        shadowRadius: 11.14,

        elevation: 17,
    },
    
    border:{
        borderRightWidth: 1,
    },
    profileActionContainer: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        marginTop: 20,
    },
    textAction: {
        fontSize: 15,
    },

    balanceContainer: {
        backgroundColor: '#FFFFFF',
        height: 30,
        marginTop:5,
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
    },
    textBalance: {
        fontSize: 15,
    },

    actionContainer: {
        backgroundColor: '#FFFFFF',
        paddingTop:20,
        padding: 10
    },
    actionItemContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 10
    },
    textActionContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'flex-start',
        marginStart: 5
    },
    textAction: {
        color: '#000000',
        fontSize: 15,
    },
    iconContainer: {
        flex: 0.1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    arrowContainer: {
        flex: 0.1,
        alignItems: 'flex-end'
    },
    loginAccount: {
        fontSize:16,
        color:"#000000",
        marginTop:8,
        fontWeight:"bold",
    },
    textAccount: {
        marginStart: 10,
        fontSize:20,
        color:"#000000",
        fontWeight:"bold",
        marginTop:5,
    },
    userContainer: {
        width: '100%',
        flexDirection: 'row',
    },
})