import React, { useState } from "react";
import {
  Alert,
  Image,
  Modal,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { Appbar, Provider as PaperProvider } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";
import Checkbox from "expo-checkbox";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { useFocusEffect } from "@react-navigation/native";
import * as Constant from "../utils/constant";
import InternetConnectionAlert from "react-native-internet-connection-alert";
import AwesomeAlert from 'react-native-awesome-alerts';
import i18n from 'i18n-js';
import { zh, en } from '../utils/supportedLanguages';
import { globalStyles } from "../../style/globalstyles";
import *  as api  from "../utils/api.js";

export default function LoanPreview({ navigation, route }) {
  const [toggleCheckBox, setToggleCheckbox] = useState(false);
  const { loanAmount, loanTerm, monthlyRepayment, interest } = route.params;
  const [showModal, setShowModal] = useState(false);
  const [bankName,setBankName] = useState("");
  const [bankAcc, setBankAcc] = useState("");
  const [msgalert1,setMsgAlert1] = useState(false);
  const [language,setLanguage] = useState("");
  i18n.fallbacks = true;
  i18n.translations = { en, zh };
    const [alertMessage, setAlertMessage] = useState("")
  const onBtnClicked = async() => {
    console.log("bank "+bankAcc + bankName);
    if (!toggleCheckBox) {
      setMsgAlert1(true);
            setAlertMessage("Please check the checkbox")
      return;
    }else{
    try {
      var ids = await AsyncStorage.getItem("id");
      const lang = await AsyncStorage.getItem('language');
      let yesterday = new Date();
      let id_order = i18n.strftime(yesterday, "%y%m%d%H%M%S");
      var p = await api.p_loan(loanAmount,loanTerm,parseFloat(monthlyRepayment).toFixed(2),interest,id_order);
      if(p ==200){
        navigation.push('loanDetails')
      }
  
    } catch (e) {
      console.error(e);
    }
  }
  };

  const fetchLoanDataFromApi = async () => {
    try {
      console.log("okay masuk");

      var ids = await AsyncStorage.getItem("id");
      const response = await api.userdetail_bank();
        console.log("bank",response);
        if(response.message === "No Data"){
          Alert.alert(i18n.t('updatebankaccount'));
          navigation.navigate('Home');
        }else{
        setBankAcc(response.BankName);
        setBankName(response.BankAccount);
        }
        console.log("response.data");
      
    } catch (error) {
      console.log(error);
    }
  };
  useFocusEffect(
    React.useCallback(() => {
      console.log("bankscreen is focused");
      fetchLoanDataFromApi();
    }, [])
  );
  const onHomeClicked = () => {
    navigation.navigate("Root");
  };

  return (
    <InternetConnectionAlert
            onChange={(connectionState) => {
                console.log("Connection State: ", connectionState);
            }}
            >
    <SafeAreaView style={styles.container}>
    <Appbar.Header style={globalStyles.appHeader}>
          <Appbar.BackAction onPress={onHomeClicked} />
          <Appbar.Content title={(i18n.t('applyloan'))} />
        </Appbar.Header>
      
      <View style={{ paddingStart: 10 }}>
        <Text style={{ paddingVertical: 10 }}>{i18n.t('confirmloan')}</Text>
        <View style={{ flexDirection: "row", marginTop: 5 }}>
          <Text style={styles.detailText}>{i18n.t('loanAmount')}</Text>
          <Text style={styles.detailText}>{loanAmount}</Text>
        </View>
        <View style={{ flexDirection: "row", marginTop: 5 }}>
          <Text style={styles.detailText}>{i18n.t('loanterm')}</Text>
          <Text style={styles.detailText}>{loanTerm}</Text>
        </View>
        <View style={{ flexDirection: "row", marginTop: 5 }}>
          <Text style={styles.detailText}>{i18n.t('Monthly_Repayment')}</Text>
          <Text style={styles.detailText}>RM {(monthlyRepayment).toFixed(2)}</Text>
        </View>
        <View style={{ flexDirection: "row", marginTop: 5 }}>
          <Text style={styles.detailText}>{i18n.t('bankaccount')}</Text>
          <Text style={styles.detailText}>{bankName}</Text>
        </View>
        <View style={{ flexDirection: "row", marginTop: 5 }}>
          <Text style={styles.detailText}>{i18n.t('bankname')}</Text>
          <Text style={styles.detailText}>{bankAcc}</Text>
        </View>
      </View>
      <View style={{ flexDirection: "row", marginTop: 20, marginStart: 10 }}>
        <Checkbox
          value={toggleCheckBox}
          onValueChange={(newValue) => setToggleCheckbox(newValue)}
        />
        <Text> {i18n.t('confirmed')}</Text>
      </View>
      <TouchableOpacity style={styles.btnSubmitLoan} onPress={onBtnClicked}>
        <Text style={{ color: "#ff0033" }}> {i18n.t('submitloan')}</Text>
      </TouchableOpacity>
      <AwesomeAlert
          show={msgalert1}
          showProgress={false}
          message={alertMessage}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}

          showConfirmButton={true}
          confirmText="Close"
          confirmButtonColor="#DD6B55"

          onConfirmPressed={() => {
           setMsgAlert1(false)
          }}
        />
    </SafeAreaView>
    </InternetConnectionAlert>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  detailText: {
    flex: 0.5,
  },
  btnSubmitLoan: {
    borderRadius: 10,
    borderColor: "#ff0033",
    borderWidth:1,
    padding: 10,
    marginTop: 20,
    width: "80%",
    alignSelf: "center",
    alignItems: "center",
  },
});
