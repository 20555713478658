const en = {
  welcomeback: 'Welcome back, ',
  hello: 'Hello',
  signIn: 'Sign In',
  MaxAmountLoan: ' Maximum loan amount ',
  loanAmount: 'Loan Amount',
  fill_the_Info: 'Fill in the information',
  quick_loan: 'Quick Loan',
  Monthly_Repayment: 'Monthly Repayment',
  user_loan_agreement: `Agree to the User Service Agreement and \n Loan Agreement`,
  Loan_immediately: 'Loan immediately',
  Joined: 'Joined',
  Member: 'Member',
  Loaned_out: 'Loaned out',
  Cumulative_transaction_amount : 'Cumulative transaction amount',
  Withdrawable_balance: 'Withdrawable balance',
  My_Profile: 'My Profile',
  My_Loan: 'My Loan',
  My_Repayment: 'My Repayment',
  My_Withdrawal: 'My Withdrawal',
  phoneNum: 'Please enter the phone number',
  password: 'Please enter the password',
  Forgot_password :'Forgot password?',
  name:'Please enter your IC full name',
  password_register: 'Please set 6-16 digit password',
  rePassword: 'Please Re-enter password',
  verifyCode: 'Enter verification code',
  term_register:'By registering, you confirm that you accept our',
  term1_register:'Terms of Use',
  Privacy_Policy: 'Privacy Policy',
  and: "and",
  Balance:"Balance",
  Withdrawal:'Withdrawal',
  notwithdrawal:'You not have any withdraw',
  Amount_Due: 'Amount Due',
  Deposit: 'Deposit',
  Upload_Slip: 'Upload payslip',
  Loan_progress: 'Loan progress',
  No_loans: 'You not have any loan',
  CARD_HOLDER: 'CARD HOLDER',
  Account_fund_security : 'Account fund security',
  profile_1: 'It only takes 3 minutes to complete the information verification and you can apply for a loan~',
  Identity_Information: 'Identity Information',
  profile_2: 'Let us know your basic situation',
  Data_Information: 'Data Information',
  profile_3: 'Let us know your profile information',
  bank_card: 'Cash withdrawal bank card',
  profile_4 :'Withdrawal bank card cannot be modified once submitted',
  Sign:'Sign',
  profile_5: 'Sign a user contract',
  phoneNum_auth:'Mobile phone number authentication',
  profile_6:'Authenticate your mobile phone number',
  profile_7:'Supplementary information can increase the chance of passing the review',
  Additional_materials: 'Additional materials',
  profile_8:'Increase your chances of passing the review',
  profile_9:'I have read and agreed to sign the "Personal Consumption Loan Contract","Personal Creadit Report Inquiry Authorization Letter", and agree to submit loan-related information to the basic database of financial credit information (the Prople Bank of Malaysia Credit information System)',
  Borrow_now: ' Borrow now',
  Login:'Login',
  register:'Register',
  Phone_blank:'Phone Number cannot be blank',
  PleaseLoginFirst:'Please Login First',
  madeloan:'You have a loan in progress',
  agreement:'Please agree to the User Service Agreement and Loan Agreement',
  home:'Home',
  wallet:'Wallet',
  support:'Support',
  me:'Me',
  updatebankaccount:'Please update bank account and name.',
  applyloan:'Apply for a loan',
  confirmloan:'Confirm loan information',
  loanterm:'Loan term',
  bankaccount:'Bank account',
  receivingaccount:'Receiving account',
  confirmed:'I have confirmed',
  submitloan:'Submit loan application',
  service:'Customer Service',
  uploadpayslip:'Please Upload your Payslip',
  uploadedpayslip:'The Payslip was uploaded',
  permissiongallery:'Permission to access gallery is required',
  paysliprequired:'Payslip are required',
  depositerequired:'Deposite required',
  remarkrequired:'Remark required',
  phonerequired:'Phone Number required',
  namerequired:'Name required',
  companyrequired:'Company Name required',
  positionrequired:'Position required',
  addressrequired:'Address required',
  incomerequired:'Monthly Income required',
  identifirequired:'Identification Number required',
  icnumber:'The Ic number must be 12 digits',
  submit:'Submit',
  Depositamount: 'Deposit Amount',
  remark:'Remark',
  complete:'Complete',
  WithdrawalList:'Withdrawal List',
  WithdrawalAmount:'Withdrawal Amount',
  completeupdate:'Update Succesfully',
  enteryouric:'Please enter your IC number',
  updateidentity:'Update Identity',
  uploadfrontic:' Please Upload your front IC ',
  uploadbackic:' Please Upload your Back IC ',
  uploadfaceic:' Please Upload your IC with face ',
  Choosefile:'Choose file',
  uploadincomedocument:"Upload Income Documents",
  entercompany:'Please enter Company Name',
  enterposition:'Please enter your Position',
  enteraddress:'Please enter your Address',
  enterincome:'Please enter your Monthly Income',
  uploadsalary:" Upload your Salary Slip photo here ",
  uploadbill:'Upload your Utilities Bill photo here',
  bankrequired:'Bank Name required',
  bankaccountrequired:'Bank Account Number required',
  updatedetail:'Please update company detail',
  bankaccountdetail:"Bank Account Details",
  bankname:'Bank Name',
  enterbank:'Please enter your Bank Name',
  enterbankaccount:'Please enter your Bank Account number',
  update:'Update',
  document:'Update Documents',
  uploaddocument:'Upload your Document',
  underreview:"Under review",
  pending:"Pending",
  processing:"Payment Processing",
  loansuccessfully:"Loan successfully",
  borrowingresult:"Borrowing results",
  loanprocessing:'Loan progress',
  Tips:'Remark',
  tipresult:'Your loan application has been submitted,please take the intiative to contact',
  order:'Order number',
  contract:'View contract',
  loandetail:'Loan Details',
  paymentsuccessfully:'Payment successfully',
  withdrawalresult:'Withdrawal results',
  withprogress:'Withdrawal progress',
  repaymentdetail:'Repayment Details',
  withamount:'Withdrawal Amount',
  passwordrequired:"Password is required",
  numbernotexist:"Phone number or password are wrong",
  wrongpass:"Wrong password",
  cannotlogin:'User Cannot Login.',
  alertmessage:"Alert Message",
  close:'Close',
  registercomplete:'Register complete',
  inuse:'Phone number registered',
  passwordnotmatch:'Password do not match',
  passblank:'Password cannot be blank',
  icblank:'IC full name cannot be blank',
  phonebetween:'Phone number must between 10 and 11',
  captcha:"Captcha NOT Matched",
  ICrequired:'Ic Number are required',
  forgotpassword:'Forgot Password',
  send:'Send',
  newpass:'NewPassword required',
  renewpass:'Re-enter Password required',
  newpassword:"New Password",
  setnewpass:'Set your new password',
  enternewpass:'Please Enter The New Password',
  reenternewpass:'Please Re-enter The Password',
  withrequired:"Withdrawal Amount are required",
  withbalance:'The withdraw amount exceed the balance',
  loandate:'Loan Date',
  totalamount:'Loan Total Amount',
  amountpermonth:'Loan repayment amount per month',
  tenureoftheloan:'Interest per annum',
  duedate:'Due Date',
  repayment:'My Repayment',
  paynow:'Pay now',
  amount:'Repayment Amount',
  uploadtran:'Upload Transfer Slip',
  repayrequired:"Repayment Amount are required",
  repaymonth:'Select Repaying Month',
  repaypic: 'Slip repayment are required',
  Recent:'Recent Transactions',
  all:'All',
  income:'Income',
  expense:'Expense',
  me_lang: "Change Language to",
  ic_fail:"Failed! Ic is already in use!",
  ic_update: "Please update your Ic",
  accountname:"Account Name",
  accountnumber:"Account Number",
  transaction:"Recent Transaction",
  exist:"The loan amount has exceeded",
  startdate:'Start Date',
  enddate:'End Date',
  outstanding:'LOAN OUTSTANDING',
  upcomingpayment:'Payments Schedule',
  viewhistory:'View History',
  duenext:'Due next',
  paid:'Paid',
  fromloan:'from loan amount of',
  personal:'Personal Loan',
  upcoming:'Upcoming',
  showmore: 'Show more',
};

const zh = {
  welcomeback: '欢迎回来, ',
  hello:'你好',
  signIn: '登录',
  MaxAmountLoan: '最高贷款额',
  loanAmount: '贷款额度',
  fill_the_Info: '填写资料',
  quick_loan: '快速贷款',
  Monthly_Repayment: '每月还贷',
  user_loan_agreement: '同意用户服务协议和贷款协议',
  Loan_immediately: '立即贷款',
  Joined:'已加入',
  Member:'成员',
  Loaned_out: '借出',
  Cumulative_transaction_amount : '累计交易金额',
  Withdrawable_balance: '可提款余额',
  My_Profile: '我的主页',
  My_Loan: '我的贷款',
  My_Repayment: '我的还贷',
  My_Withdrawal: '我的提款',
  phoneNum: '请输入电话号码',
  password: '请输入密码',
  Forgot_password :'忘记密码？',
  name:'请输入您的IC全名',
  password_register: '请设置6-16位密码',
  rePassword: '请重新输入密码',
  verifyCode: '输入验证码',
  term_register:'通过注册，您确认您接受我们的',
  term1_register:'使用条款',
  Privacy_Policy: '隐私政策',
  and: "和",
  Balance:"余额",
  Withdrawal:'提款',
  notwithdrawal:'您没有任何提款',
  Amount_Due: '应付金额',
  Deposit: '订金',
  Upload_Slip: '上传收据',
  Loan_progress: '贷款进度',
  No_loans: '没有贷款',
  CARD_HOLDER: '卡片持有者',
  Account_fund_security : '账户资金安全',
  profile_1: '只需3分钟即可完成信息验证，即可申请贷款~',
  Identity_Information: '身份信息',
  profile_2: '告诉我们您的基本情况',
  Data_Information: '数据信息',
  profile_3: '让我们知道您的个人资料信息',
  bank_card: '取款银行卡',
  profile_4 :'提款银行卡一经提交不可修改',
  Sign:'符号',
  profile_5: '签订用户合同',
  phoneNum_auth:'手机号码认证',
  profile_6:'验证您的手机号码',
  profile_7:'补充资料可以增加通过审核的机会',
  Additional_materials: '附加材料',
  profile_8:'增加通过审核的机会',
  profile_9:'我已阅读并同意签署《个人消费贷款合同》、《个人信用报告》查询授权书”，并同意将贷款相关信息提交至金融信用信息基础数据库（Prople Bank of Malaysia Credit Information System）',
  Borrow_now: '现在借',
  Login:'登录',
  register:'注册',
  Phone_blank:'电话号码不能为空',
  PleaseLoginFirst:'请先登录',
  madeloan:'您有贷款在进行中',
  agreement:'请同意《用户服务协议》和《借贷协议》',
  home:'主页',
  wallet:'钱包',
  support:'客服',
  me:'我',
  updatebankaccount:'请更新银行账户和姓名.',
  applyloan:'申请贷款',
  confirmloan:'确认贷款信息',
  loanterm:'贷款期限',
  bankaccount:'银行账户',
  receivingaccount:'收款账户',
  confirmed:'我已经确认',
  submitloan:'提交贷款申请',
  service:'客户服务',
  uploadpayslip:'请上传您的工资单',
  uploadedpayslip:'工资单已上传',
  permissiongallery:'需要访问照片的权限',
  paysliprequired:'需要填写工资单',
  depositerequired:'需要填写押金',
  remarkrequired:'需要填写备注',
  phonerequired:'需要填写手机号码',
  namerequired:'需要填写姓名',
  identifirequired:'需要填写身份证',
  companyrequired:'需要填写公司姓名',
  positionrequired:'需要填写职位',
  addressrequired:'需要填写地址',
  incomerequired:'需要填写月收入',
  icnumber:'身份证号码必须是12位数字',
  submit:'提交',
  Depositamount: '订金金额',
  remark:'备注',
  complete:'完成',
  WithdrawalList:'提款清单',
  WithdrawalAmount:'提款金额',
  completeupdate:'更新成功',
  enteryouric:'请输入你的身份证号码',
  updateidentity:'更新身份',
  uploadfrontic:' 请上传您的身份证正面 ',
  uploadbackic:' 请上传您的身份证背面 ',
  uploadfaceic:' 请上传您的身份证和您的脸一起 ',
  Choosefile:'选择文件',
  uploadincomedocument:"上传收入文件",
  entercompany:'请输入公司名称',
  enterposition:'请输入您的职位',
  enteraddress:'请输入您的地址',
  enterincome:'请输入您的月收入',
  uploadsalary:" 在此处上传您的工资单照片 ",
  uploadbill:'在此处上传您的公用事业账单照片',
  bankrequired:'需要填写银行名称',
  bankaccountrequired:'需要填写银行帐户号码',
  updatedetail:'请更新公司详细信息',
  bankaccountdetail:"银行帐户详细信息",
  enterbank:'请输入您的银行名称',
  bankname:'银行名称',
  enterbankaccount:'请输入您的银行账号',
  update:'更新',
  document:'更新文件',
  uploaddocument:'上传您的文件',
  underreview:"审查中",
  pending:"待定",
  processing:"支付处理",
  loansuccessfully:"贷款成功",
  borrowingresult:"借款结果",
  loanprocessing:'贷款进度',
  Tips:'备注',
  tipresult:'您的贷款申请已提交，请主动联系',
  order:'订单号',
  contract:'查看合同',
  loandetail:'贷款详情',
  paymentsuccessfully:'支付成功',
  withdrawalresult:'提款结果',
  withprogress:'提现进度',
  repaymentdetail:'还款明细',
  withamount:'提款金额',
  passwordrequired:"需要填写密码",
  numbernotexist:"电话号码或密码错误",
  wrongpass:"密码错误",
  cannotlogin:'用户无法登录.',
  alertmessage:"提示信息",
  close:'关闭',
  registercomplete:'注册完成',
  inuse:'电话号码已注册过',
  passwordnotmatch:'密码不匹配',
  passblank:'密码不能为空',
  icblank:'身份证全名不能为空',
  phonebetween:'电话号码必须介于 10 和 11 之间',
  captcha:"验证码不匹配",
  ICrequired:'需要填写身份证号码',
  forgotpassword:'忘记密码',
  send:'发送',
  newpass:'需要填写新密码',
  renewpass:'需要在输入一次新密码',
  newpassword:"新密码",
  setnewpass:'设置新密码',
  enternewpass:'请输入新密码',
  reenternewpass:'请在输入一次新密码',
  withrequired:"必须填写提款金额",
  withbalance:'提现金额超过余额',
  loandate:'贷款日期',
  totalamount:'贷款总额',
  amountpermonth:'每月还款额',
  tenureoftheloan:'贷款期限和年利率',
  duedate:'截止日期',
  repayment:'我的还款',
  paynow:'现在付款',
  amount:'还款额',
  uploadtran:'上传转帐单',
  repayrequired:"需要填写还款金额",
  repaymonth:'选择还款月份',
  repaypic: '需要还款',
  Recent:'最近的交易',
  all:'全部',
  income:'进账',
  expense:'出账',
  me_lang: "将语言更改为",
  ic_fail:"失败的！ ic已经在使用了！",
  ic_update: "请更新你的IC",
  accountname:"账户名称",
  accountnumber:"账户号码",
  transaction:"交易记录",
  exist:"贷款金额已超过",
  startdate:'开始日期',
  enddate:'结束日期',
  outstanding:'未偿还的贷款',
  upcomingpayment:'付款时间表',
  viewhistory:'查看历史记录',
  duenext:'下一个付费日期',
  paid:'已付款',
  fromloan:'来自贷款金额',
  personal:'个人贷款',
  upcoming:'即将到来',
  showmore: '显示更多'

};
export { zh, en };