import React, { useEffect, useState } from "react";
import {
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
  FlatList,
  Slider,
} from "react-native";
import { Title } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";
import { Appbar, Provider as PaperProvider } from "react-native-paper";
import { ProgressStep, ProgressSteps } from "react-native-progress-steps";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { useFocusEffect } from "@react-navigation/native";
import * as Constant from "../utils/constant";
import StepIndicator from 'react-native-step-indicator';
import { globalStyles } from "../../style/globalstyles";
import InternetConnectionAlert from "react-native-internet-connection-alert";
import i18n from 'i18n-js';
import { zh, en } from '../utils/supportedLanguages';
import *  as api  from "../utils/api.js";

const LoanDetails = ({ navigation }) => {
  const[status,setStatus] = useState();
  const [loans, setLoan] = useState({
    createdAt: "",
    loanAmount: "",
    loanTerm: "",
    MonthlyPay: "",
    StatusLoan: "",
    Interest: "",
    id: "",
  });
  i18n.fallbacks = true;
  i18n.translations = { en, zh };
  const [createAt ,setCreateAt] = useState("");
  const [loanAmount, setloansAmount] = useState("");
  const [loanterm, setLoanTerm] = useState("");
  const [monthlyPay, setMonthlyPay] = useState("");
  const [statusLoan, setStatusLoan] = useState("");
  const [interest,setInterest] = useState("");
  const [idLoan,setIdLoan] = useState("");
  const[loanstatus,setLoanStatus] = useState(true);
  const [remarkMsg,setRemarkMsg] = useState("");
  const onMyProfileClicked = () => {
    navigation.push("Root");
  };
  const onHomeClicked = () => {
    navigation.navigate("Root");
  };
  const data = [
    { id: "Loan Amount", name: "loanAmount" },
    { id: "Loan term", name: "loanterm" },
    { id: "Monthly repayment", name: "RM Monthly repayment" },
  ];

  const item = ({ item }) => {
    return (
      <View style={{ flexDirection: "row" }}>
        <View style={{ marginLeft: 20, width: 200 }}>
          <Text style={{ color: "#777777", fontSize: 12 }}>{item.id}</Text>
        </View>
        <View style={{ width: 200 }}>
          <Text style={{ color: "#616161", fontSize: 12 }}>{item.name}</Text>
        </View>
      </View>
    );
  };
  const fetchLoanDataFromApi = async () => {
    try {
      console.log("okay masuk");

      var ids = await AsyncStorage.getItem("id");
      if(ids != null){
        var response = await api.listofloan();
      
        if(response.message === "No Data"){
          console.log("masuk sini loan")
          setLoanStatus(true)
        }else{
          setCreateAt(response.createdAt);
          setloansAmount(response.loanAmount);
          setMonthlyPay(response.MonthlyPay);
          setLoanTerm(response.loanTerm);
          setStatusLoan(response.StatusLoan);
          setInterest(response.Interest);
          setIdLoan(response.orderid)
          switch(response.StatusLoan){
            case "Under review":
              get_msg("Underreview")
              setStatus(0);
              break;
            case "Audit Verification":
              get_msg("Auditverification")
              setStatus(1);
              break;
            case "Waiting for payment":
              get_msg("Waitingforthenextpayment")
              setStatus(2);
              break;
            case "Successfully recieved":
              get_msg("Successfullyarrived")
              setStatus(3);
              break;
            default:
              console.log("error");
              break;  
          }
        }
      }
      
    } catch (error) {
      console.log(error);
    }
  };
  const get_msg = async(msg)=>{
    console.log("🚀 ~ file: loanDetails.js ~ line 114 ~ constget_msg=async ~ msg", msg)
    const Msg = await axios.get(Constant.Base_Api+"api/msg/"+msg);
    if(Msg.status == 200){
    console.log("🚀 ~ file: loanDetails.js ~ line 122 ~ constget_msg=async ~ Msg", Msg.data.msg2)
    setRemarkMsg(Msg.data.msg2)

    }
    console.log("🚀 ~ file: loanDetails.js ~ line 126 ~ constget_msg=async ~ Msg", remarkMsg)
  }
  
  useFocusEffect(
    React.useCallback(() => {
      fetchLoanDataFromApi();
      console.log("loan detail is focused");
      console.log(loanstatus)
    }, [])
  );
  const labels = [(i18n.t('underreview')),(i18n.t('pending')),(i18n.t('processing')),(i18n.t('loansuccessfully'))];
const customStyles = {
  stepIndicatorSize: 25,
  currentStepIndicatorSize:30,
  separatorStrokeWidth: 2,
  currentStepStrokeWidth: 3,
  stepStrokeCurrentColor: '#ff0033',
  stepStrokeWidth: 3,
  stepStrokeFinishedColor: '#ff0033',
  stepStrokeUnFinishedColor: '#aaaaaa',
  separatorFinishedColor: '#ff0033',
  separatorUnFinishedColor: '#aaaaaa',
  stepIndicatorFinishedColor: '#ff0033',
  stepIndicatorUnFinishedColor: '#ffffff',
  stepIndicatorCurrentColor: '#ffffff',
  stepIndicatorLabelFontSize: 13,
  currentStepIndicatorLabelFontSize: 13,
  stepIndicatorLabelCurrentColor: '#ff0033',
  stepIndicatorLabelFinishedColor: '#ffffff',
  stepIndicatorLabelUnFinishedColor: '#aaaaaa',
  labelColor: '#999999',
  labelSize: 13,
  currentStepLabelColor: '#ff0033'
}

  return (
    <InternetConnectionAlert
            onChange={(connectionState) => {
                console.log("Connection State: ", connectionState);
            }}
            >
    <SafeAreaView style={styles.container}>

      <View style={styles.body}>
        <Appbar.Header style={globalStyles.appHeader}>
          <Appbar.BackAction onPress={onHomeClicked} />
          <Appbar.Content title={(i18n.t('borrowingresult'))} />
        </Appbar.Header>
        {(!loanstatus)? <Text>{(i18n.t('No_loans'))}</Text> :
        <>
        <View style={styles.userInfoSection}>
        <Title
                style={[
                  styles.title,
                  {
                    marginLeft: 10,
                  },
                ]}
              >
                 {(i18n.t('loanprocessing'))} {createAt}
              </Title>
        </View>
        <View style={styles.userInfoSection}>
          <View style={styles.row}>
            <View style={{ marginLeft: 10 }}>
              <View style={{ flexDirection: "row" }}>
                <View style={{ width: "60%" }}>
                  <Text
                    style={{ marginTop: 10, color: "#f44336", fontSize: 12}}>
                    {/* {loans.status} */}
                    {statusLoan}
                  </Text>
                  </View>
                  <View style={{ width: "50%" }}>
                <Text style={{ color: "#616161", fontSize: 18, marginTop: 5 }}>
                  RM {loanAmount}
                </Text>
                </View>
              </View>
            </View>
          </View>
          <View style={{ height: "15%",marginTop:10}}>
          <StepIndicator
         customStyles={customStyles}
         stepCount= {4}
         currentPosition={status}
         labels={labels}
          />
          </View>
          </View>
        <View style={styles.userInfoSection}>
          <View style={styles.row}>
            <View style={{ marginLeft: 10 }}>
              <View style={styles.row}>
                <Text
                  style={{
                    color: "#777777",
                    fontSize: 12,
                    marginRight: 10,
                    marginTop: 10,
                  }}
                >
                  {(i18n.t('Tips'))}
                </Text>
                <Text style={{ color: "#f44336", fontSize: 12, marginTop: 10 }}>
                {/* {(i18n.t('tipresult'))} */ remarkMsg}
                </Text>
              </View>
            </View>
          </View>
        </View>
    
        <View style={styles.userInfoSection}>
          <View style={styles.row}>
            <View style={{ marginLeft: 10 }}>
              <Title
                style={[
                  styles.title,
                  {
                    marginBottom: -5,
                  },
                ]}
              >
                {(i18n.t('loandetail'))}
              </Title>
            </View>
          </View>
        </View>
        <View>
          <View style={{ flexDirection: "row" }}>
            <View style={{ marginLeft: 20, width: 200 }}>
              <Text style={{ color: "#777777", fontSize: 12 }}>
              {(i18n.t('order'))}
              </Text>
            </View>
            <View style={{ width: 200 }}>
              <Text style={{ color: "#616161", fontSize: 12 }}>{idLoan}</Text>
            </View>
          </View>
          <View style={{ flexDirection: "row" }}>
            <View style={{ marginLeft: 20, width: 200 }}>
              <Text style={{ color: "#777777", fontSize: 12 }}>
              {(i18n.t('loanAmount'))}
              </Text>
            </View>
            <View style={{ width: 200 }}>
              <Text style={{ color: "#616161", fontSize: 12 }}>
               RM {loanAmount}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: "row" }}>
            <View style={{ marginLeft: 20, width: 200 }}>
              <Text style={{ color: "#777777", fontSize: 12 }}>{(i18n.t('loanterm'))}</Text>
            </View>
            <View style={{ width: 200 }}>
              <Text style={{ color: "#616161", fontSize: 12 }}>
                {loanterm}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: "row" }}>
            <View style={{ marginLeft: 20, width: 200 }}>
              <Text style={{ color: "#777777", fontSize: 12 }}>
              {(i18n.t('Monthly_Repayment'))}
              </Text>
            </View>
            <View style={{ width: 200 }}>
              <Text style={{ color: "#616161", fontSize: 12 }}>
               RM {parseFloat(monthlyPay).toFixed(2)}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.userInfoSection}>
          <TouchableOpacity onPress={onMyProfileClicked}>
            <Text
              style={{
                color: "#616161",
                fontSize: 16,
                textAlign: "center",
                marginTop: 20,
              }}
            >
              {" "}
              {(i18n.t('contract'))}{" "}
            </Text>
          </TouchableOpacity>
        </View>
        </>
        
        }
       
      </View>
    </SafeAreaView>
    </InternetConnectionAlert>
  );
};

export default LoanDetails;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  body: {
    flex: 1,
    backgroundColor: "white",
  },
  title: {
    fontSize: 18,
  },
  row: {
    flexDirection: "row",
    marginBottom: 7,
  },
  BorrowBtn: {
    width: "70%",
    borderRadius: 30,
    backgroundColor: "#6200ee",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    paddingVertical: 10,
    marginEnd: 10,
    marginTop: 10,
  },
  BorrowText: {
    fontSize: 15,
    color: "#FFFFFF",
  },
  userInfoSection: {
    marginBottom: 0,
    backgroundColor: "#ffffff",
    borderBottomColor: "#eeeeee",
    borderBottomWidth: 1,
  },
  iconCompleted: {
    flexDirection: "column",
    alignItems: "center",
    height: 200,
  },
  dash: {
    width: 1,
    height: 80,
    top: 0,
    alignContent: "center",
    alignSelf: "center",
    flexDirection: "column",
  },
});
