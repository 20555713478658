import React, { useState, useRef, useEffect } from "react";
import {
  Alert,
  Dimensions,
  Image,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  Button,
  View,
} from "react-native";
import { SafeAreaView } from 'react-native-safe-area-context';
import Ionicons from "@expo/vector-icons/Ionicons";
import FontAwesome from "@expo/vector-icons/FontAwesome";
import { Modal, ProgressBar } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import * as ImagePicker from "expo-image-picker";
import { Appbar, Provider as PaperProvider } from "react-native-paper";
import axios from "axios";
import * as Constant from "../../utils/constant";
import { useFocusEffect } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import ContentLoader from "react-native-easy-content-loader";
import { globalStyles } from "../../../style/globalstyles";
import InternetConnectionAlert from "react-native-internet-connection-alert";
import i18n from 'i18n-js';
import { zh, en } from '../../utils/supportedLanguages';
import AwesomeAlert from 'react-native-awesome-alerts';
import * as api from '../../utils/api';
const windowHeight = Dimensions.get("window").height;
// const dimensionHeight = useWindowDimensions()

export default function Updateidentity({ navigation }) {
  const dispatch = useDispatch();
  const [createUserModel, setCreateUserModel] = useState({
    name: "",
    phoneNumber: "",
    identificationNumber: "",
    picFront: "",
    picBack:"",
    picFace: ""
  });
  i18n.fallbacks = true;
  i18n.translations = { en, zh };
  const [loading, setLoading] = useState(false);
  const [editIc, setEditIc] = useState(false);
  const [picFront, setPicFront] = useState("");
  const [picBack, setPicBack] = useState("");
  const [picFace, setPicFace] = useState("");
  const [picfrontName, setPicFrontName] = useState("");
  const [picbackname, setPicBackName] = useState("");
  const [picFacename, setFaceName] = useState("");
  const [msgalert1,setMsgAlert1] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [modal_pic3,setModalpic3] = useState(false);
  const [modal_pic2,setModalpic2] = useState(false);
  const [modal_pic,setModalpic] = useState(false)
  const base = 'data:image/jpg;base64,';
  const onHomeClicked = () => {
    navigation.navigate("ProfileScreen");
  };

  const openImagePickerAsync = async (pic) => {
    let permissionResult =
      await ImagePicker.requestMediaLibraryPermissionsAsync();

    if (permissionResult.granted == false) {
      Alert.alert(i18n.t('permissiongallery'));
      return;
    }
    let pickerResult = await ImagePicker.launchImageLibraryAsync({
      base64: true,
      quality: 1,
    });
    if (pickerResult.cancelled == true) {
      return;
    }
    switch (pic) {
      case "front":
        console.log("okay front");
        setPicFront(pickerResult.base64);
        let name = pickerResult.uri.replace("file:///data/user/0/com.kimie100.LoanApp/cache/ImagePicker/","")
        console.log(name);
        setPicFrontName(name);
        break;
      case "back":
        console.log("okay back");
        setPicBack(pickerResult.base64);
        let name2 = pickerResult.uri.replace("file:///data/user/0/com.kimie100.LoanApp/cache/ImagePicker/","")
        setPicBackName(name2)
        break;
      case "face":
        console.log("okay face");
        setPicFace(pickerResult.base64);
        let name3 = pickerResult.uri.replace("file:///data/user/0/com.kimie100.LoanApp/cache/ImagePicker/","")
        setFaceName(name3);
        break;
      default:
        console.log("errro");
    }
  };



  const onUpdateClicked = async () => {
    console.log(createUserModel.identificationNumber);
    //  if (createUserModel.phoneNumber == "") {
    //   // Alert.alert(i18n.t('phonerequired'));
    //   setMsgAlert1(true);
    //   setAlertMessage(i18n.t('phonerequired'));
    //   return;
    // } else if (createUserModel.name == "") {
    //   // Alert.alert(i18n.t('namerequired'));
    //   setMsgAlert1(true);
    //   setAlertMessage(i18n.t('namerequired'));
    //   return;
    // } else 
    if (createUserModel.identificationNumber == "" || createUserModel.identificationNumber == null) {
      // Alert.alert(i18n.t('identifirequired'));
      setMsgAlert1(true);
      setAlertMessage(i18n.t('identifirequired'));
      return;
    }else if(createUserModel.identificationNumber.length <12 || createUserModel.identificationNumber.length >12 ){
      // Alert.alert(i18n.t('icnumber'))
      setMsgAlert1(true);
      setAlertMessage(i18n.t('icnumber'));
    }else {
      setLoading(true);
       if(editIc){
        
        try {
          let sta = 1;
          var ids = await AsyncStorage.getItem("id");
          var res = await api.update_user(createUserModel.identificationNumber, picFront,picBack,picFace,sta);
          if(res == 200){
            setMsgAlert1(true);
            setAlertMessage(i18n.t('completeupdate'));
            fetchLoanDataFromApi();
          }else{
            console.log("ic post", res);
            setMsgAlert1(true);
            setAlertMessage(i18n.t('ic_fail'));
          }
       
        } catch (err) {
            console.log(err)
        }
      }else{
        try {
          let sta = 0;
          var ids = await AsyncStorage.getItem("id");
          var res = await api.update_user("",picFront,picBack,picFace,sta)
          if(res == 200){
            setMsgAlert1(true);
            setAlertMessage(i18n.t('completeupdate'));
            fetchLoanDataFromApi();
          }else{
            console.log("ic", res)
            setMsgAlert1(true);
            setAlertMessage(i18n.t('ic_fail'));
          }
            
        } catch (err) {
          console.log(err)
        }
       
      }
      setLoading(false);
    }
  
  };

  const fetchLoanDataFromApi = async () => {
    try {
      console.log("okay masuk");
      var ids = await AsyncStorage.getItem("id");
      const response = await api.disUser();
      setPicFront(response.Pic_ic_Front);
      setPicBack( response.Pic_Ic_Back);
      setPicFace(response.Pic_Ic_Hand)
      setCreateUserModel((prevUser) => {
          return {
            ...prevUser,
            name: response.name,
            phoneNumber: response.phoneNum,
            identificationNumber: response.IcNum,
            // picFront: response.Pic_ic_Front,
            // picBack: response.Pic_Ic_Back,
            // picFace: response.Pic_Ic_Hand
          };
          
      });
      if (response.IcNum == ""|| response.IcNum == null) {
        setEditIc(true);
      }else{
        setEditIc(false);
      }
      setLoading(false);  
    
    } catch (error) {
      console.log(error);
    }
  };
const picName1 = ()=>{
  if(picFront != "tiada"){
    return "Show uploaded front ic";
  }else{
    return picfrontName;
  }
}
const picName2 = ()=>{
  if(picBack != "tiada"){
    return "Show uploaded back ic";
  }else{
    return picbackname;
  }
}
const picName3 = ()=>{
  
  if(picFace != "tiada"){
    // if(picFace!=""){
      return "Show uploaded face ic";
    // }
    
  }else{
    return picFacename;
  }
}
// const pic3= ()=>{
//   return <>
//   <Modal visible={true}>


//   </Modal>
  
//   </>
// }
  useFocusEffect(
    React.useCallback(() => {
        setLoading(true);
      console.log("identityscreen is focused");
      fetchLoanDataFromApi();
    }, [])
  );

  return (
    <InternetConnectionAlert
    onChange={(connectionState) => {
        console.log("Connection State: ", connectionState);
    }}
    >
    <SafeAreaView style={styles.container}>

    <View style={globalStyles.body}>
      <Appbar.Header style={globalStyles.appHeader}>
        <Appbar.BackAction onPress={onHomeClicked} />
        <Appbar.Content title={(i18n.t('updateidentity'))} />
      </Appbar.Header>
      <ContentLoader active loading={loading}>
      <View style={globalStyles.bodycontainer}>
        <View style={styles.inputContainer}>
          <Ionicons name="phone-portrait-outline" size={15} color={"#A0A0A0"} />
          <TextInput
            style={styles.input}
            keyboardType="numeric"
            editable={false}
            value={createUserModel.phoneNumber}
            placeholder="Please enter the phone number"
            onChangeText={(text) => {
              setCreateUserModel((prevState) => {
                return { ...prevState, phoneNumber: text };
              });
            }}
          />
        </View>

        <View style={styles.inputContainer}>
          <FontAwesome name="user-o" size={15} color={"#A0A0A0"} />
          <TextInput
            style={styles.input}
            placeholder="Please enter your name"
            value={createUserModel.name}
            editable={false}
            onChangeText={(text) => {
              setCreateUserModel((prevState) => {
                return { ...prevState, name: text };
              });
            }}
          />
        </View>

        <View style={styles.inputContainer}>
          <FontAwesome name="id-card-o" size={15} color={"#A0A0A0"} />
          <TextInput
            editable={editIc}
            style={styles.input}
            placeholder={i18n.t('enteryouric')}
            value={createUserModel.identificationNumber}
            keyboardType="numeric"
            onChangeText={(text) => {
              setCreateUserModel((prevState) => {
                return { ...prevState, identificationNumber: text };
              });
            }}
          />
        </View>

        <View style={styles.inputContainer}>
          <View style={{ width: "30%" }}>
            <TouchableOpacity
              style={styles.chooseBtn}
              onPress={() => {
                openImagePickerAsync("front");
              }}
            >
              <Text style={{ color: "#ffffff" }}> {(i18n.t('Choosefile'))} </Text>
            </TouchableOpacity>
          </View>
          <View style={{ width: "auto" }}>
            <TouchableOpacity onPress={()=>{(picFront != "tiada")? setModalpic(true):null}}>
            <Text style={{ color: "#808080", textAlign: "center" }}>
              {(picFront == "tiada"|| picFront == null )?(i18n.t('uploadfrontic')):picName1()}
            </Text>
            </TouchableOpacity>
          </View>
        </View>
        <ProgressBar
          progress={(picFront == "tiada"|| picFront == null)? 0: 1}
          color={"#ff0033"}
          style={{
            margin: 10,
            marginLeft: 20,
            marginRight: 20,
            height: 10,
            borderRadius: 30,
          }}
        >
          {" "}
        </ProgressBar>

        <View style={styles.inputContainer}>
          <View style={{ width: "30%" }}>
            <TouchableOpacity
              style={styles.chooseBtn}
              onPress={() => {
                openImagePickerAsync("back");
              }}
            >
              <Text style={{ color: "#ffffff" }}> {(i18n.t('Choosefile'))} </Text>
            </TouchableOpacity>
          </View>
          <View style={{ width: "auto" }}>
            <TouchableOpacity onPress={()=>{(picBack != "tiada")?setModalpic2(true):null}}>
            <Text style={{ color: "#808080", textAlign: "center" }}>
            {(picBack == "tiada"|| picBack == null)? (i18n.t('uploadbackic')): picName2()}
            </Text>
            </TouchableOpacity>
          </View>
        </View>
        <ProgressBar
          progress={(picBack == "tiada"|| picBack == null)? 0: 1}
          color={"#ff0033"}
          
          style={{
            margin: 10,
            marginLeft: 20,
            marginRight: 20,
            height: 10,
            borderRadius: 30,
          }}
        >
          {" "}
        </ProgressBar>

        <View style={styles.inputContainer}>
          <View style={{ width: "30%" }}>
            <TouchableOpacity
              style={styles.chooseBtn}
              onPress={() => {
                openImagePickerAsync("face");
              }}
            >
              <Text style={{ color: "#ffffff" }}> {(i18n.t('Choosefile'))} </Text>
            </TouchableOpacity>
          </View>
          <View style={{ width: "auto" }}>
            <TouchableOpacity onPress={()=> {(picFace != "tiada")?setModalpic3(true):null}}>
            <Text style={{ color: "#808080", textAlign: "center" }}>
               {(picFace == "tiada"|| picFace == null)? (i18n.t('uploadfaceic')): picName3()}
            </Text>
            </TouchableOpacity>
            
          </View>
        </View>
        
        <ProgressBar
          progress={(picFace == "tiada"|| picFace == null)? 0: 1}
          color={"#ff0033"}
          style={{
            margin: 10,
            marginLeft: 20,
            marginRight: 20,
            height: 10,
            borderRadius: 30,
          }}
        >
          {" "}
        </ProgressBar>

        <TouchableOpacity style={globalStyles.outlineBtn} onPress={onUpdateClicked}>
          <Text style={globalStyles.outlineText}> {(i18n.t('submit'))} </Text>
        </TouchableOpacity>
      </View>
      </ContentLoader>
    </View>
    <AwesomeAlert
          show={msgalert1}
          showProgress={false}
          message={alertMessage}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}
          showConfirmButton={true}
          confirmText={i18n.t('close')}
          confirmButtonColor="#DD6B55"

          onConfirmPressed={() => {
           setMsgAlert1(false)
          }}
        />
    <Modal visible={modal_pic} onDismiss={()=>setModalpic(false)}>
      <Image source={{uri: base+ picFront }} style={styles.imageIC}/>
    </Modal>
    <Modal visible={modal_pic2} onDismiss={()=>setModalpic2(false)}>
      <Image source={{uri: base+ picBack }} style={styles.imageIC}/>
    </Modal>
    <Modal visible={modal_pic3} onDismiss={()=>setModalpic3(false)}>
      <Image source={{uri: base+ picFace }} style={styles.imageIC}/>
    </Modal>
    </SafeAreaView>
    </InternetConnectionAlert>
  );
}

const styles = StyleSheet.create({
  mo:{
    backgroundColor: "#F7F7F7"
  },
  body: {
    flex: 1,
    backgroundColor: "#F7F7F7",
    marginTop: 20,
  },
  titleContainer: {
    width: "100%",
    height: 150,
    position: "relative",
    backgroundColor: "#FEE16E",
    alignItems: "center",
    fontWeight: "bold",
    height: 50,
    backgroundColor: "#6200ee",
    alignItems: "center",
    fontWeight: "bold",
    bottom: 240,
  },
  title: {
    fontSize: 20,
    color: "#FFFFFF",
    marginTop: 35,
  },
  userInfoSection: {
    paddingHorizontal: 70,
    marginBottom: 0,
    backgroundColor: "#ffffff",
    borderBottomColor: "#eeeeee",
    borderBottomWidth: 1,
  },
  row: {
    flexDirection: "row",
    marginBottom: 7,
  },
  loginContainer: {
    flex: 1,
    width: "95%",
    backgroundColor: "#FFFFFF",
    position: "absolute",
    alignSelf: "center",
    justifyContent: "center",
    top: 100,
  },
  inputContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 10,
    marginVertical: 5,
    width: "100%",
  },
  input: {
    flex: 1,
    height: 50,
    borderBottomWidth: 0.5,
    paddingStart: 10,
  },
  uploadIcContainer: {
    flexDirection: "row",
    alignItems: "flex-end",
    paddingHorizontal: 10,
    marginVertical: 5,
    width: "100%",
  },
  imageIC: {
    width: "200%",
    height: undefined,
    maxHeight: 200,
    aspectRatio: 1,
    resizeMode: "contain",
    alignSelf: 'center',
    
  },
  registerBtn: {
    marginTop: 5,
    alignSelf: "flex-end",
    marginEnd: 5,
  },
  registerText: {
    fontSize: 13,
    color: "#98A5FD",
    fontWeight: "bold",
  },
  loginBtn: {
    width: "95%",
    borderRadius: 20,
    borderWidth: 1,
    borderColor: "#ff0033",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    marginTop: 20,
    paddingVertical: 10,
  },
  loginText: {
    fontSize: 15,
    color: "#ff0033",
  },
  updateText: {
    fontSize: 15,
    color: "#ffffff",
  },
  footer: {
    width: "100%",
    position: "absolute",
    backgroundColor: "#F7F7F7",
    bottom: -50,
    alignItems: "center",
  },
  footerText: {
    fontSize: 15,
    color: "#FEE16E",
  },
  text: {
    color: "gray",
    marginVertical: 10,
  },
  link: {
    color: "#FDB075",
  },
  container: {
    flex: 1,
  },
  checkboxContainer: {
    flexDirection: "row",
    marginBottom: 5,
  },

  checkbox: {
    alignSelf: "center",
  },
  chooseBtn: {
    borderWidth: 1,
    padding: 3,
    borderColor: "#ff0033",
    backgroundColor: "#ff0033",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    alignSelf: "flex-start",
    borderRadius: 5,
  },
  updateBtn: {
    borderWidth: 1,
    padding: 3,
    borderColor: "#ff0033",
    backgroundColor: "#ff0033",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    alignSelf: "flex-end",
    borderRadius: 5,
  },
  progressContainer: {
    flex: 1,
    flexDirection: "column", //column direction
    justifyContent: "center",
    alignItems: "center",
    padding: 8,
    margin: 10,
  },
  progressBar: {
    height: 20,
    width: "80%",
    backgroundColor: "#ff0033",
    borderColor: "#000",
    borderWidth: 1,
    borderRadius: 20,
  },
});
