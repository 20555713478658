import axios from 'axios';
import * as Constant from './constant';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as func from './function';
export async function login  (phone,pass){
    try{
        var res = await axios.post(Constant.Base_Api+'api/auth/signin', {
        "phoneNum": phone,
        "password": pass,
        });
        AsyncStorage.setItem('id', phone);
        AsyncStorage.setItem('name',res.data.name );
        return res.status;
    }catch(error){
        return error.response.data;
      } 
}


export async function register (phone,pass,name){
    try{
        var res  = await axios.post(Constant.Base_Api+'api/auth/signup', {
            "phoneNum": phone,
            "password": pass,
            "name": name
          })
        AsyncStorage.setItem('id', phone);
        AsyncStorage.setItem('name',name );
          return res.status
    }catch(error){
        return error.response.data;

    }
}

export async function loan_limit(){
    try{
        var res = await axios.get(Constant.Base_Api+"api/loanlimit/1");
        return res.data;
    }catch(error){
        return error.response.data;
    }
} 
export async function check_loan_status(){
    try{
        var id = await AsyncStorage.getItem("id");
        if(id != null){
            var res =await axios.get(Constant.Base_Api + "api/loans/calpay/" + id);
            return res.data;
        }
        
    }catch(error){
        return error.response.data;
    }
}
export async function check_user_ic(){
    try{
        var id = await AsyncStorage.getItem("id");
        if(id != null){
            var res = await axios.get(Constant.Base_Api+"api/userdetails/" + id);
            if(res.data.IcNum == ""||res.data.IcNum == null){
                return "no ic";
            }else if (res.data.userProfile == null){
                return "no profile";
            }else if(res.data.userProfile.userAccount == null){
                return "no account";
            }
            return 1;
        }
    }catch(error){
        return error.response.data;
    }
}

export async function check_acc(){
    try{
        var id = await AsyncStorage.getItem("id");
        if(id!=null){
            var res  = await axios.get(Constant.Base_Api + "api/userprofile/" + ids + "/acc");
            return res.data;
        }
    }catch(error){
        return error.response.data;
    }
}
export async function listofloan(){
    try{
        var id = await AsyncStorage.getItem("id");
        var res  =await axios.get(Constant.Base_Api + "api/loans/"+id);
        return res.data;
    }catch(error){
        return error.response.data;
    }
}

export async function listofuserpay(id){
    try{
       // var id = await AsyncStorage.getItem("id");
        var res  =await axios.get(Constant.Base_Api+"api/userPays/pays/"+id);
        return res.data;
    }catch(error){
        return error.response.data;
    }
}
export async function p_userpay(amount, date,slip,idloan) {
    console.log("🚀 ~ file: api.js ~ line 110 ~ p_userpay ~ idloan", idloan)
    try {
        var id = await AsyncStorage.getItem("id");
        var res = await axios.post(Constant.Base_Api + "api/userPays", {
          Amount : amount,
          date : date,
          pic_pay: slip,
          phoneNum: id,
          userLoanId: idloan
        })
        return res.status;
    } catch (error) {
        return error.response.data;
    }
}
export async function cal_loan() {
    try {
        var id = await AsyncStorage.getItem("id");
        var res = await axios.get(Constant.Base_Api + "api/depo/cal/" + id);
        var res2  = await axios.get(Constant.Base_Api + "api/withdraw/cal/" + id);
        var total = func.total_withdraw(res2.data[0].total,res.data[0].total)
        return total;
    } catch (error) {
        return error.response.data;
    }
}
export async function p_depo(depo,remark,slip) {
    try {
        var id = await AsyncStorage.getItem("id");
        var res = await axios.post(Constant.Base_Api + "api/depo", {
            "DepositeAmount" : depo,
            "statusDepo" : "Processing",
            "remarkDepo" : remark,
            "slipDepo" : slip,
            "phoneNum": id
          })
          return res.status;
    } catch (error) {
        return error.response.data
    }
    
}
export async function depoList() {
    try {
        var id = await AsyncStorage.getItem("id");
        var res = await axios.get(Constant.Base_Api + "api/depo/list/"+id)
          return res.data;
    } catch (error) {
        return error.response.data
    }
    
}
export async function user() {
    try {
        var id = await AsyncStorage.getItem("id");
        var res = await axios.get(Constant.Base_Api + "api/userdetails/"+id);
        return res.data;
    } catch (error) {
        return error.response.data;
    } 
}
export async function disUser(){
    try {
        var id = await AsyncStorage.getItem("id");
        var res = await axios.get(Constant.Base_Api + "api/userdetails/dis/"+id);        
        return res.data;
    } catch (error) {
        return error.response.data;
    }
}
export async function update_user(ic,pic_front,pic_back,pic_face,sta_ic) {

    try {
        var id = await AsyncStorage.getItem("id");
        if(sta_ic ==1){
            var res  = await  axios.put(Constant.Base_Api + "api/userdetails/" + id, {
                IcNum: ic,
                Pic_ic_Front: pic_front,
                Pic_Ic_Back: pic_back,
                Pic_Ic_Hand: pic_face,
              }); 
        }else{
            var res  = await  axios.put(Constant.Base_Api + "api/userdetails/" + id, {
                Pic_ic_Front: pic_front,
                Pic_Ic_Back: pic_back,
                Pic_Ic_Hand: pic_face,
              }); 
        }
        return res.status;
    } catch (error) {
        return error.response;
    }
    
}
export async function withdraw() {
    try {
        var id = await AsyncStorage.getItem("id");
        var res = await axios.get(Constant.Base_Api + "api/withdraw/" + id);
        return res.data;
    } catch (error) {
        return error.response.data;
    }
}
export async function withdrawList() {
    try {
        var id = await AsyncStorage.getItem("id");
        var res = await axios.get(Constant.Base_Api + "api/withdraw/list/" + id);
        return res.data;
    } catch (error) {
        return error.response.data;
    }
}
export async function p_withdraw(withdraw){
    try {
        var id = await AsyncStorage.getItem("id");
        var res = await  axios.post(Constant.Base_Api + "api/withdraw", {
          "amount" : withdraw,
          "status" : "Pending",
          "phoneNum": id
        })
        return res.status;
    } catch (error) {
        return error.response.data
    }
}
export async function userdetail_bank() {
    try {
        var id = await AsyncStorage.getItem("id");
        var res = await axios.get(Constant.Base_Api + "api/userprofile/" + id +"/bank");
        return res.data;
    } catch (error) {
        return error.response.data
    }
    
}
export async function userdetatil() {
    try {
        var id = await AsyncStorage.getItem("id");
        var res = await axios.get(Constant.Base_Api+"api/userprofile/"+ id);
        return res.data;
    } catch (error) {
        return error.response.data;
    }
    
}
export async function p_userdetail(com_name,user_posi,user_address,mont_income,payslip,uti_bill) {
    try {
        var id = await AsyncStorage.getItem("id");
        var res = await axios.post(Constant.Base_Api+'api/userprofile', {
            "userCompanyname" : com_name,
            "userPosition" : user_posi,
            "userAddress" : user_address,
            "monthlyIncome" : mont_income,
            "Pic_PaySlip": payslip,
            "Pic_UtilitiesBill": uti_bill,
            "phoneNum": id
          });
          return res.status;
    } catch (error) {
        return error.response.data;
    }
}
export async function Up_userdetail(com_name,user_posi,user_address,mont_income,payslip,uti_bil) {
    try {
        var id = await AsyncStorage.getItem("id");
        var res = await axios.put(Constant.Base_Api+'api/userprofile/'+id, {
            "userCompanyname" : com_name,
            "userPosition" : user_posi,
            "userAddress" : user_address,
            "monthlyIncome" : mont_income,
            "Pic_PaySlip": payslip,
            "Pic_UtilitiesBill": uti_bil,
            "phoneNum": id
          });
          return res.status;
    } catch (error) {
        return error.response.data;
    }
}
export async function up_user_bank(bank_name,bank_acc) {
    try {
        var id = await AsyncStorage.getItem("id");
        var res = await  axios.put(Constant.Base_Api+'api/userprofile/' +id, {
            "userBank" : bank_name,
            "userAccount" : bank_acc,
          })
          return res.status;
    } catch (error) {
        return error.response.data;
    }
    
}
export async function p_loan(loanAmount,loanTerm,monthlyRepayment,interest,id_order) {
    try {
        var id = await AsyncStorage.getItem("id");
        var res = await axios.post(Constant.Base_Api + "api/loans", {
          loanAmount: loanAmount,
          loanTerm: loanTerm,
          MonthlyPay: monthlyRepayment,
          StatusLoan: "Under review",
          Interest: interest,
          orderid: id_order,
          phoneNum: id,
        });
        return res.status;
    } catch (error) {
        return error.response.data
    }
}
