import React,{useState,useEffect, useCallback} from "react";
import { StyleSheet, Text, TouchableOpacity, View ,Pressable, Button,Image,ImageProps,Alert } from "react-native";
import { Bubble, GiftedChat, Send } from "react-native-gifted-chat";
import { MaterialCommunityIcons,AntDesign  } from '@expo/vector-icons';
import { Manager } from "socket.io-client";
import { io } from "socket.io-client";
import Modal from "react-native-modal";
import * as ImagePicker from 'expo-image-picker';
import { Video,Audio } from 'expo-av';
import { useFocusEffect } from '@react-navigation/native';
import * as DocumentPicker from 'expo-document-picker';
import { globalStyles } from "../../style/globalstyles";
import InternetConnectionAlert from "react-native-internet-connection-alert";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { SafeAreaView } from 'react-native-safe-area-context';
import i18n from 'i18n-js';
import { zh, en } from '../utils/supportedLanguages';
import AwesomeAlert from 'react-native-awesome-alerts';
export default function Support() {
  let externalUserId = '123456789'; 
  const video = React.useRef(null)
    const [messages, setMessages] = useState([]);
    const[modal, setModal] = useState(false);
    const[login,setLogin] = useState(true);
    const [selectedImage, setSelectedImage] = useState()
    const [selectedVideo, setSelectedVideo] = useState();
    const [name, setName] = useState();
    const img64 = "data:image/jpg;base64,";
    const [loadingHis, setLoadingHis] = useState(false);
    const [yesterdayDate , setYesterdayDate] = useState("");
    i18n.fallbacks = true;
    i18n.translations = { en, zh };
    const [language,setLanguage] = useState("");

    // const socket = io('http://txitech.dyndns.info', {
    //     transports: ['websocket'],
    //     path: "/chat" // you need to explicitly tell it to use websockets
    //   });
      const manager = new Manager('https://www.easycredit2u.com', {
        autoConnect: true,
        transports: ['websocket'],
        
      });
      const socket = manager.socket("/");
    socket.on("user disconnected", function(userName) {
      //document.querySelector(`.${userName}-userlist`).remove();
      console.log(userName);
      
  });
  socket.io.on("error", (error) => {
    console.log("error socket io " + error);
    if(error.message == "websocket error"){
      socket.disconnect(true);
      socket.connect();
    }
    socket.connect();
  });
  socket.on("connect_error", () => {
    socket.connect();
  });
   
    socket.on("disconnect", (data)=>{
      console.log("disconnnect socket io " + data);
      socket.connect();
    })
      socket.on("chat messages",  data =>{
        console.log(data.messages[0]._id)
        setMessages(previousMessages => GiftedChat.append(previousMessages, data.messages));
      })
    const onSend = useCallback(async(messages = []) => {
        console.log(messages[0].createdAt)
        let time = new Date().toLocaleString( {timeZone: "Asia/Malaysia"});
        console.log(time);
        messages[0].createdAt = time;
        const value = await AsyncStorage.getItem('id');
        const names = await AsyncStorage.getItem('name');
        setMessages(previousMessages => GiftedChat.append(previousMessages, messages));

       socket.emit("chat message", {username: names,room:value,messages});
        
    }, [])
    
    const renderSend = (props) => {
        return( 
            <Send {... props}>
               <View>
               <MaterialCommunityIcons
                    name="send-circle"
                   styles={{marginBottom:5 , marginRight:5}}
                   size={32} 
                   color='#2e64e5'
                   />
                   </View> 
            </Send>
           

        )
    }
    const getData = async () => {
      const value = await AsyncStorage.getItem('id');
      const name = await AsyncStorage.getItem('name');
      const lang = await AsyncStorage.getItem('language');
      setLogin(true)

      if(value !== null) {
          console.log(value);
          if(name !== null){
              console.log(name)
             // SetUsername(name)
          }
          if(lang !== null){
               
            setLanguage(lang)
        }       
      }else{ 
          setLogin(false)
      }
  }
  const connectSocket = async()=>{
    const value = await AsyncStorage.getItem('id');
    const name = await AsyncStorage.getItem('name');
    setName(name);
    let test =[];
    console.log(value);
    console.log(name);
    if(value == null&& name==null){
      console.log("no");
    }else{
      socket.emit("new user", {name: name, room:value});
      socket.on("connect", () => {
          console.log(socket.id); // x8WIv7-mJelg7on_ALbx
        });
    }
  }
  const mgsDepo = async()=>{
    const depomsg = await AsyncStorage.getItem('depo');
    const value = await AsyncStorage.getItem('id');
    const names = await AsyncStorage.getItem('name');
    if(depomsg){
      console.log("ada")
      let messages = [  {
        "_id": Math.round(Math.random() * 1000000),
        "createdAt": new Date(),
        "text": "Want to deposit money",
        "user":  {
          "_id": 1,
        },
      },];
      setMessages(previousMessages => GiftedChat.append(previousMessages, messages));
      socket.emit("chat message", {username: names,room:value,messages});
      
    }
    await AsyncStorage.removeItem('depo')
  }

    // const check_msg = async()=>{
    //   const value = await AsyncStorage.getItem('id');
    //   const value2 = await AsyncStorage.getItem('msg');
    //   if(value != null&&value2 !=null){
    //   console.log("🚀 ~ file: support.js ~ line 160 ~ constcheck_msg=async ~ value2", value2)
    //   console.log("🚀 ~ file: support.js ~ line 160 ~ constcheck_msg=async ~ value", value)
        
    //       if(value != value2){
    //         messages.length = 0
    //         console.log("🚀 ~ file: support.js ~ line 165 ~ constcheck_msg=async ~ messages", messages)
    //       }

    //   }
    // }
    useFocusEffect(
      React.useCallback(() => {
          console.log('my is focused')
          getData();
          connectSocket();
          mgsDepo();
         // check_msg()
      },[])
  )

    const scrollToBottomComponent = () =>{
        return(
            <MaterialCommunityIcons
            name='arrow-down'
            size={22}
            color='#333'
            />
        )
    }

    const renderBubble = (props) =>{
        return(
        <Bubble 
         {... props}
         wrapperStyle={{
             right:{
                 backgroundColor: '#2e64e5'
             },
             left:{
              backgroundColor: '#dbdbdb'
             }
         }}
         textStyle={{
             right:{
                 color:'#fff'
             }
         }}
        />
        );
    }
    const openImagePickerAsync = async () => {
        let permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync()
        const value = await AsyncStorage.getItem('id');
        if (permissionResult.granted === false) {
            Alert.alert(i18n.t('permissiongallery'))
            return
        }

        let pickerResult = await ImagePicker.launchImageLibraryAsync({
            base64: true,
            quality: 1,
          })
        if (pickerResult.cancelled === true) {
            return
        }
        
        setSelectedImage( pickerResult.uri );
        console.log(pickerResult.uri);
        let messages =[ {
            
          _id: Math.round(Math.random() * 1000000),
          createdAt: new Date(),
          user: {
            _id: 1,
          },
          image: img64+pickerResult.base64,
          pending: true,
        }];
        setModal(false)
        //setMessages(previousMessages => GiftedChat.append(previousMessages, sent));
        socket.emit("chat message", {room:value,messages});
    }
    const pickDocument = async () => {
      let result = await DocumentPicker.getDocumentAsync({});
      console.log(result.uri);
      console.log(result);
      
      let sent = {
            
        _id: Math.round(Math.random() * 1000000),
        text: result.name,
        createdAt: new Date(),
        user: {
          _id: 1,
        },
        pending: true,
        };
        setModal(false)
        setMessages(previousMessages => GiftedChat.append(previousMessages, sent));
        
    };
    const loadhistory = async()=>{
      setLoadingHis(true);
      const value = await AsyncStorage.getItem('id');
      let check=[];
      console.log("kosong="+yesterdayDate);
      if(yesterdayDate ==""){
        if(messages.length == 0){
          let today = new Date();
          let yesterday = new Date(today);
          let ti =  yesterday.setDate(yesterday.getDate() - 1)
          let time = new Date(today).toISOString().slice(0, 10);
          console.log(time);
          setYesterdayDate(time);
          socket.emit("chathistory2",{user: value, date: time});
            socket.on("history2", (data)=>{
              setMessages(previousMessages => GiftedChat.append(previousMessages, data.messages));
            })
        }else{
          let today = new Date();
          let yesterday = new Date(today);
          let ti =  yesterday.setDate(yesterday.getDate() - 1)
          let time = new Date(today).toISOString().slice(0, 10);
          console.log(time);
          setYesterdayDate(time);
        }
      }else{

        let yesterday = new Date(yesterdayDate);
        let ti =  yesterday.setDate(yesterday.getDate() - 1)
        let time = new Date(ti).toISOString().slice(0, 10);
        console.log(time);
        setYesterdayDate(time);
        socket.emit("chathistory2",{user: value, date: time});
          socket.on("history2", (data)=>{
            setMessages(previousMessages => GiftedChat.append(previousMessages, data.messages));
          }) 
      }
      setLoadingHis(false);
    };
    return (
       <InternetConnectionAlert
            onChange={(connectionState) => {
                // if (isConnected===true){
                //   console.log("Connection State: ", connectionState);}
                //   else {
                //     console.log("no connect")
                //   }
            }}
            >
      <>
      <SafeAreaView style={styles.container}>
        <View style={globalStyles.Header}>
          <Text style={globalStyles.Headtitle}>{i18n.t('service')}</Text>
        </View>
        </SafeAreaView>
        { login &&<GiftedChat
         messages={messages.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))}
          onSend={(messages) => onSend(messages)}
          user={{
            _id: 1,
          }}
          renderBubble={renderBubble}
          alwaysShowSend
          renderSend={renderSend}
          scrollToBottom
          renderAvatar={null}
          scrollToBottomComponent={scrollToBottomComponent}
          textInputStyle={{
            width: 300,
            height: 40,
            backgroundColor: "#fff",
            paddingVertical: 10,
            paddingHorizontal: 15,
            marginTop:5,
            borderColor: "#ccc",
            borderWidth: 1,
            borderRadius: 15,
            fontSize: 16,
          }}
          infiniteScroll = {false}
          loadEarlier={true} // this was missing
           onLoadEarlier={loadhistory}
           isLoadingEarlier ={loadingHis}
          renderActions={() => {
            return (<View>
              <TouchableOpacity
                style={styles.button}
                onPress={() => setModal(!modal)}
              >
                <MaterialCommunityIcons
                    name="plus-circle"
                   styles={{marginBottom:5}}
                   size={32} 
                   color='#2e64e5'
                   />

              </TouchableOpacity>
              </View>
            );
          }}
        />
      }
      { login &&
        <Modal
          isVisible={modal}
          onBackdropPress={() => setModal(false)}
          style={styles.modal}
        >
        <View style={styles.btnModal}>
            <Button title="Photo" onPress={openImagePickerAsync}/>
            <Button title="File" onPress={pickDocument} />
        </View>
        <View style={styles.modalspace}></View>
          <View style={styles.modalbox}>
            <Button title="Cancel" onPress={() => setModal(!modal)} />
          </View>
        </Modal>
      }
      {!login &&
             <AwesomeAlert
             show={true}
             showProgress={false}
             title={i18n.t('alertmessage')}
             message={i18n.t('PleaseLoginFirst')}
             closeOnTouchOutside={true}
             closeOnHardwareBackPress={false}
   
             showConfirmButton={true}
             confirmText={i18n.t('close')}
             confirmButtonColor="#DD6B55"
   
             onConfirmPressed={() => {
              return false;
             }}
           />
            }
      </>
      </InternetConnectionAlert>
    );
}

const styles = StyleSheet.create({
    button: {
        borderRadius: 10,
        marginBottom:5 , 
    },
    modalbox:{
        backgroundColor: '#F7F7F7',
        borderWidth: 1,
        borderColor: 'lightgray',
        borderStyle: 'solid',
        paddingHorizontal: 20,
        paddingVertical: 10,
        borderRadius: 10,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.8,
        shadowRadius: 2,
        paddingBottom:10
    },
    btnModal:{
        backgroundColor: '#F7F7F7',
        borderWidth: 1,
        borderColor: 'lightgray',
        borderStyle: 'solid',
        paddingHorizontal: 20,
        paddingVertical: 10,
        borderRadius: 10,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.8,
        shadowRadius: 2,
        elevation: 1, 
    },
    modal:{
        justifyContent: 'flex-end',
        margin: 0,
    },
    modalspace:{
      borderWidth: 1,
      padding:2
    },
})