import React, { useState, useRef, useEffect } from "react";
import {
  Alert,
  Dimensions,
  Image,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  Button,
  View,
} from "react-native";
import Ionicons from "@expo/vector-icons/Ionicons";
import * as ImagePicker from "expo-image-picker";
import { useDispatch, useSelector } from "react-redux";
import { Appbar, Provider as PaperProvider } from "react-native-paper";
import axios from "axios";
import * as Constant from "../../utils/constant";
import { useFocusEffect } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import ContentLoader from "react-native-easy-content-loader";
import { globalStyles } from "../../../style/globalstyles";
import InternetConnectionAlert from "react-native-internet-connection-alert";
import { SafeAreaView } from "react-native-safe-area-context";
import i18n from 'i18n-js';
import { zh, en } from '../../utils/supportedLanguages';
import DateTimePickerAndroid from "@react-native-community/datetimepicker";
const windowHeight = Dimensions.get("window").height;
import AwesomeAlert from 'react-native-awesome-alerts';
// const dimensionHeight = useWindowDimensions()
import * as api from '../../utils/api';

export default function Paynow({ navigation, route }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [payment, setPayment] = useState("");
const {orderid,id,monthpay} = route.params
  const img64 = "data:image/jpg;base64,";
  const [slip, setSlip] = useState("");
  i18n.fallbacks = true;
  i18n.translations = { en, zh };

  const [month,setMonth] = useState("");
  const [mode,setMode] = useState("date");
  const [date, setDate] = useState(null);
  const [show, setShow] = useState(false);
  const [msgalert1,setMsgAlert1] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const showPicker = (currentMode) => {
    setShow(true);
    setMode(currentMode);
  }

  useEffect(() => {
    const today = new Date();
    const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    setDate(date);
  }, []);

  const onHomeClicked = () => {
    navigation.navigate('Repayment');
  };

  const onUpdateClicked = async () => {
    
    if(payment == ""){
        setMsgAlert1(true);
        setAlertMessage(i18n.t('repayrequired'))
        return;
    }
    else if(slip === ""){
      setMsgAlert1(true);
      setAlertMessage(i18n.t('repaypic'))
      return
    }
    else {
      var ids = await AsyncStorage.getItem('id');
      console.log("🚀 ~ file: PaynowScreen.js ~ line 79 ~ onUpdateClicked ~ ids", ids)
      let m = new Date(month)
      let idloan = id;
      console.log("🚀 ~ file: PaynowScreen.js ~ line 82 ~ onUpdateClicked ~ idloan", idloan)
      try {
        var res = await api.p_userpay(parseFloat(payment).toFixed(2), date,slip,idloan);
        console.log("🚀 ~ file: PaynowScreen.js ~ line 83 ~ onUpdateClicked ~ res", res)
        if(res == 200){
          setLoading(false);
          navigation.navigate("Wallet")
        }else {
          Alert.alert(res)
        }
      } catch (err) {
       console.log(err);    
      }
    }
    
  };

  const openImagePickerAsync = async (pic) => {
    let permissionResult =
      await ImagePicker.requestMediaLibraryPermissionsAsync();

    if (permissionResult.granted === false) {
      Alert.alert(i18n.t('permissiongallery'));
      return;
    }
    let pickerResult = await ImagePicker.launchImageLibraryAsync({
      base64: true,
      quality: 1,
    });
    if (pickerResult.cancelled === true) {
      return;
    }
    switch (pic) {
      case "bill":
        setSlip(pickerResult.base64);
        break;
      default:
        console.log("errro");
    }
  };

  const renderBillView = () => {
    if (slip === "") {
        return <Text style={[globalStyles.outlineText,{textAlign:"center"}]}>{i18n.t('uploadtran')}</Text>
    } else {
        // console.log(img64+slip)
        return <Image source={{ uri: img64+slip }} style={styles.imageIC}></Image>
    }
  }

  useFocusEffect(
    React.useCallback(() => {
        //setLoading(true);
      console.log("Withdrawal is focused");  
      setPayment(monthpay)    
    }, [])
  );

  return (
    <InternetConnectionAlert
    onChange={(connectionState) => {
        console.log("Connection State: ", connectionState);
    }}
    >
      <SafeAreaView style={globalStyles.container}>
    <View style={globalStyles.body}>
      <Appbar.Header style={globalStyles.appHeader}>
        <Appbar.BackAction onPress={onHomeClicked} />
        <Appbar.Content title={i18n.t('paynow')} />
      </Appbar.Header>
      <ContentLoader active loading={loading}>
      <View style={[globalStyles.bodycontainer,{top:40}]}>
      <Text style={[globalStyles.title,{color:'#000000',alignSelf:"center",marginBottom:10}]}>
             {orderid}
              </Text>
        <View style={styles.inputContainer}>
          <Ionicons name="cash-outline" size={15} color={"#A0A0A0"} />
          {/* <Text style={{flex:1,borderBottomWidth: 2,paddingStart: 10,marginTop:5,marginBottom:5,fontSize:15}}>RM{parseFloat(monthpay).toFixed(2)}</Text> */}
          <TextInput
            defaultValue={parseFloat(monthpay).toFixed(2)}
            style={styles.input}
            keyboardType="numeric"
            placeholder={i18n.t('amount')}
            onChangeText={(text) => {
              setPayment(text);
            }}
          />
        </View>

        <View style={styles.inputContainer}>
          <Ionicons name="calendar" size={15} color={"#A0A0A0"} />
          <View style={{flexDirection:"row"}}>
          <Text style={{flex:0.98,borderBottomWidth: 2,paddingStart: 10,marginTop:5,marginBottom:5,fontSize:15}}>{date}</Text>
          {/* <Button title="DatePicker" onPress={() => showPicker('date')}/> */}
          </View>
          {/* {show && (<DateTimePickerAndroid
            testID='dateTimePicker'
            value={date}
            mode={mode}
            display='default'
            onChange={onChange}
          />
          )} */}
        </View>

        <View style={[styles.inputContainer,{justifyContent:"center"}]}>
                <TouchableOpacity style={styles.uploadIcContainer} onPress={()=>{openImagePickerAsync("bill")}}>
                    {renderBillView()}
                </TouchableOpacity>
                </View>

        <TouchableOpacity style={styles.btnLoan} onPress={onUpdateClicked}>
          <Text style={styles.textBtn}> {i18n.t('submit')}</Text>
        </TouchableOpacity>
      </View>
      </ContentLoader>
    </View>
    <AwesomeAlert
          show={msgalert1}
          showProgress={false}
          message={alertMessage}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}

          showConfirmButton={true}
          confirmText={(i18n.t('close'))}
          confirmButtonColor="#DD6B55"

          onConfirmPressed={() => {
           setMsgAlert1(false)
          }}
        />
    </SafeAreaView>

    </InternetConnectionAlert>
  );
}

const styles = StyleSheet.create({
  body: {
    flex: 1,
    backgroundColor: "#F7F7F7",
    marginTop: 20,
  },
  inputContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 10,
    marginVertical: 5,
    width: "100%",
  },
  input: {
    flex: 1,
    height: 50,
    borderBottomWidth: 1,
    paddingStart: 10,
  },
  uploadIcContainer: {
    borderWidth:1,
    borderColor:"#ff0033",
    borderRadius:10,
    width:200,
    height:200,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    margin:20,
    padding:50
  },
  text: {
    color: "gray",
    marginVertical: 10,
  },
  textBtn: {
    fontSize: 15,
    color: '#ffffff',
    fontWeight: 'bold',
    alignSelf:"center"
  },  
  btnLoan: {
    width: '70%',
    borderRadius: 20,
    marginTop: 20,
    padding: 10,
    marginBottom:5,
    backgroundColor:'#ff0033',
    alignSelf: 'center'
  },
  imageIC: {
    width: "200%",
    height: undefined,
    maxHeight: 200,
    aspectRatio: 1,
    resizeMode: "contain",
  },
});
