import React, { memo, useEffect, useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View, Image, ScrollView,useWindowDimensions, Modal, Alert, BackHandler,KeyboardAvoidingView} from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
//import { Slider } from '@miblanchard/react-native-slider';
//import ButtonToggleGroup from 'react-native-button-toggle-group';
import * as Constant from '../utils/constant';
import Checkbox from 'expo-checkbox';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { globalStyles } from '../../style/globalstyles';
import ContentLoader from "react-native-easy-content-loader";
import InternetConnectionAlert from "react-native-internet-connection-alert";
import { SafeAreaView } from 'react-native-safe-area-context';
import OneSignal from 'react-native-onesignal';
import { Slider,ButtonGroup  } from "@rneui/themed";
import { FontAwesome5 } from '@expo/vector-icons';
import AwesomeAlert from 'react-native-awesome-alerts';
import i18n from 'i18n-js';
import { zh, en } from '../utils/supportedLanguages';
import { useAppState } from '@react-native-community/hooks';
import { Manager } from "socket.io-client";
import { TextInput } from 'react-native-paper';
import *  as api  from "../utils/api.js";

export default function Home({ navigation }) {
    
    const dimensionHeight = useWindowDimensions()
    const dispatch = useDispatch()
    const[login,setLogin] = useState(true);
    const { isLoggedIn } = useSelector(state => state.reducer)
    const [selectedLoanAmount, setSelectedLoanAmout] = useState(0)
    const [month, setMonth] = useState(0)
    const [minAmount, setminAmount] = useState(0)
    const [Amountmin,setAmountMin] = useState();
    const [maxAmount, setmaxAmount] = useState(100)
    const [AmountMax,setAmountMax] =  useState();
    const [serviceRate, setserviceRate] = useState(0)
    const [monthlyRepayment, setMonthlyRepayment] = useState(0)
    const [toggleCheckBox, setToggleCheckBox] = useState(false);
    const [username, setUsername] = useState();
    const [loanStatus, setLoanStatus] = useState("");
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(0);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [msgalert1,setMsgAlert1] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [language,setLanguage] = useState("");
    const [loanData, setLoanData] = useState({
        monthSelection: [],
    })
    const [userIc, setUserIc] = useState("");
    const [bankacc,setBankAcc] = useState();
    const [btnMonth, setBtnMonth] = useState();
    const [userProfile, setUserProfile] = useState();
    const [trymonth,setTrymont] =  useState();
    const [exceedlimit, setExceedLimit] = useState(false);
    const [loanInter,setLoanInter] = useState('');
    const [termstext, setTermText] = useState();
    i18n.fallbacks = true;
    i18n.translations = { en, zh };
    // const manager = new Manager('http://txitech.dyndns.info', {
    //     autoConnect: true,
    //     transports: ['websocket'],
    //     path: "/chat"
    //   });
    //   const socket = manager.socket("/");
    const onLoanClicked = () => {
        console.log("🚀 ~ file: home.js ~ line 69 ~ onLoanClicked ~ userProfile", userIc)
        console.log(userIc)
        if (!login){
            setMsgAlert1(true);
            setAlertMessage(i18n.t('PleaseLoginFirst'))
            
        }
        else if(userIc == "tiada"){
            
            setMsgAlert1(true);
            setAlertMessage("Please update your Ic")
        }else if(userProfile){
            setMsgAlert1(true);
            setAlertMessage("Please update your Profile")
        }
        else if(bankacc){
            setMsgAlert1(true);
            setAlertMessage("Please update your bank acc")
        }
        else if(loanStatus == "cannot"){
            setMsgAlert1(true);
            setAlertMessage(i18n.t('madeloan'))
        }
        else if(!toggleCheckBox){
            setMsgAlert1(true);
            setAlertMessage(i18n.t('agreement'))
        }
        else{
            let m =[];
        loanData.monthSelection.map((e)=>{
           m.push(e);
        });
            navigation.navigate('LoanPreview',{
                loanAmount: loanInter,
                loanTerm: m[month],
                monthlyRepayment: monthlyRepayment,
                interest: serviceRate
            })
        }
    }

    const setStep = () => {
        let result = 1;
        let max = maxAmount;
        const length =  parseInt(maxAmount).length
        for (let i = 1; i < length; i++) {
            result = result*10;
        }
        return result
    }

    const calculateRepayment = () =>{
        let m =[];
        loanData.monthSelection.map((e)=>{
           m.push(e)
        });
        if(m.length == 0 ){
            m.push(3);
        }
        console.log("🚀 ~ file: home.js ~ line 122 ~ calculateRepayment ~ m", m)

        const interest =  (serviceRate / 100)* selectedLoanAmount;
        setLoanInter(interest + parseFloat(selectedLoanAmount));
        const totalRepayment = (interest + parseInt(selectedLoanAmount)) / m[month]
        console.log("🚀 ~ file: home.js ~ line 133 ~ calculateRepayment ~ month", month)
        // console.log("repaymentamount",selectedLoanAmount);
        setMonthlyRepayment(totalRepayment)
        // console.log(`repayment = ${totalRepayment}`)
    }


    
const ic_user = async()=>{
    try{
        var res = await api.check_user_ic();
        console.log("🚀 ~ file: home.js ~ line 137 ~ constic_user=async ~ res", res)
        switch(res){
            case "no ic":
                setUserIc("tiada");
                break;
            case "no profile":
                setUserIc("ada");
                setUserProfile(true);
                break;
            case "no account":
                setUserIc("ada");
                setUserProfile(false);
                setBankAcc(true)
                break;
            default:
                setUserIc("ada");
                setUserProfile(false);
                setBankAcc(false)
                console.log("all have");
                break;
        }
    }catch(e){
        console.log(e);
    }
}
    const fetchLoanDataFromApi = async () =>{
        console.log("fetchLoanDataFromApi")
        try {
            var loan_limit = await api.loan_limit();
            setminAmount(loan_limit.MinAmount);
            setAmountMin(loan_limit.MinAmount)
            setmaxAmount(loan_limit.MaxAmount);
            setAmountMax(loan_limit.MaxAmount);
            //setMonth(response.data[0].InitialTermLoan);
            setserviceRate(loan_limit.Interest);
            setSelectedLoanAmout(loan_limit.InitialAmount);
            loan_limit.TermLoan.map(e=>{
                console.log("map dalam fetch",e);
                setTrymont(e);
            })            
            setLoanData(prevloan =>{
                return{
                    ...prevloan,
                    monthSelection: loan_limit.TermLoan,
                }
            });
        } catch (error) {
            
            console.log(error)
            
        }
        console.log("🚀 ~ file: home.js ~ line 183 ~ fetchLoanDataFromApi ~ setTermText", trymonth)

        console.log("192,", loan_limit.TermLoan);
    }
    const getData = async () => {
        try {
        const value = await AsyncStorage.getItem('id');
        const name = await AsyncStorage.getItem('name');
        const lang = await AsyncStorage.getItem('language');
        console.log("🚀 ~ file: home.js ~ line 204 ~ getData ~ lang", lang)
        i18n.locale = lang
        
        setLogin(true)
        if(value != null) {
         
            if(name != null){

                setUsername(name)
            }
            if(lang != null){
               
                setLanguage(lang)
            }
        }else{ 
            setUsername()
            setLogin(false)
        }
        } catch(e) {
            console.log(e);
        }
        
    }
    const fetchapiLoan = async() =>{
        try {
            var check_loan = await api.check_loan_status();
            console.log("🚀 ~ file: home.js ~ line 214 ~ fetchapiLoan ~ check_loan", check_loan)
            if(check_loan == "Have data"){
                setLoanStatus("cannot");
            }else{
                setLoanStatus("can");
            }
            console.log("🚀 ~ file: home.js ~ line 232 ~ fetchapiLoan ~ setLoanStatus", loanStatus)

            setLoading(false)
          } catch (error) {
            console.log(error + "loan");
          }
    }
    OneSignal.setNotificationWillShowInForegroundHandler(notificationReceivedEvent => {
        console.log("OneSignal: notification will show in foreground:", notificationReceivedEvent);
        let notification = notificationReceivedEvent.getNotification();
        const data = notification.additionalData

        notificationReceivedEvent.complete(notification);
      });
      
      OneSignal.setNotificationOpenedHandler(notification => {
      });
      const buts = () =>{
        let ada = [] ;
        ada = loanData.monthSelection;
        if(!ada){
         return ([3,6]);
        } else{
          
         return (
               ada)
        }
     }
    // const currentAppState = useAppState()
     //console.log(currentAppState);
    //  socket.on("users_online", (data)=>{
    //     console.log("dekat app.js id",data)
    //    socket.emit("online_user", data);
    //   })
    //   const stay_connect =async()=>{
    //     const value = await AsyncStorage.getItem('id');
    //     const name = await AsyncStorage.getItem('name');
        
    //     let test =[];
    //     console.log(value);
    //     console.log(name);
    //     if(value == null&& name==null){
    //       console.log("no");
    //     }else{
          
    //       socket.on("connect", () => {
    //         AsyncStorage.setItem('socket_id', socket.id);
    //           console.log(socket.id); // x8WIv7-mJelg7on_ALbx
    //         });
    //         socket.emit("new user", {name: name, room:value});
    //     }
    //   }

    useFocusEffect(
        React.useCallback(() => {
            //setLoading(true);
            fetchLoanDataFromApi();
            //stay_connect();
            getData();
            pushNoti()
            console.log('screen is focused')
            ic_user();
            fetchapiLoan();
            console.log("home language",language)
        },[])
    )
    const pushNoti = async()=>{
        var ids = await AsyncStorage.getItem("id");
        let externalUserId = '123456789';
        const deviceState = await OneSignal.getDeviceState();
        console.log(deviceState);
        console.log("for noti",ids)
        if(ids == null){
            return;
        }else{
           // console.log(ids)
            OneSignal.setExternalUserId(ids, (results) => {
              // The results will contain push and email success statuses
             console.log('Results of setting external user id');
             console.log(results);
              
              // Push can be expected in almost every situation with a success status, but
              // as a pre-caution its good to verify it exists
              if (results.push && results.push.success) {
               console.log('Results of setting external user id push status:');
               console.log(results.push.success);
              }
              
              // Verify the email is set or check that the results have an email success status
              if (results.email && results.email.success) {
             //   console.log('Results of setting external user id email status:');
             //   console.log(results.email.success);
              }
            
              // Verify the number is set or check that the results have an sms success status
              if (results.sms && results.sms.success) {
              //  console.log('Results of setting external user id sms status:');
              //  console.log(results.sms.success);
              }
            });
        }
       
    }
    useEffect(calculateRepayment,[month, serviceRate, selectedLoanAmount])
const onComfirm =()=>{
            setExceedLimit(false)
}
    return (
         <InternetConnectionAlert
            onChange={(connectionState) => {
                 console.log("Connection State: ", connectionState.isConnected);
                 if(connectionState.isConnected){
                     setLoading(false);
                 }
            }}
            >
                 <ContentLoader active loading={loading}>
        <SafeAreaView style={[globalStyles.container,{position: "absolute",width:"100%",height:"100%"}]}>
            {/* <KeyboardAwareScrollView> */}
            { login &&
        <View style={globalStyles.Header}>
                <Text style={{color:"#ffffff",fontSize:20}}> {i18n.t('welcomeback')} </Text>
                <Text style={globalStyles.Headtitle}>{(username == "") ? "": username} </Text></View>}
            {!login &&    <View style={globalStyles.Header}>
                <Text style={globalStyles.Headtitle}> {i18n.t('hello')} </Text></View>}
               
            <View style={globalStyles.firstView}>
                <Text style={[globalStyles.whiteText,{marginTop:5}]}> {i18n.t('MaxAmountLoan')} </Text>
                <Text style={[globalStyles.Headtitle,{marginTop:5}]}> {maxAmount ? AmountMax : 0} </Text>
                <View style={{ width: '90%', marginBottom: 5 }}>
                    <Slider
                            value={selectedLoanAmount}
                            onValueChange={value => setSelectedLoanAmout(parseInt(value))}
                            maximumValue={parseInt(maxAmount)}
                            minimumValue={parseInt(minAmount)}
                            step={setStep() * 0.01}
                            allowTouchTrack
                            trackStyle={{ height: 5, backgroundColor: 'transparent' }}
                            thumbStyle={{ height: 20, width: 20, backgroundColor: 'transparent' }}
                            thumbTintColor={'white'} 
                            minimumTrackTintColor={'white'} 
                            maximumTrackTintColor={'black'} 
                            thumbProps={{
                            children: (
                                <FontAwesome5 
                                name="arrow-alt-circle-right"
                                size={20}
                                color="#DCDCDC"
                                reverse
                                containerStyle={{ bottom: 20, right: 20 }}
                                />
                            ),
                            }}
                        />
                        <View style={{flexDirection:'row',justifyContent:'center'}}>
                        <Text style={globalStyles.whiteText}> {i18n.t('loanAmount')}: </Text>
                        <TextInput
                            value={selectedLoanAmount.toString()}
                           //defaultValue={selectedLoanAmount}
                            style={{height: 20,
                                borderBottomWidth: 1.0,
                                width:70,
                                borderBottomColor:"#fff",
                                backgroundColor:"#ff0033",
                                }}
                                theme={{
                                    colors: {
                                          text: 'white',
                                       }
                                 }}
                                maxLength={5}
                                keyboardType='number-pad'
                                onChangeText={(text) => {
                                    if(parseInt(text) > maxAmount){
                                        // Alert.alert("exist limit",[{ text: "OK", onPress: () => }])
                                        // Alert.alert(
                                        //     i18n.t('alertmessage'),
                                        //     i18n.t('exist'),
                                        //     [
                                            
                                        //     { text: "OK", onPress: () => setSelectedLoanAmout(minAmount.toString()) }
                                        //     ]
                                        // );
                                    //setMsgAlert1(true);
                                    setExceedLimit(true);
                                    //console.log("🚀 ~ file: home.js ~ line 455 ~ Home ~ exceedlimit", exceedlimit)

                                    // if(!exceedlimit){
                                    //     setSelectedLoanAmout(minAmount)
                                    // }
                                    setSelectedLoanAmout(minAmount);
                                    }else{
                                        if (text.includes(' ')) {
                                            setSelectedLoanAmout(text.trim()); 
                                        }else{
                                           
                                            setSelectedLoanAmout(text)
                                            setValue(text);
                                        }
                                    }
                                    
                                
                                }} 
                        />
                        </View>
                </View>
                <View style={{ width: '90%' }}>
                    {/* <ButtonToggleGroup
                        highlightBackgroundColor={'#909090'}
                        highlightTextColor={'white'}
                        inactiveBackgroundColor={'transparent'}
                        inactiveTextColor={'white'}
                        values={(trymonth.length == 0) ? ["sss","sss"]: trymonth}
                        onSelect={val => setMonth(val)}
                        value={month}
                    /> */}
                      <ButtonGroup
                        buttons={(buts() == "") ? ["3","6"]: buts()}
                        selectedIndex={selectedIndex}
                        onPress={(value) => {
                            setSelectedIndex(value);
                            setMonth(value)
                        }}
                        
                        containerStyle={{ marginBottom: 20 }}
                        />
                </View>
                </View>
                
                <View style={[globalStyles.row,{justifyContent:'center'}]}>
                    <Text style={globalStyles.blackText}> 1. {i18n.t('fill_the_Info')}</Text>
                    <Text style={[globalStyles.blackText,{marginStart:5}]}> 2. {i18n.t('quick_loan')}</Text>
                </View>
                <View style={{alignItems:'center', marginTop:5}}>
                    <Text style={globalStyles.blackText}> {i18n.t('Monthly_Repayment')}: <Text style={{fontWeight:'bold'}}>RM {parseFloat(monthlyRepayment).toFixed(2)}</Text> ( {serviceRate}% ) </Text>
                </View>
                <View style={[globalStyles.row,{justifyContent:'center'}]}>
                    <Checkbox style={globalStyles.blackText}value={toggleCheckBox} onValueChange={(newValue => setToggleCheckBox(newValue))}/>
                    <Text style={globalStyles.blackText}> {i18n.t('user_loan_agreement')}</Text>
                </View>
                <TouchableOpacity style={[globalStyles.outlineBtn,{width:"70%"}]} onPress={onLoanClicked}>
                    <Text style={globalStyles.outlineText}> {i18n.t('Loan_immediately')}</Text>
                </TouchableOpacity>
                
            <View style={{flexDirection: 'row',height:'20%'}}>
            <View style={styles.secondView} >
                <View style={styles.childViewContainer}>
                    <View style={styles.childContainer1}>
                        <Text style={styles.textSmall}>{i18n.t('Joined')}</Text>
                        <Text style={styles.textBig}>167,209 <Text style={styles.textSmall}>{i18n.t('Member')}</Text></Text>
                       
                    </View>
                    </View>
                    </View>
                    <View style={styles.secondView} >
                    <View style={styles.childViewContainer}>
                    <View style={styles.childContainer1}>
                    <Text style={[styles.textSmall]}>{i18n.t("Loaned_out")}</Text>
                        <Text style={styles.textBig}>3,910,291 </Text>
                    </View>
                    </View>
                </View>
                </View>
                <View style={styles.thirdView}>
                <View style={styles.childViewContainer}>
                    <View style={styles.childContainer1}>
                        <Text style={[styles.textBig, { fontWeight: 'bold' }]}>IBOC</Text>
                        <Text style={[styles.textBig, { marginTop: 5 }]}>311,456,234</Text>
                        <Text style={[styles.textSmall, { marginTop: 5 }]}>{i18n.t('Cumulative_transaction_amount')}</Text>
                    </View>
                    <View style={[styles.childContainer2]}>
                        <Image source={require('../../assets/trend.png')} style={[styles.imgSecondView]} />
                    </View>
                </View>
                </View>
                <AwesomeAlert
          show={msgalert1}
          showProgress={false}
          message={alertMessage}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}

          showConfirmButton={true}
          confirmText={i18n.t('close')}
          confirmButtonColor="#DD6B55"

          onConfirmPressed={() => {
            //setSelectedLoanAmout(minAmount)
           setMsgAlert1(false)
          }}
        />
          <AwesomeAlert
          show={exceedlimit}
          showProgress={false}
          message={alertMessage}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}

          showConfirmButton={true}
          confirmText={i18n.t('close')}
          confirmButtonColor="#DD6B55"

          onConfirmPressed={onComfirm}
        />
        {/* </KeyboardAwareScrollView> */}
        </SafeAreaView>
        </ContentLoader>
</InternetConnectionAlert>
    )
}

const styles = StyleSheet.create({
    childViewContainer: {
        flex: 1,
        margin: 10,
        flexDirection: 'row',
    },

    secondView: {
        flex: 1,
        width: '95%',
        borderRadius:10,
        backgroundColor:'#ff0033',
        margin: 10,
        alignItems: 'center'
    },
    childContainer1: {
        flex: 1.0,
        justifyContent: 'center'
    },
    childContainer2: {
        flex: 0.3,
        alignItems: 'flex-end',
    },
    imgSecondView: {
        width: undefined,
        height: '100%',
        aspectRatio: 0.5,
        marginRight:10
    },
    textSmall: {
        fontSize: 15,
        color: '#FFFFFF',
        alignSelf: 'center'
    },
    textBig: {
        fontSize: 25,
        color: '#FFFFFF',
        alignSelf: 'center'
    },

    thirdView: {
        flex: 1,
        width: '95%',
        borderRadius:10,
        backgroundColor:'#000000',
        marginHorizontal: 10,
        marginBottom: 10,
    },
    modal: {
        justifyContent: 'flex-end',
        margin: 0,
      },
      modalContainer: {
        backgroundColor: '#fff',
        paddingHorizontal: 16,
        paddingTop: 20,
        paddingBottom: 40,
        alignItems: 'center',
      },
      modalTitle: {
        fontSize: 22,
        fontWeight: '600',
      },
      modalText: {
        fontSize: 18,
        color: '#555',
        marginTop: 14,
        textAlign: 'center',
        marginBottom: 10,
      },
      button: {
        backgroundColor: '#000',
        paddingVertical: 12,
        paddingHorizontal: 16,
        width: '100%',
        alignItems: 'center',
        marginTop: 10,
      },
      buttonText: {
        color: '#fff',
        fontSize: 20,
      },
})