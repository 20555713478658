import React, { useState } from 'react';
import { Alert, Dimensions, StyleSheet, Text, TextInput, TouchableOpacity,  View,TouchableWithoutFeedback,Keyboard,Platform } from 'react-native';
import FontAwesome from '@expo/vector-icons/FontAwesome';
import { useDispatch, useSelector } from 'react-redux';
import * as ImagePicker from 'expo-image-picker';
import { ProgressBar } from "react-native-paper";
import {
    Appbar , Provider as PaperProvider,
} from 'react-native-paper';
import * as Constant from '../../utils/constant';
import { SafeAreaView } from 'react-native-safe-area-context';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import { useFocusEffect } from '@react-navigation/native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {globalStyles} from '../../../style/globalstyles';
import ContentLoader from "react-native-easy-content-loader";
import i18n from 'i18n-js';
import { zh, en } from '../../utils/supportedLanguages';
const windowHeight = Dimensions.get('window').height
// const dimensionHeight = useWindowDimensions()
import InternetConnectionAlert from "react-native-internet-connection-alert";
import AwesomeAlert from 'react-native-awesome-alerts';
import * as api from '../../utils/api';

export default function Datainf({ navigation }) {

    const dispatch = useDispatch()
    i18n.fallbacks = true;
    i18n.translations = { en, zh };
    const [createUserModel, setCreateUserModel] = useState({
        CompanyName: '',
        Position: '',
        Address: '',
        MonthlyIncome: '',
        payslips: "", 
        bills: ""
    })
    const[loading,setLoading] = useState(false)
    const[methodapi, setMethodApi] = useState();
    const[paySlip,setPaySlip] = useState("");
    const[bill, setBill] =useState("");
    const onHomeClicked = () => {
        navigation.navigate('ProfileScreen');
    }
    const [msgalert1,setMsgAlert1] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const openImagePickerAsync = async (pic) => {
        let permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync()
        if (permissionResult.granted === false) {
            Alert.alert(i18n.t('permissiongallery'))
            return
        }
        let pickerResult = await ImagePicker.launchImageLibraryAsync({
                base64: true,
                quality: 1,
              })

        if (pickerResult.cancelled === true) {
            return
        }
        switch(pic){
            case"slip":
                console.log("salary");
                setPaySlip(pickerResult.base64);
                break;
            case "bill":
                console.log("bill");
                setBill(pickerResult.base64);
                break;
            default:
                console.log("errro");        
        }
    }

    const onUpdateClicked = async() => {
    
        if (createUserModel.CompanyName === '') {
        Alert.alert(i18n.t('companyrequired'))
        return
        }else if (createUserModel.Position === '') {
            Alert.alert(i18n.t('positionrequired'))
            return
        }else if (createUserModel.Address === '') {
            Alert.alert(i18n.t('addressrequired'))
            return
        }else if (createUserModel.MonthlyIncome === '') {
            Alert.alert(i18n.t('incomerequired'))
            return
        }else{
            setLoading(true);
            var ids = await AsyncStorage.getItem('id');
            if(methodapi === "post"){
                try{
                    var res = await api.p_userdetail(createUserModel.CompanyName,createUserModel.Position,
                        createUserModel.Address,createUserModel.MonthlyIncome,paySlip,bill);
                    if(res == 200){
                        setMsgAlert1(true);
                        setAlertMessage(i18n.t('complete'));
                        setLoading(false);
                        fetchLoanDataFromApi();
                    }
                }catch(e){
                    console.log(e);
                }
            }else{
                try{
                    var res = await api.Up_userdetail(createUserModel.CompanyName,createUserModel.Position,
                        createUserModel.Address,createUserModel.MonthlyIncome,paySlip,bill);
                    if(res == 200){
                        setLoading(false);
                        setMsgAlert1(true);
                        setAlertMessage(i18n.t('completeupdate'));
                        fetchLoanDataFromApi()
                    }
                }catch(e){
                    console.log(e);
                }
            }
            
        }
    };

    const fetchLoanDataFromApi = async () =>{
        try {
            console.log("okay masuk")
            var ids = await AsyncStorage.getItem('id');
            const response = await api.userdetatil();
            if(response.message === "No Data"){
                setMethodApi("post");
                setCreateUserModel(prevUser => {
                    return({
                        ...prevUser,
                        CompanyName: "",
                        Position: "",
                        Address: "",
                        MonthlyIncome: "",
                        payslips: "", 
                        bills: ""
                    })
                })   
            }else{
                setMethodApi("put");
                setPaySlip(response.Pic_PaySlip);
                setBill(response.Pic_UtilitiesBill)
                setCreateUserModel(prevUser => {
                    return({
                        ...prevUser,
                        CompanyName: response.userCompanyname,
                        Position: response.userPosition,
                        Address: response.userAddress,
                        MonthlyIncome: response.monthlyIncome,
                        payslips: response.Pic_PaySlip, 
                        bills: response.Pic_UtilitiesBill
                    })
                })    
            }
            console.log(methodapi);
            
            setLoading(false);          
        } catch (error) {
            console.log("ss",error)
        }    
    }
    const getData = async () => {
        try {
        const value = await AsyncStorage.getItem('id')
        if(value !== null) {
           //setID(value);
        }
        } catch(e) {
        // error reading value
        }
    }
    useFocusEffect(
        React.useCallback(() => {
            setLoading(true);
            console.log('datascreen is focused');
            fetchLoanDataFromApi();
            getData();
        },[])
    )
    return (
        <InternetConnectionAlert
        onChange={(connectionState) => {
            console.log("Connection State: ", connectionState);
        }}
        >
        <TouchableWithoutFeedback onPress={()=>{
            if(Platform.OS !== "web") Keyboard.dismiss();
            console.log('dissmiss');
        
          }}>
    <SafeAreaView style={styles.container}>
        <View style={globalStyles.body}>
            <Appbar.Header style={globalStyles.appHeader}>
            <Appbar.BackAction onPress={onHomeClicked} />
            <Appbar.Content
                    title={(i18n.t('uploadincomedocument'))}
                    />
            </Appbar.Header>
            <View style={globalStyles.bodycontainer}>
             <ContentLoader active  loading={loading}>
             <View style={styles.inputContainer}>
                    <Icon name='office-building' size={15} color={'#A0A0A0'} />
                    <TextInput
                        style={styles.input}
                        value={createUserModel.CompanyName}
                        placeholder={(i18n.t('entercompany'))}
                        onChangeText={(text) => {
                            setCreateUserModel((prevState) => {
                                return { ...prevState, CompanyName: text }
                            })
                        }} />
                </View>

                <View style={globalStyles.inputContainer}>
                    <FontAwesome name='user-o' size={15} color={'#A0A0A0'} />
                    <TextInput
                        style={styles.input}
                        placeholder={(i18n.t('enterposition'))}
                        value={createUserModel.Position}
                        onChangeText={(text) => {
                            setCreateUserModel((prevState) => {
                                return { ...prevState, Position: text }
                            })
                        }} />
                </View>

                <View style={globalStyles.inputContainer}>
                    <Icon name='map-marker-up' size={15} color={'#A0A0A0'} />
                    <TextInput
                        style={styles.input}
                        placeholder={(i18n.t('enteraddress'))}
                        value={createUserModel.Address}
                        onChangeText={(text) => {
                            setCreateUserModel((prevState) => {
                                return { ...prevState, Address: text }
                            })
                        }} />
                </View> 

                <View style={globalStyles.inputContainer}>
                    <Icon name='cash-multiple' size={15} color={'#A0A0A0'} />
                    <TextInput
                    keyboardType="numeric"
                        style={styles.input}
                        placeholder={(i18n.t('enterincome'))}
                        value={createUserModel.MonthlyIncome}
                        onChangeText={(text) => {
                            setCreateUserModel((prevState) => {
                                return { ...prevState, MonthlyIncome: text }
                            })
                        }} />
                </View> 
        <View style={styles.inputContainer}>
          <View style={{ width: "30%" }}>
            <TouchableOpacity
              style={styles.chooseBtn}
              onPress={() => {
                openImagePickerAsync("slip");
              }}
            >
              <Text style={{ color: "#ffffff" }}> {(i18n.t('Choosefile'))} </Text>
            </TouchableOpacity>
          </View>
          <View style={{ width: "auto" }}>
            <Text style={{ color: "#808080", textAlign: "center" }}>
              {(paySlip === ""|| paySlip === null )? i18n.t('uploadsalary'): createUserModel.payslips}
            </Text>
          </View>
        </View>
        <ProgressBar
          progress={(paySlip === ""|| paySlip === null)? 0: 1}
          color={"#ff0033"}
          style={{
            margin: 10,
            marginLeft: 20,
            marginRight: 20,
            height: 10,
            borderRadius: 30,
          }}
        >
          {" "}
        </ProgressBar>
        
        <View style={styles.inputContainer}>
          <View style={{ width: "30%" }}>
            <TouchableOpacity
              style={styles.chooseBtn}
              onPress={() => {
                openImagePickerAsync("bill");
              }}
            >
              <Text style={{ color: "#ffffff" }}> {(i18n.t('Choosefile'))} </Text>
            </TouchableOpacity>
          </View>
          <View style={{ width: "auto" }}>
            <Text style={{ color: "#808080", textAlign: "center" }}>
              {(bill === ""|| bill === null )? i18n.t('uploadbill'): createUserModel.bills}
            </Text>
          </View>
        </View>
        <ProgressBar
          progress={(bill === ""|| bill === null)? 0: 1}
          color={"#ff0033"}
          style={{
            margin: 10,
            marginLeft: 20,
            marginRight: 20,
            height: 10,
            borderRadius: 30,
          }}
        >
          {" "}
        </ProgressBar>

        <TouchableOpacity style={globalStyles.outlineBtn} onPress={onUpdateClicked}>
          <Text style={globalStyles.outlineText}> {i18n.t('submit')} </Text>
        </TouchableOpacity>
                </ContentLoader>
            </View>
            </View>
            </SafeAreaView>
        </TouchableWithoutFeedback>
        <AwesomeAlert
          show={msgalert1}
          showProgress={false}
          message={alertMessage}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}

          showConfirmButton={true}
          confirmText={i18n.t('close')}
          confirmButtonColor="#DD6B55"

          onConfirmPressed={() => {
           setMsgAlert1(false)
          }}
        />
        </InternetConnectionAlert>
    )
}

const styles = StyleSheet.create({
    body: {
        flex: 1,
        backgroundColor: '#F7F7F7',
        marginTop:20,
    },
    titleContainer: {
        width: '100%',
        height: 150,
        position: 'relative',
        backgroundColor: '#FEE16E',
        alignItems: 'center',
        fontWeight: 'bold',
        height: 50,
        backgroundColor: '#6200ee',
        alignItems: 'center',
        fontWeight: 'bold',
        bottom: 240,
    },
    title: {
        fontSize: 20,
        color: '#FFFFFF',
        marginTop: 35
    },
    userInfoSection: {
        paddingHorizontal: 70,
        marginBottom: 0,
        backgroundColor: "#ffffff",
        borderBottomColor: '#eeeeee',
        borderBottomWidth: 1,
    },
    row: {
        flexDirection: 'row',
        marginBottom: 7,
    },
    loginContainer: {
        flex: 1,
        width: '95%',
        backgroundColor: '#FFFFFF',
        position: 'absolute',
        alignSelf: 'center',
        justifyContent: 'center',
        top: 60,
    },
    inputContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 10,
        marginVertical: 5
    },
    input: {
        flex: 1,
        height: 50,
        borderBottomWidth: 0.5,
        paddingStart: 10
    },
    uploadIcContainer: {
        marginTop: 10,
        height: 100,
        width:100,
        borderRadius: 10,
        borderColor: '#000000',
        borderWidth: 0.5,
        backgroundColor: '#FEE16E',
        justifyContent: 'center',
        alignItems: 'center'
    },
    imageIC: {
        width: '100%',
        height: undefined,
        maxHeight: 200,
        aspectRatio: 1,
        resizeMode: 'contain'
    },
    registerBtn: {
        marginTop: 5,
        alignSelf: 'flex-end',
        marginEnd: 5
    },
    registerText: {
        fontSize: 13,
        color: '#98A5FD',
        fontWeight: 'bold'
    },
    loginBtn: {
        width: '95%',
        borderRadius: 20,
        backgroundColor: '#6200ee',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        marginTop: 20,
        paddingVertical: 10
    },
    loginText: {
        fontSize: 15,
        color: '#FFFFFF'
    },
    footer: {
        width: '100%',
        position: 'absolute',
        backgroundColor: '#F7F7F7',
        bottom: -50,
        alignItems: 'center'
    },
    footerText: {
        fontSize: 15,
        color: '#FEE16E'
    },
    text: {
        color: 'gray',
        marginVertical:10,
    },
    link: {
        color: '#FDB075'
    },
    container: {
        flex: 1,
    },
    checkboxContainer: {
        flexDirection: "row",
        marginBottom: 5,
    },
    
    checkbox: {
        alignSelf: "center",
    },
    chooseBtn: {
        borderWidth: 1,
        padding: 3,
        borderColor: "#ff0033",
        backgroundColor: "#ff0033",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        alignSelf: "flex-start",
        borderRadius: 5,
      },

})