import React from 'react';
import {View, Text , StyleSheet , Pressable} from 'react-native';

const CustomButton = ({onPress, text, type = "PRIMARY", bgColor, fgColor}) => {
    return (
        <Pressable 
        onPress={onPress} 
        style={[styles.container, styles[`container_${type}`],
        bgColor ? {backgroundColor: bgColor}:{},]}>
        <Text style={[styles.text, styles[`text_${type}`],
            fgColor ? {color: fgColor}:{},]}>{text}</Text>
        </Pressable>
        );
    };
    const styles = StyleSheet.create({
        container: {
            width: '35%',
            padding: 5,
            paddingRight:10,
            paddingTop:0,
            alignSelf: 'flex-end',
            borderRadius: 5,
        },

        container_PRIMARY: {
            backgroundColor: '#3B71F3',
        },

        container_TERTIARY: {
        },

        text: {
            fontWeight: 'bold',
            color: 'black',
        }
    });

export default CustomButton