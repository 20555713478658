import React, { useState } from 'react';
import { Alert, Dimensions, Image, Pressable, StyleSheet, Text, TextInput, TouchableOpacity, 
    useWindowDimensions, View,Button,ScrollView } from 'react-native';
    import {
        Title,RadioButton
    } from 'react-native-paper';
import CustomButton from '../../components/CustomButton'
import InternetConnectionAlert from "react-native-internet-connection-alert";


const windowHeight = Dimensions.get('window').height
// const dimensionHeight = useWindowDimensions()

export default function SystemSetting({ navigation }) {
    const [checked, setChecked] = React.useState('first');
    const onMyProfileClicked = () => {
        navigation.push('LoadSetting')
    }

    return (
        <ScrollView>
        <View style={styles.body}>
        {/* <View style={styles.userInfoSection,{padding:5,paddingBottom:10}}> */}
                <View style={styles.row}>
                <TouchableOpacity onPress={onMyProfileClicked}>
                    <Text style={[styles.Textstyles,{color:'#696969'
                            }]}>General settings </Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={onMyProfileClicked}>
                    <Text style={[styles.Textstyles,{color:'#696969'
                            }]}>Loan settings </Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={onMyProfileClicked}>
                    <Text style={[styles.Textstyles,{color:'#696969'
                            }]}>Wechat QR code </Text>
                </TouchableOpacity>
                        {/* </View> */}
                    </View>
        <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles,{
                            }]}>Review status {"\n"}
                            reminder content</Text>
                        <Text style={{color:"#777777",fontSize: 12,textAlign: "center",padding:5,paddingTop:10}}>Title and prompt content</Text>
                    </View>
                    </View>
        <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>First step status</Text>
                        <Text style={{color:"#ff4500",fontSize: 12,textAlign: "center",padding:5}}>=========================</Text>
                    </View>
                    </View>                        
        <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>Under review(9)</Text>
                             <View style={styles.userInfoSection}>
                             <View style={styles.row}>
                        <Text style={[styles.Textstyles3,{
                            }]}>正在审核</Text>
                            <Text style={[styles.Textstyles4,{
                            }]}>你的借款申请已提交，请主动联系在线客服办理！</Text>
                            </View>
                            </View>
                    </View>
                    </View>

        <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>Audit verification(2)</Text>
                             <View style={styles.userInfoSection}>
                             <View style={styles.row}>
                        <Text style={[styles.Textstyles3,{
                            }]}>正在审核</Text>
                            <Text style={[styles.Textstyles4,{
                            }]}>你的借款申请已提交，请主动联系在线客服办理！</Text>
                            </View>
                            </View>
                    </View>
                    </View>
        <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>The second state</Text>
                        <Text style={{color:"#ff4500",fontSize: 12,textAlign: "center",padding:5}}>=========================</Text>
                    </View>
                    </View> 

            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>Audit failed 1 (3)</Text>
                             <View style={styles.userInfoSection}>
                             <View style={styles.row}>
                        <Text style={[styles.Textstyles3,{
                            }]}>审核未通过1</Text>
                            <Text style={[styles.Textstyles4,{
                            }]}>审核未通过1审核未通过1审核未通过1审核未通过1</Text>
                            </View>
                            </View>
                    </View>
                    </View>

            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>Audit failed 2 (4)</Text>
                             <View style={styles.userInfoSection}>
                             <View style={styles.row}>
                        <Text style={[styles.Textstyles3,{
                            }]}>审核未通过2</Text>
                            <Text style={[styles.Textstyles4,{
                            }]}>审核未通过2审核未通过2审核未通过2审核未通过2</Text>
                            </View>
                            </View>
                    </View>
                    </View>


            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>Audit failed 3 (5)</Text>
                             <View style={styles.userInfoSection}>
                             <View style={styles.row}>
                        <Text style={[styles.Textstyles3,{
                            }]}>审核未通过3</Text>
                            <Text style={[styles.Textstyles4,{
                            }]}>审核未通过3审核未通过3审核未通过3</Text>
                            </View>
                            </View>
                    </View>
                    </View>

            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>Approved(8)</Text>
                             <View style={styles.userInfoSection}>
                             <View style={styles.row}>
                        <Text style={[styles.Textstyles3,{
                            }]}>审核通过</Text>
                            <Text style={[styles.Textstyles4,{
                            }]}>审核通过审核通过审核通过审核通过审核通过</Text>
                            </View>
                            </View>
                    </View>
                    </View>

            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>The third step state</Text>
                        <Text style={{color:"#ff4500",fontSize: 12,textAlign: "center",padding:5}}>=========================</Text>
                    </View>
                    </View> 

            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>Waiting for the next payment (11)</Text>
                             <View style={styles.userInfoSection}>
                             <View style={styles.row}>
                        <Text style={[styles.Textstyles3,{
                            }]}>等待下款</Text>
                            <Text style={[styles.Textstyles4,{
                            }]}>等待下款等待下款等待下款</Text>
                            </View>
                            </View>
                    </View>
                    </View>

            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>The next paragraph failed (23)</Text>
                             <View style={styles.userInfoSection}>
                             <View style={styles.row}>
                        <Text style={[styles.Textstyles3,{
                            }]}>下载失败</Text>
                            <Text style={[styles.Textstyles4,{
                            }]}>姓名和卡号不符，下载失败</Text>
                            </View>
                            </View>
                    </View>
                    </View>

            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>Account freeze(-2)</Text>
                             <View style={styles.userInfoSection}>
                             <View style={styles.row}>
                        <Text style={[styles.Textstyles3,{
                            }]}>账户冻结</Text>
                            <Text style={[styles.Textstyles4,{
                            }]}>账户检测为风险账户，已被冻结，请联系客服查看后台设置</Text>
                            </View>
                            </View>
                    </View>
                    </View>

            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>Fourth step status</Text>
                        <Text style={{color:"#ff4500",fontSize: 12,textAlign: "center",padding:5}}>=========================</Text>
                    </View>
                    </View> 

            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>Fund freeze 1(17)</Text>
                             <View style={styles.userInfoSection}>
                             <View style={styles.row}>
                        <Text style={[styles.Textstyles3,{
                            }]}>资金冻结1</Text>
                            <Text style={[styles.Textstyles4,{
                            }]}>资金冻结1(17)资金冻结1(17)资金冻结1(17)</Text>
                            </View>
                            </View>
                    </View>
                    </View>

            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>Fund freeze 2(18)</Text>
                             <View style={styles.userInfoSection}>
                             <View style={styles.row}>
                        <Text style={[styles.Textstyles3,{
                            }]}>资金冻结2</Text>
                            <Text style={[styles.Textstyles4,{
                            }]}>资金冻结2资金冻结2资金冻结2</Text>
                            </View>
                            </View>
                    </View>
                    </View>

            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>Fund freeze 3(19)</Text>
                             <View style={styles.userInfoSection}>
                             <View style={styles.row}>
                        <Text style={[styles.Textstyles3,{
                            }]}>资金冻结3</Text>
                            <Text style={[styles.Textstyles4,{
                            }]}>资金冻结3资金冻结3资金冻结3</Text>
                            </View>
                            </View>
                    </View>
                    </View>
            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>Fund freeze 4(20)</Text>
                             <View style={styles.userInfoSection}>
                             <View style={styles.row}>
                        <Text style={[styles.Textstyles3,{
                            }]}>资金冻结4</Text>
                            <Text style={[styles.Textstyles4,{
                            }]}>资金冻结4资金冻结4资金冻结4</Text>
                            </View>
                            </View>
                    </View>
                    </View>
            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>Request a refund(7)</Text>
                             <View style={styles.userInfoSection}>
                             <View style={styles.row}>
                        <Text style={[styles.Textstyles3,{
                            }]}>申请退款</Text>
                            <Text style={[styles.Textstyles4,{
                            }]}>您的退款申请已提交，3-7个工作日内自动到账，请注意查收短信！</Text>
                            </View>
                            </View>
                    </View>
                    </View>
            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>Cancellation of contract(15)</Text>
                             <View style={styles.userInfoSection}>
                             <View style={styles.row}>
                        <Text style={[styles.Textstyles3,{
                            }]}>解除合同</Text>
                            <Text style={[styles.Textstyles4,{
                            }]}>解除合同解除合同解除合同</Text>
                            </View>
                            </View>
                    </View>
                    </View>
            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>Successfully arrived(12)</Text>
                             <View style={styles.userInfoSection}>
                             <View style={styles.row}>
                        <Text style={[styles.Textstyles3,{
                            }]}>到账成功</Text>
                            <Text style={[styles.Textstyles4,{
                            }]}>打款成功打款成功打款成功</Text>
                            </View>
                            </View>
                    </View>
                    </View>

            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>Not called</Text>
                        <Text style={{color:"#ff4500",fontSize: 12,textAlign: "center",padding:5}}>=========================</Text>
                    </View>
                    </View> 

            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>Audit failed:status (-1)</Text>
                             <View style={styles.userInfoSection}>
                             <View style={styles.row}>
                        <Text style={[styles.Textstyles3,{
                            }]}>暂时不用</Text>
                            <Text style={[styles.Textstyles4,{
                            }]}>暂时不用</Text>
                            </View>
                            </View>
                    </View>
                    </View>

            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>Credit report exception:status (13)</Text>
                             <View style={styles.userInfoSection}>
                             <View style={styles.row}>
                        <Text style={[styles.Textstyles3,{
                            }]}>暂时不用</Text>
                            <Text style={[styles.Textstyles4,{
                            }]}>暂时不用</Text>
                            </View>
                            </View>
                    </View>
                    </View>

            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>Paid-to be confirmed:Status(14)</Text>
                             <View style={styles.userInfoSection}>
                             <View style={styles.row}>
                        <Text style={[styles.Textstyles3,{
                            }]}>暂时不用</Text>
                            <Text style={[styles.Textstyles4,{
                            }]}>暂时不用</Text>
                            </View>
                            </View>
                    </View>
                    </View>

            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>Order cancellation:status(22)</Text>
                             <View style={styles.userInfoSection}>
                             <View style={styles.row}>
                        <Text style={[styles.Textstyles3,{
                            }]}>暂时不用</Text>
                            <Text style={[styles.Textstyles4,{
                            }]}>暂时不用</Text>
                            </View>
                            </View>
                    </View>
                    </View>
            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles,{
                            }]}>SMS treasure SMS {"\n"}
                            settings</Text>
                            <Text style={{color:"#ff4500",fontSize: 12,textAlign: "center",padding:5,paddingTop:10}}>SMS platform information</Text>
                        <Text style={{color:"#777777",fontSize: 12,textAlign: "center",padding:5,paddingTop:10}}>registration</Text>
                    </View>
                    </View>
            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>username</Text>
                             <View style={styles.userInfoSection}>
                             <View style={styles.row}>
                        <Text style={[styles.Textstyles3,{
                            }]}>wcb8899</Text>
                            </View>
                            </View>
                    </View>
                    </View>
            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>password</Text>
                             <View style={styles.userInfoSection}>
                             <View style={styles.row}>
                        <Text style={[styles.Textstyles3,{
                            }]}>aa123456</Text>
                            </View>
                            </View>
                    </View>
                    </View>
            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>sign</Text>
                             <View style={styles.userInfoSection}>
                             <View style={styles.row}>
                        <Text style={[styles.Textstyles3,{
                            }]}>软体管家</Text>
                            </View>
                            </View>
                    </View>
                    </View>
            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles,{
                            }]}>Corporate messenger {"\n"}
                            settings</Text>
                            <Text style={{color:"#ff4500",fontSize: 12,textAlign: "center",padding:5,paddingTop:10}}>SMS platform information</Text>
                        <Text style={{color:"#777777",fontSize: 12,textAlign: "center",padding:5,paddingTop:10}}>registration</Text>
                    </View>
                    </View>
            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>username</Text>
                             <View style={styles.userInfoSection}>
                             <View style={styles.row}>
                        <Text style={[styles.Textstyles3,{
                            }]}>19943209605</Text>
                            </View>
                            </View>
                    </View>
                    </View>
            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>password</Text>
                             <View style={styles.userInfoSection}>
                             <View style={styles.row}>
                        <Text style={[styles.Textstyles3,{
                            }]}>123456</Text>
                            </View>
                            </View>
                    </View>
                    </View>
            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>Enterprise ID</Text>
                             <View style={styles.userInfoSection}>
                             <View style={styles.row}>
                        <Text style={[styles.Textstyles3,{
                            }]}>1096</Text>
                            </View>
                            </View>
                    </View>
                    </View>
            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>sign</Text>
                             <View style={styles.userInfoSection}>
                             <View style={styles.row}>
                        <Text style={[styles.Textstyles3,{
                            }]}>挖财宝</Text>
                            </View>
                            </View>
                    </View>
                    </View>
            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles,{
                            }]}>Site settings</Text>
                            <Text style={{color:"#ff4500",fontSize: 12,textAlign: "center",padding:5,paddingTop:5}}>Setting parameters</Text>
                    </View>
                    </View>
            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>user registration credit</Text>
                             <View style={styles.userInfoSection}>
                             <View style={styles.row}>
                        <Text style={[styles.Textstyles3,{
                            }]}>0</Text>
                            </View>
                            </View>
                    </View>
                    </View>
            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>Site name</Text>
                             <View style={styles.userInfoSection}>
                             <View style={styles.row}>
                        <Text style={[styles.Textstyles3,{
                            }]}>挖财宝</Text>
                            </View>
                            </View>
                    </View>
                    </View>
            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>Site title</Text>
                             <View style={styles.userInfoSection}>
                             <View style={styles.row}>
                        <Text style={[styles.Textstyles3,{
                            }]}>挖财宝</Text>
                            </View>
                            </View>
                    </View>
                    </View>
            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>Site address</Text>
                             <View style={styles.userInfoSection}>
                             <View style={styles.row}>
                        <Text style={[styles.Textstyles3,{
                            }]}>http://www.haikou345.com</Text>
                            </View>
                            </View>
                    </View>
                    </View>
            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>Site keywords</Text>
                             <View style={styles.userInfoSection}>
                             <View style={styles.row}>
                        <Text style={[styles.Textstyles3,{
                            }]}>挖财宝</Text>
                            </View>
                            </View>
                    </View>
                    </View>
            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>Get the maximum number of times on the day</Text>
                             <View style={styles.userInfoSection}>
                             <View style={styles.row}>
                        <Text style={[styles.Textstyles3,{
                            }]}>5000</Text>
                            </View>
                            </View>
                    </View>
                    </View>
            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>Site description</Text>
                             <View style={styles.userInfoSection}>
                             <View style={styles.row}>
                        <Text style={[styles.Textstyles3,{
                            }]}>挖财宝是一款基于移动端的信用借款APP</Text>
                            </View>
                            </View>
                    </View>
                    </View>
            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>Whether to close the website</Text>
                             <View style={styles.userInfoSection}>
                             <View style={styles.row}>
                             <RadioButton
                                value="first"
                                status={ checked === 'first' ? 'checked' : 'unchecked' }
                                onPress={() => setChecked('first')}
                            />
                            <Text style={{color:"#000000",fontSize: 12,textAlign: "center",paddingTop:10}}>No</Text>
                            <RadioButton
                                value="second"
                                status={ checked === 'second' ? 'checked' : 'unchecked' }
                                onPress={() => setChecked('second')}
                            />
                            <Text style={{color:"#000000",fontSize: 12,textAlign: "center",paddingTop:10}}>Yes</Text>
                            </View>
                            </View>
                    </View>
                    </View>
            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>Site shutdown prompt</Text>
                             <View style={styles.userInfoSection}>
                             <View style={styles.row}>
                        <Text style={[styles.Textstyles3,{
                            }]}> {"\n"}
                            {"\n"}
                            {"\n"}
                            {"\n"}</Text>
                            </View>
                            </View>
                    </View>
                    </View>
                    <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>ICP record certificate number</Text>
                             <View style={styles.userInfoSection}>
                             <View style={styles.row}>
                        <Text style={[styles.Textstyles3,{
                            }]}>津ICP备12003168号</Text>
                            </View>
                            </View>
                    </View>
                    </View>
            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}>Statistics/Customer Service Code Call</Text>
                             <View style={styles.userInfoSection}>
                             <View style={styles.row}>
                        <Text style={[styles.Textstyles3,{
                            }]}>testing</Text>
                            </View>
                            </View>
                    </View>
                    </View>
            <View style={styles.userInfoSection}>
                <View style={styles.row}>
                <Text style={[styles.Textstyles2,{
                            }]}></Text>
                             {/* <View style={styles.userInfoSection,{width:100,padding:5}}> */}
                             <View style={styles.row}>
                             <CustomButton text = "Submit" />
                            </View>
                            </View>
                    </View>
                    </View>

        {/* </View> */}
        </ScrollView>
    )
}

const styles = StyleSheet.create({
        Textstyles: {
            color:"#2e8b57",
            fontSize: 10,
            textAlign: 'center', 
            backgroundColor: "#eeeeee",
            fontWeight: 'bold',
            width:120,
            padding:5,
        },
        Textstyles2: {
            color:"#2e8b57",
            fontSize: 10,
            textAlign: 'right', 
            backgroundColor: "#ffffff",
            borderWidth:0.5,
            borderColor:"#e0e0e0",
            fontWeight: 'bold',
            width:120,
            padding:5,
        },
        Textstyles3: {
            color:"#808080",
            fontSize: 12,
            textAlign: 'left', 
            backgroundColor: "#ffffff",
            borderWidth:1,
            borderColor:"#e0e0e0",
            width:100,
            margin:3,
            paddingLeft:3,
        },
        Textstyles4: {
            color:"#808080",
            fontSize: 12,
            textAlign: 'left', 
            backgroundColor: "#ffffff",
            borderWidth:1,
            borderColor:"#e0e0e0",
            width:160,
            margin:3,
            paddingLeft:3,
        },
        row: {
            flexDirection: 'row',
        },
        title: {
            fontSize: 18
        },
        userInfoSection: {
            backgroundColor: "#ffffff",
            borderBottomColor: '#eeeeee',
            borderBottomWidth: 1,
          },
        body: {
            flex: 1,
            backgroundColor: 'white',
            marginLeft:10,
            marginRight:10,
        },
});
