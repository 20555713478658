import React, { useState } from 'react';
import { Alert, Dimensions, Image, Pressable, StyleSheet, Text, TextInput, TouchableOpacity, 
    useWindowDimensions, View,Button } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import * as ImagePicker from 'expo-image-picker';
import * as DocumentPicker from 'expo-document-picker';
import * as FileSystem from 'expo-file-system';
import {
    Appbar , Provider as PaperProvider,
} from 'react-native-paper';
import * as Constant from '../../utils/constant';
import { SafeAreaView } from 'react-native-safe-area-context';
import { globalStyles } from '../../../style/globalstyles';
import InternetConnectionAlert from "react-native-internet-connection-alert";
import i18n from 'i18n-js';
import { zh, en } from '../../utils/supportedLanguages';

const windowHeight = Dimensions.get('window').height
// const dimensionHeight = useWindowDimensions()

export default function Additional({ navigation }) {

    const dispatch = useDispatch()
    i18n.fallbacks = true;
    i18n.translations = { en, zh };
    const onHomeClicked = () => {
        navigation.navigate('ProfileScreen');
    }

     const pickDocument = async () => {
          let result = await DocumentPicker.getDocumentAsync({});
          console.log(result.uri);
          const options = { encoding: FileSystem.EncodingType.Base64 };
          const data = await FileSystem.readAsStringAsync(result.uri, options);
        
          //console.log(data);
        };
 
    const onUpdateClicked = () =>{
    navigation.navigate('ProfileScreen');
        Alert.alert(i18n.t('completeupdate'))
    };

    return (
        <InternetConnectionAlert
        onChange={(connectionState) => {
            console.log("Connection State: ", connectionState);
        }}
        >
    <SafeAreaView style={styles.container}>
        <View style={globalStyles.body}>
        <Appbar.Header style={globalStyles.appHeader}>
            <Appbar.BackAction onPress={onHomeClicked} />
            <Appbar.Content
                    title={(i18n.t('document'))}
                    />
            </Appbar.Header>
            <View style={styles.background}>
            <View style={styles.button}>
                <TouchableOpacity>
                <Button
                    title={(i18n.t('uploaddocument'))}
                    color="black"
                    onPress={pickDocument}
                />
                </TouchableOpacity>
            </View>
            </View>
                <TouchableOpacity style={globalStyles.outlineBtn} onPress={onUpdateClicked}>
                    <Text style={globalStyles.outlineText}> {(i18n.t('update'))} </Text>
                </TouchableOpacity>
            </View>
            </SafeAreaView>
            </InternetConnectionAlert>
    )
}

const styles = StyleSheet.create({
    body: {
        flex: 1,
        backgroundColor: '#F7F7F7'
    },
    titleContainer: {
        width: '100%',
        height: 150,
        position: 'relative',
        backgroundColor: '#FEE16E',
        alignItems: 'center',
        fontWeight: 'bold',
        height: 50,
        backgroundColor: '#6200ee',
        alignItems: 'center',
        fontWeight: 'bold',
        bottom: 240,
    },
    title: {
        fontSize: 20,
        color: '#FFFFFF',
        marginTop: 35
    },
    loginContainer: {
        flex: 1,
        width: '95%',
        backgroundColor: '#FFFFFF',
        position: 'absolute',
        alignSelf: 'center',
        justifyContent: 'center',
        top: 100,
    },
    inputContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 10,
        marginVertical: 5
    },
    input: {
        flex: 1,
        height: 50,
        borderBottomWidth: 0.5,
        paddingStart: 10
    },
    uploadIcContainer: {
        marginTop: 10,
        height: 200,
        borderRadius: 10,
        borderColor: '#000000',
        borderWidth: 0.5,
        backgroundColor: '#FEE16E',
        justifyContent: 'center',
        alignItems: 'center'
    },
    imageIC: {
        width: '100%',
        height: undefined,
        maxHeight: 200,
        aspectRatio: 1,
        resizeMode: 'contain'
    },
    registerBtn: {
        marginTop: 5,
        alignSelf: 'flex-end',
        marginEnd: 5
    },
    registerText: {
        fontSize: 13,
        color: '#98A5FD',
        fontWeight: 'bold'
    },
    loginBtn: {
        width: '95%',
        borderRadius: 20,
        backgroundColor: '#6200ee',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        marginTop: 20,
        paddingVertical: 10
    },
    loginText: {
        fontSize: 15,
        color: '#FFFFFF'
    },
    footer: {
        width: '100%',
        position: 'absolute',
        backgroundColor: '#F7F7F7',
        bottom: -50,
        alignItems: 'center'
    },
    footerText: {
        fontSize: 15,
        color: '#FEE16E'
    },
    text: {
        color: 'gray',
        marginVertical:10,
    },
    link: {
        color: '#FDB075'
    },
    container: {
        flex: 1,
    },
    checkboxContainer: {
        flexDirection: "row",
        marginBottom: 5,
    },
    
    checkbox: {
        alignSelf: "center",
    },
    background: {
        marginTop:100,
        borderWidth:1,
        margin:10,
        backgroundColor:
          "radial-gradient(ellipse at left bottom,    rgb(163, 237, 255) 0%,    rgba(57, 232, 255, 0.9) 59%,    rgba(48, 223, 214, 0.9) 100% )",
      },
      file: {
        color: "black",
        textAlign:'center',

      },
      button: {
          justifyContent:'center',
          alignItems:'center',
          alignSelf: 'center',
        width:300,
        height:300
      },

})