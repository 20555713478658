import AsyncStorage from '@react-native-async-storage/async-storage';


export async function check_store(name){
    try{
        if(name =="id"){
            return await AsyncStorage.getItem(name);
        }else if(name == "name"){
            return await AsyncStorage.getItem(name);
        }
    }catch(e){
        return e;
    }
}

export  function total_withdraw(w,d) {
    return d-w;
}