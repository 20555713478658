import React, { useState, useRef, useEffect } from "react";
import {
  Alert,
  Dimensions,
  Image,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  Button,
  View,
} from "react-native";
import Ionicons from "@expo/vector-icons/Ionicons";
import FontAwesome from "@expo/vector-icons/FontAwesome";
import { ProgressBar } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import * as ImagePicker from "expo-image-picker";
import { Appbar, Provider as PaperProvider } from "react-native-paper";
import axios from "axios";
import * as Constant from "../../utils/constant";
import { useFocusEffect } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import ContentLoader from "react-native-easy-content-loader";
import { globalStyles } from "../../../style/globalstyles";
import InternetConnectionAlert from "react-native-internet-connection-alert";
import { SafeAreaView } from "react-native-safe-area-context";
import i18n from 'i18n-js';
import { zh, en } from '../../utils/supportedLanguages';
import AwesomeAlert from 'react-native-awesome-alerts';
import * as api from '../../utils/api';

const windowHeight = Dimensions.get("window").height;
// const dimensionHeight = useWindowDimensions()

export default function Payslip({ navigation }) {
  const dispatch = useDispatch();
  const img64 = "data:image/jpg;base64,";
  const [createUserModel, setCreateUserModel] = useState({
    name: "",
    phoneNumber: "",
    identificationNumber: "",
    picFront: "",
    picBack:"",
    picFace: ""
  });
  const [loading, setLoading] = useState(false);
  const [slip, setSlip] = useState("");
  const [deposite,setDeposite] = useState("");
  const [remark,setRemark] = useState("");
  i18n.fallbacks = true;
  i18n.translations = { en, zh };
  const [language,setLanguage] = useState("");
  const onHomeClicked = () => {
    navigation.navigate("Wallet");
  };
  const [msgalert1,setMsgAlert1] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const openImagePickerAsync = async (pic) => {
    let permissionResult =
      await ImagePicker.requestMediaLibraryPermissionsAsync();

    if (permissionResult.granted === false) {
      Alert.alert(i18n.t('permissiongallery'));
      return;
    }
    let pickerResult = await ImagePicker.launchImageLibraryAsync({
      base64: true,
      quality: 1,
    });
    if (pickerResult.cancelled === true) {
      return;
    }
    switch (pic) {
      case "bill":
        console.log("okay front");
        setSlip(pickerResult.base64);
        break;
      default:
        console.log("errro");
    }
  };

  const renderBillView = () => {

    if (slip === "") {
        return <Text style={[globalStyles.outlineText,{textAlign:"center"}]}>{(slip === "")? i18n.t('uploadpayslip'): i18n.t('uploadedpayslip')}</Text>
    } else {
       // console.log(selectedImage.localUri)
        return <Image source={{ uri: img64+slip }} style={styles.imageIC}></Image>
    }
}

  const onUpdateClicked = async () => {
    if(slip === ""){
      setMsgAlert1(true);
      setAlertMessage(i18n.t('paysliprequired'));
      // Alert.alert(i18n.t('paysliprequired'));
      return;
    }else if (deposite === "") {
      setMsgAlert1(true);
      setAlertMessage(i18n.t('depositerequired'));
      // Alert.alert(i18n.t('depositerequired'));
      return;
    }else {
        setLoading(true);
      try {
        var ids = await AsyncStorage.getItem("id");
        var res = await api.p_depo(deposite,remark,slip);
        if(res == 200){
          setLoading(false);
          setMsgAlert1(true);
          setAlertMessage(i18n.t('complete'));
          navigation.navigate("Wallet")
        }else{
          console.log(res);
        }
      } catch (err) {
       console.log(err);    
      }
    }
    
  };
  useFocusEffect(
    React.useCallback(() => {
        //setLoading(true);
      console.log("payslip is focused");
      //fetchLoanDataFromApi();
    }, [])
  );

  return (
    <InternetConnectionAlert
    onChange={(connectionState) => {
        console.log("Connection State: ", connectionState);
    }}
    >
      <SafeAreaView style={globalStyles.container}>
    <View style={globalStyles.body}>
      <Appbar.Header style={globalStyles.appHeader}>
        <Appbar.BackAction onPress={onHomeClicked} />
        <Appbar.Content title={i18n.t('Upload_Slip')} />
      </Appbar.Header>
      <ContentLoader active loading={loading}>
      <View style={globalStyles.bodycontainer}>
        <View style={styles.inputContainer}>
          <Ionicons name="cash-sharp" size={15} color={"#A0A0A0"} />
          <TextInput
            style={styles.input}
            keyboardType="numeric"
            placeholder={i18n.t('Depositamount')}
            onChangeText={(text) => {
              setDeposite(text);
            }}
          />
        </View>

        <View style={styles.inputContainer}>
          <FontAwesome name="edit" size={15} color={"#A0A0A0"} />
          <TextInput
            style={styles.input}
            placeholder={i18n.t('remark')}
            multiline={true}
            numberOfLines={4}
            onChangeText={(text) => {
              setRemark(text);
            }}
          />
        </View>

        <View style={[styles.inputContainer,{justifyContent:"center"}]}>
                <TouchableOpacity style={styles.uploadIcContainer} onPress={()=>{openImagePickerAsync("bill")}}>
                    {renderBillView()}
                </TouchableOpacity>
                </View>
        <TouchableOpacity style={styles.btnLoan} onPress={onUpdateClicked}>
          <Text style={styles.textBtn}> {i18n.t('submit')} </Text>
        </TouchableOpacity>
      </View>
      </ContentLoader>
    </View>
    <AwesomeAlert
          show={msgalert1}
          showProgress={false}
          message={alertMessage}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}

          showConfirmButton={true}
          confirmText={i18n.t('close')}
          confirmButtonColor="#DD6B55"

          onConfirmPressed={() => {
           setMsgAlert1(false)
          }}
        />
    </SafeAreaView>
    </InternetConnectionAlert>
  );
}

const styles = StyleSheet.create({
  body: {
    flex: 1,
    backgroundColor: "#F7F7F7",
    marginTop: 20,
  },
  titleContainer: {
    width: "100%",
    height: 150,
    position: "relative",
    backgroundColor: "#FEE16E",
    alignItems: "center",
    fontWeight: "bold",
    height: 50,
    backgroundColor: "#6200ee",
    alignItems: "center",
    fontWeight: "bold",
    bottom: 240,
  },
  title: {
    fontSize: 20,
    color: "#FFFFFF",
    marginTop: 35,
  },
  userInfoSection: {
    paddingHorizontal: 70,
    marginBottom: 0,
    backgroundColor: "#ffffff",
    borderBottomColor: "#eeeeee",
    borderBottomWidth: 1,
  },
  row: {
    flexDirection: "row",
    marginBottom: 7,
  },
  loginContainer: {
    flex: 1,
    width: "95%",
    backgroundColor: "#FFFFFF",
    position: "absolute",
    alignSelf: "center",
    justifyContent: "center",
    top: 100,
  },
  inputContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 10,
    marginVertical: 5,
    width: "100%",
  },
  input: {
    flex: 1,
    height: 50,
    borderBottomWidth: 0.5,
    paddingStart: 10,
  },
  uploadIcContainer: {
    borderWidth:1,
    borderColor:"#ff0033",
    borderRadius:10,
    width:200,
    height:200,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    margin:20,
    padding:50
  },
  imageIC: {
    width: "200%",
    height: undefined,
    maxHeight: 200,
    aspectRatio: 1,
    resizeMode: "contain",
  },
  registerBtn: {
    marginTop: 5,
    alignSelf: "flex-end",
    marginEnd: 5,
  },
  registerText: {
    fontSize: 13,
    color: "#98A5FD",
    fontWeight: "bold",
  },
  loginBtn: {
    width: "95%",
    borderRadius: 20,
    borderWidth: 1,
    borderColor: "#ff0033",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    marginTop: 20,
    paddingVertical: 10,
  },
  loginText: {
    fontSize: 15,
    color: "#ff0033",
  },
  updateText: {
    fontSize: 15,
    color: "#ffffff",
  },
  footer: {
    width: "100%",
    position: "absolute",
    backgroundColor: "#F7F7F7",
    bottom: -50,
    alignItems: "center",
  },
  footerText: {
    fontSize: 15,
    color: "#FEE16E",
  },
  text: {
    color: "gray",
    marginVertical: 10,
  },
  link: {
    color: "#FDB075",
  },
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  checkboxContainer: {
    flexDirection: "row",
    marginBottom: 5,
  },

  checkbox: {
    alignSelf: "center",
  },
  chooseBtn: {
    borderWidth: 1,
    padding: 3,
    borderColor: "#ff0033",
    backgroundColor: "#ff0033",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    alignSelf: "flex-start",
    borderRadius: 10,
    justifyContent:"center"
  },
  updateBtn: {
    borderWidth: 1,
    padding: 3,
    borderColor: "#ff0033",
    backgroundColor: "#ff0033",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    alignSelf: "flex-end",
    borderRadius: 5,
  },
  textBtn: {
    fontSize: 15,
    color: '#ffffff',
    fontWeight: 'bold',
    alignSelf:"center"
},
btnLoan: {
    width: '70%',
    borderRadius: 20,
    marginTop: 20,
    padding: 10,
    marginBottom:5,
    backgroundColor:'#ff0033',
    alignSelf: 'center'
},
});
