import React, { useState } from 'react';
import { Alert, Dimensions, Image, Pressable, StyleSheet, Text, TextInput, TouchableOpacity, 
    useWindowDimensions, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import {
    Appbar , Provider as PaperProvider,
} from 'react-native-paper';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import { useFocusEffect } from '@react-navigation/native';
import { SafeAreaView } from 'react-native-safe-area-context';
import * as Constant from '../../utils/constant';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { globalStyles } from '../../../style/globalstyles';
import ContentLoader from "react-native-easy-content-loader";
const windowHeight = Dimensions.get('window').height
// const dimensionHeight = useWindowDimensions()
import InternetConnectionAlert from "react-native-internet-connection-alert";
import i18n from 'i18n-js';
import { zh, en } from '../../utils/supportedLanguages';
import AwesomeAlert from 'react-native-awesome-alerts';
import SelectDropdown from 'react-native-select-dropdown';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import * as api from '../../utils/api';

export default function CashBank({ navigation }) {

    const dispatch = useDispatch()
    i18n.fallbacks = true;
    i18n.translations = { en, zh };
    const [msgalert1,setMsgAlert1] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [createUserModel, setCreateUserModel] = useState({
        BankName: '',
        BankAccount: '',
    })
    const[loading,setLoading] = useState(false);

    const onHomeClicked = () => {
        navigation.navigate('ProfileScreen');
    }
    const bank = ["Maybank", "CIMB Group Holdings", "Public Bank Berhad", 
                  "RHB Bank","Hong Leong Bank","AmBank","UOB Malaysia","Bank Rakyat","OCBC Bank Malaysia",
                  "HSBC Bank Malaysia","Bank Islam Malaysia","Affin Bank","Alliance Bank Malaysia Berhad",
                  "Standard Chartered Bank Malaysia","MBSB Bank Berhad","Citibank Malaysia","Bank Simpanan Nasional (BSN)",
                  "Bank Muamalat Malaysia Berhad","Agrobank","Al-Rajhi Malaysia","Co-op Bank Pertama"];

    const onUpdateClicked = async() => {
        console.log("bank",createUserModel.BankName)
        if (createUserModel.BankName === ''||createUserModel.BankName===null) {
            setMsgAlert1(true);
            setAlertMessage(i18n.t('bankrequired'));
        }else if (createUserModel.BankAccount === ''||createUserModel.BankAccount===null) {
            setMsgAlert1(true);
            setAlertMessage(i18n.t('bankaccountrequired'));
        }else{
            var ids = await AsyncStorage.getItem('id');
            setLoading(true);
            try{
                console.log(createUserModel.MonthlyIncome)
                var res  = await api.up_user_bank(createUserModel.BankName,createUserModel.BankAccount)
                console.log("🚀 ~ file: CashBankScreen.js ~ line 62 ~ onUpdateClicked ~ res", res)
                if(res == 200){
                    setMsgAlert1(true);
                    setAlertMessage(i18n.t('completeupdate'));
                    fetchLoanDataFromApi();
                }
                setLoading(false);
            }catch(e){
                console.log(e);
            }
        }
    };

    const fetchLoanDataFromApi = async () =>{
        try {
            console.log("okay masuk")
            var ids = await AsyncStorage.getItem('id');
            const response = await api.userdetail_bank();
            console.log(response.data);
            if(!response.userCompanyname){
                Alert.alert(i18n.t('updatedetail'));
                navigation.push('DataScreen')
            }
            setCreateUserModel(prevUser => {
                return({
                    ...prevUser,
                    BankName: response.BankName,
                    BankAccount: response.BankAccount
                })
            })
            setLoading(false);
        } catch (error) {
            console.log(error)
        }
        
    }
    const getData = async () => {
        try {
        const value = await AsyncStorage.getItem('id')
        if(value !== null) {
           setID(value);
        }
        } catch(e) {
        // error reading value
        }
    }
    useFocusEffect(
        React.useCallback(() => {
            setLoading(true);
            console.log('bankscreen is focused');
            fetchLoanDataFromApi();
            getData();
        },[])
    )
    return (
        <InternetConnectionAlert
        onChange={(connectionState) => {
            console.log("Connection State: ", connectionState);
        }}
        >
     <SafeAreaView style={styles.container}>
        <View style={globalStyles.body}>
        <Appbar.Header style={globalStyles.appHeader}>
            <Appbar.BackAction onPress={onHomeClicked} />
            <Appbar.Content
                    title={(i18n.t('bankaccountdetail'))}
                    />
            </Appbar.Header>
            <View style={globalStyles.bodycontainer}>
            <ContentLoader active  loading={loading}>
                <View style={styles.inputContainer}>
                <Icon name='bank' size={15} color={'#A0A0A0'} />
                    {/* <TextInput
                   
                        style={styles.input}
                        value={createUserModel.BankName}
                        placeholder={(i18n.t('enterbank'))}
                        onChangeText={(text) => {
                            setCreateUserModel((prevState) => {
                                return { ...prevState, BankName: text }
                            })
                        }} /> */}
                        <SelectDropdown
                            data={bank.sort()}
                            defaultButtonText={'Select Bank'}
                            defaultValue={createUserModel.BankName}
                            onSelect={(selectedItem, index) => {
                                console.log(selectedItem, index)
                                setCreateUserModel((prevState) => {
                                    return { ...prevState, BankName: selectedItem }
                                })
                            }}
                            buttonStyle={styles.dropdown1BtnStyle}
                            buttonTextStyle={styles.dropdown1BtnTxtStyle}
                            buttonTextAfterSelection={(selectedItem, index) => {
                                // text represented after item is selected
                                // if data array is an array of objects then return selectedItem.property to render after item is selected
                                return selectedItem
                            }}
                            rowTextForSelection={(item, index) => {
                                // text represented for each item in dropdown
                                // if data array is an array of objects then return item.property to represent item in dropdown
                                return item
                            }}
                            dropdownIconPosition={'right'}
                            dropdownStyle={styles.dropdown1DropdownStyle}
                            rowStyle={styles.dropdown1RowStyle}
                            rowTextStyle={styles.dropdown1RowTxtStyle}
                            selectedRowStyle={styles.dropdown1SelectedRowStyle}
                            search
                            searchInputStyle={styles.dropdown1searchInputStyleStyle}
                            searchPlaceHolder={'Search here'}
                            searchPlaceHolderColor={'darkgrey'}
                            renderSearchInputLeftIcon={() => {
                              return <FontAwesome name={'search'} color={'#444'} size={18} />;
                            }}
                        />
                </View>

                <View style={styles.inputContainer}>
                <Icon name='credit-card-check-outline' size={15} color={'#A0A0A0'} />
                    <TextInput
                    
                        style={styles.input}
                        placeholder={(i18n.t('enterbankaccount'))}
                        value={createUserModel.BankAccount}
                        keyboardType='numeric'
                        onChangeText={(text) => {
                            setCreateUserModel((prevState) => {
                                return { ...prevState, BankAccount: text }
                            })
                        }} />
                </View>

                <TouchableOpacity style={globalStyles.outlineBtn} onPress={onUpdateClicked} >
                    <Text style={globalStyles.outlineText}> {(i18n.t('update'))} </Text>
                    </TouchableOpacity>
                </ContentLoader>
            </View>
            
        </View>
        <AwesomeAlert
          show={msgalert1}
          showProgress={false}
          message={alertMessage}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}

          showConfirmButton={true}
          confirmText={i18n.t('close')}
          confirmButtonColor="#DD6B55"

          onConfirmPressed={() => {
           setMsgAlert1(false)
          }}
        />
        </SafeAreaView>
     </InternetConnectionAlert>   
    )
}

const styles = StyleSheet.create({
    body: {
        flex: 1,
        backgroundColor: '#F7F7F7',
        marginTop:20
    },
    titleContainer: {
        width: '100%',
        height: 150,
        position: 'relative',
        backgroundColor: '#FEE16E',
        alignItems: 'center',
        fontWeight: 'bold',
        height: 50,
        backgroundColor: '#6200ee',
        alignItems: 'center',
        fontWeight: 'bold',
        bottom: 240,
    },
    title: {
        fontSize: 20,
        color: '#FFFFFF',
        marginTop: 35
    },
    loginContainer: {
        flex: 1,
        width: '95%',
        backgroundColor: '#FFFFFF',
        position: 'absolute',
        alignSelf: 'center',
        justifyContent: 'center',
        top: 60,
    },
    inputContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 10,
        marginVertical: 5
    },
    input: {
        flex: 1,
        height: 50,
        borderBottomWidth: 1,
        paddingStart: 10
    },
    uploadIcContainer: {
        marginTop: 10,
        height: 200,
        borderRadius: 10,
        borderColor: '#000000',
        borderWidth: 0.5,
        backgroundColor: '#FEE16E',
        justifyContent: 'center',
        alignItems: 'center'
    },
    imageIC: {
        width: '100%',
        height: undefined,
        maxHeight: 200,
        aspectRatio: 1,
        resizeMode: 'contain'
    },
    registerBtn: {
        marginTop: 5,
        alignSelf: 'flex-end',
        marginEnd: 5
    },
    registerText: {
        fontSize: 13,
        color: '#98A5FD',
        fontWeight: 'bold'
    },
    loginBtn: {
        width: '95%',
        borderRadius: 20,
        backgroundColor: '#6200ee',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        marginTop: 20,
        paddingVertical: 10
    },
    loginText: {
        fontSize: 15,
        color: '#FFFFFF'
    },
    footer: {
        width: '100%',
        position: 'absolute',
        backgroundColor: '#F7F7F7',
        bottom: -50,
        alignItems: 'center'
    },
    footerText: {
        fontSize: 15,
        color: '#FEE16E'
    },
    text: {
        color: 'gray',
        marginVertical:10,
    },
    link: {
        color: '#FDB075'
    },
    container: {
        flex: 1,
    },
    checkboxContainer: {
        flexDirection: "row",
        marginBottom: 5,
    },
    
    checkbox: {
        alignSelf: "center",
    },
    dropdown1BtnStyle: {
        width: '80%',
        height: 40,
        backgroundColor: '#FFF',
        borderRadius: 8,
        borderBottomWidth:1,
        flex:1
      },
      dropdown1BtnTxtStyle: {color: '#444', textAlign: 'left',flex:1},
      dropdown1DropdownStyle: {backgroundColor: '#EFEFEF',flex:1},
      dropdown1RowStyle: {backgroundColor: '#EFEFEF', borderBottomColor: '#C5C5C5',flex:1},
      dropdown1RowTxtStyle: {color: '#444', textAlign: 'left',flex:1},
      dropdown1SelectedRowStyle: {backgroundColor: 'rgba(0,0,0,0.1)',flex:1},
      dropdown1searchInputStyleStyle: {
        backgroundColor: '#EFEFEF',
        borderRadius: 8,
        borderBottomWidth: 1,
        borderBottomColor: '#444',
        flex:1
      },
})