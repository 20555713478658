import { CREATE_NEW_USER, SET_IS_LOGGED_IN } from "./actions";

const initialState = {
    createUserResult: {
        message:'',
        data:{}
    },
    isLoggedIn: false
}

function reducer(state=initialState, action){
    switch(action.type){
        case CREATE_NEW_USER: return {...state, createUserResult:{
            message: action.payload.message,
            data: action.payload.data
        }};
        case SET_IS_LOGGED_IN: return {...state, isLoggedIn: action.payload}
        default: return state
    }
}

export default reducer