import React, { useEffect, useState } from "react";
import { Pressable, StyleSheet, Text, TouchableOpacity, View ,ScrollView,Alert, FlatList } from "react-native";
import { Appbar, Provider as PaperProvider } from "react-native-paper";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useFocusEffect } from '@react-navigation/native';
import axios, { Axios } from 'axios';
import * as Constant from '../utils/constant';
import {globalStyles} from '../../style/globalstyles';
import ContentLoader from "react-native-easy-content-loader";
import InternetConnectionAlert from "react-native-internet-connection-alert";
import { SafeAreaView } from "react-native-safe-area-context";
import AwesomeAlert from 'react-native-awesome-alerts';
import i18n from 'i18n-js';
// import ProgressCircle from 'react-native-progress-circle'
import CircularProgress from 'react-native-circular-progress-indicator';
import { zh, en } from '../utils/supportedLanguages';
import *  as api  from "../utils/api.js";
export default function Repayment({navigation}) {
 
    const [loading, setLoading] = useState(false);
    const[dataSource,setDataSource] = useState()
    const [msgalert1,setMsgAlert1] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [datePay, setDatePay] = useState("");
    const [term, setTerm] = useState("");
    const [loan_amount, setLoanAmount]= useState("");
    const [pay_month,setPay_month] = useState("");
    const [id_loan,setId_loan] = useState("");
    const [order_id,setOrder_id] = useState("");
    const [term_length, setTerm_lenght] = useState("");
    const [percen_loan,setPerce_loan] = useState("");
    const [amount_left,setAmount_left] = useState("");
    const [userpay,setUserPay] = useState();
    const [satu,setSatu] = useState([
      
    ]);
    const [btnAble,setBtnAble]= useState(false);
    const[loan_data,setLoan_data] = useState();

    const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    i18n.fallbacks = true;
    i18n.translations = { en, zh };
    const getData = async () => {
      //  setLoading(true);
        try {
        const value = await AsyncStorage.getItem('id');
        const name = await AsyncStorage.getItem('name');
        let lang = await AsyncStorage.getItem('language');
        console.log(lang);
        if(lang == null){
            i18n.locale = "en";
        }else{
            i18n.locale = lang;
        }
        
        setLogin(true)
        if(value !== null) {
            console.log(value);
            if(name !== null){
                console.log(name)
                SetUsername(name)
            }
        }else{ 
            setLogin(false)
        }
        } catch(e) {
        // error reading value
        }
    }

    const onHomeClicked = () => {
        navigation.navigate("Root");
      };
    const onPaynowClicked = (id, orderid,monthpay) => {
          console.log("click pay now",id,orderid);
          navigation.navigate('Paynow',{
            orderid: orderid,
            id: id,
            monthpay: monthpay
          })
        
    }
    const list_pay_month = (i,amount,term,date,dp,m_pay)=>{
        
        let today = new Date();
        let today_month=today.getMonth();
        let today_year = today.getFullYear();

        let l =[];
        let d=[];
        let  q = date - 1;
        for(let r=1;r<=term;r++){
            let a = 1;
            // if(date<12){
                date = parseInt(date)+a;
                d.push(date)
            // } 
        }
        let x= 0;
        let f = 0;
        for(x;x<term;x++){
            let dates;
            
            if(d[x]<=12){
                dates = month[d[x]-1]+" "+dp+" "+today_year; 
                // console.log("🚀 ~ file: repayment.js ~ line 104 ~ constlist_pay_month=async ~ d[x]-1", d[x]-1)
            }else{
                
                dates = month[f]+" "+dp+" "+(today_year+1); 
                f++;
            }
                
            let u ={
                id:x+1,
                dates: dates,
                amount: m_pay,
                percen:"",
                status:""
            }
        l.push(u); 
        
        }   
        if(i.length !=0){
            for(let u = 0;u<i.length;u++){
            
                l[u].amount = i[u].Amount;
                l[u].status = i[u].Status;
                l[u].percen = 100;
    
            }
        }
        setLoan_data(l)        
        console.log("🚀 ~ file: repayment.js ~ line 132 ~ Repayment ~ l", l)
    }
  const list_payment = async(id,amount,terms,d,dp,m_pay)=>{
    var res  = await api.listofuserpay(id);
    console.log("🚀 ~ file: repayment.js ~ line 136 ~ constlist_payment=async ~ res", res)
    let i = res;
    if(res.length != 0){
        let t = []
        res.map(x=>{
            t.push(x.Amount)
        });
        let total = t.reduce(
            (previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue)
          );
        let am= parseFloat(amount) - parseFloat(total);
        let percen = (i.length / terms)*100;
        setPerce_loan(percen)
        console.log("🚀 ~ file: repayment.js ~ line 150 ~ constlist_payment=async ~ percen", percen)
        setAmount_left(parseFloat(am).toFixed(2));
        setTerm_lenght(i.length);
        setUserPay(res.data);
        if(i.length == terms){
            setBtnAble(true);
        }
    }else{
        setPerce_loan(res.length);
        setAmount_left(amount);
        setTerm_lenght(res.length);
        // setUserPay(res.data);
    }
    list_pay_month(i,amount,terms,d,dp,m_pay);
    
     

  }
      const fetch_data_loan = async()=>{
        var ids = await AsyncStorage.getItem("id");
        var res = await api.listofloan();
        var res2 = await api.loan_limit();
        // console.log("🚀 ~ file: repayment.js ~ line 132 ~ constfetch_data_loan=async ~ res", res)
        setTerm(res.loanTerm);
        setLoanAmount(res.loanAmount);
        setPay_month(parseFloat(res.MonthlyPay).toFixed(2));
        setOrder_id(res.orderid);
        setId_loan(res.id);
        let dates = res.createdAt;
        let d = dates.slice(0,7);
        d = d.slice(-1)
        console.log("🚀 ~ file: repayment.js ~ line 121 ~ constfetch_data_loan=async ~ d", d)
        let i = res.userpay
        let today = new Date();
        let today_month=today.getMonth()+1;
        let today_year = today.getFullYear();
        console.log(today_month);
        setDatePay(month[today_month]+" "+res2.date_pay+" "+today_year)
        list_payment(res.id,res.loanAmount,res.loanTerm,d,res2.date_pay,parseFloat(res.MonthlyPay).toFixed(2))
        // setLoan_data(res.data)
        // console.log("🚀 ~ file: repayment.js ~ line 137 ~ constfetch_data_loan=async ~ setLoan_data", loan_data)
      }
    useFocusEffect(
        React.useCallback(() => {
            fetch_data_loan()
        //    fetchLoanDataFromApi()
            getData();
            //listpayment();
           
            console.log('wallet is focused')
        },[])
    )
    const [selectedId, setSelectedId] = useState(null);
    return (
        <InternetConnectionAlert
        onChange={(connectionState) => {
            console.log("Connection State: ", connectionState);
        }}
        >
        <SafeAreaView style={styles.container}>
        <View style={globalStyles.body}>
        <Appbar.Header style={globalStyles.appHeader}>
        <Appbar.BackAction onPress={onHomeClicked} />
        <Appbar.Content title={i18n.t('repayment')} />
      </Appbar.Header>
                <ContentLoader active loading={loading}>
               
                
             <View style={{height:"31%", flexDirection: 'column', margin: 10,backgroundColor: '#ffffff',borderWidth:0,borderColor:"#ff0033",borderRadius:5,shadowColor: "#000",
                shadowOffset: {width: 0,height: 10,},shadowOpacity: 0.44,shadowRadius: 10.32,elevation: 16}}>

             <View style={{ flexDirection:"row"}}>
                 {/* <ProgressCircle
                      percent={parseInt(percen_loan)}
                      radius={50}
                      borderWidth={12}
                      color="#ff0033"
                      shadowColor="#999"
                      bgColor="#fff"
                      outerCircleStyle={{margin:20}}
                  >
            <Text style={{ fontSize: 16 ,fontWeight:"bold", textAlign:"center",color:"#000"}}>{term_length+"/"+ term}</Text>
            <Text style={{ fontSize: 16 ,textAlign:"center",color:"#808080"}}>{'Paid'}</Text>
        </ProgressCircle> */}
         <CircularProgress
  value={percen_loan}
  radius={70}
  activeStrokeColor={'#ff0033'}
  title={term_length+"/"+ term}
          titleStyle={{ fontWeight: 'bold' }}
  

/>
        <View style={{flexDirection:"column"}}>
            <View style={{flexDirection:"column",width: "100%"}}>
                <Text style={{marginLeft:10,fontSize:12,marginTop:25,color:"#999",fontWeight:'bold'}} >{i18n.t('outstanding')}</Text>
                <Text style={{fontWeight: 'bold',marginTop:15,marginLeft:10,fontSize:24}} >RM {amount_left}</Text>
                <Text style={{fontWeight: 'bold',marginLeft:10,fontSize:12}} >{i18n.t('fromloan')} RM {loan_amount}</Text>
            </View>
            </View>
            </View>
            <View style={{flexDirection:"row",flex:1}}>

            <View style={{flexDirection:"column",flex:1}}>
                <Text style={{color:"#bdbdbd" ,textAlign:"center" ,fontSize:14,fontWeight: 'bold'}}>{i18n.t('Amount_Due')}</Text>
                <Text style={{color:"#000" ,marginTop:5,textAlign:"center" ,fontSize:18}}>RM {pay_month}</Text>
                </View>
                <View style={{borderLeftWidth:1,marginTop:10,marginBottom:20,borderLeftColor:"#bdbdbd"}}></View>
                <View style={{flexDirection:"column",flex:1}}>
                <Text style={{color:"#bdbdbd" ,textAlign:"center" ,fontSize:14,fontWeight: 'bold'}}>{i18n.t('duedate')}</Text>
                <Text style={{color:"#000" ,marginTop:5,textAlign:"center" ,fontSize:18}}>{datePay}</Text>
                </View>
                </View>
            
          </View>
          {/* <LinearGradient colors={['#4c669f', '#3b5998', '#192f6a']}  > */}
          <TouchableOpacity style={styles.btnLoan} onPress={()=>onPaynowClicked(id_loan,order_id,pay_month)} disabled={btnAble}>
                    <Text style={styles.textBtn}> {i18n.t('paynow')}</Text>
                </TouchableOpacity>

                <View style={{flexDirection:"row" ,width: "100%",marginTop:20}}>
                <Text style={{alignSelf:"center",padding:10,color:"#000",fontSize:14,fontWeight:"bold",width: "72%"}}> {i18n.t('upcomingpayment')} </Text>
                {/* <TouchableOpacity><Text style={{textAlign:"right",padding:10,color:"#ff0033",fontSize:14,fontWeight:"bold"}}> {i18n.t('viewhistory')} </Text></TouchableOpacity> */}
                </View>
               
                <FlatList
         data={ loan_data }
        renderItem={({ item }) => (           
          <View style={{flexDirection:"row",margin: 10,backgroundColor: '#ffffff',borderWidth:0,borderColor:"#ff0033",borderRadius:1,shadowColor: "#000",
          shadowOffset: {width: 0,height: 10,},shadowOpacity: 0.44,shadowRadius: 10.32,elevation: 16 }}>
              {/* <ProgressCircle
                percent={parseInt(item.percen)}
                radius={30}
                borderWidth={6}
                color="#ff0033"
                shadowColor="#bdbdbd"
                bgColor="#fff"
                outerCircleStyle={{margin:15}}
            >
      <Text style={{ fontSize: 16 ,fontWeight:"bold", textAlign:"center",color:"#000"}}>{item.id}</Text>
  </ProgressCircle> */}
   <CircularProgress
  value={item.percen}
  radius={30}
  activeStrokeColor={'#ff0033'}

/>
          <View style={{flexDirection:"column",width: "auto",marginTop:20}}>
          <Text style={[styles.GridViewInsideTextItemStyle,{width: "100%",fontSize:16}]} >{item.dates}</Text>
          <Text style={{width: "100%",fontWeight: 'bold',fontSize:12,color:"#bdbdbd",marginTop:10}} >{i18n.t('personal')} </Text>
      </View>
      <View style={{flexDirection:"column",width: "40%",marginTop:20}}>
          <View style={{flexDirection:"row"}}>
          <Text style={[{color:"#000" , width: "100%",textAlign:"right" ,paddingRight:15,fontSize:16}]}>RM {item.amount}</Text>
          </View>
          <Text style={[{ width: "100%",textAlign:"right" ,marginTop:10,paddingRight:15,fontSize:12}, (item.status == 'Verified')? styles.verify: styles.not_veri]}>{(item.status == "Verified")? "Verified": i18n.t('duenext')}</Text>
          </View>
          </View>
        )}
        //Setting the number of column
        
        keyExtractor={(item, index) => index}
      />
            
            </ContentLoader>
            <AwesomeAlert
          show={msgalert1}
          showProgress={false}
          message={alertMessage}
          closeOnTouchOutside={true}
          closeOnHardwareBackPress={false}

          showConfirmButton={true}
          confirmText={(i18n.t('close'))}
          confirmButtonColor="#DD6B55"

          onConfirmPressed={() => {
           setMsgAlert1(false)
          }}
        />
        </View>
        </SafeAreaView>
        </InternetConnectionAlert>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#F7F7F7'
    },
    firstView: {
        width: '100%',
        backgroundColor: '#FFFFFF',
        alignItems: 'center',
        padding: 10
    },
    linearGradient: {
      flex: 1,
      paddingLeft: 15,
      paddingRight: 15,
      borderRadius: 5
    },
    loanContainer: {
        width: '90%',
        borderRadius: 10,
        marginTop:10,
        borderColor:'#ff0033',
        borderWidth:1,
        paddingVertical: 10,
        alignSelf: 'center',
        alignItems:'center',
        marginBottom:10
    },
    textBalanceNum: {
        fontSize: 20,
        color: '#000000',
        borderBottomWidth:1,
        borderBottomColor:'#3b3b3b',
        marginBottom:5,
    },
    textBalance: {
        color: '#000000',
        marginTop: 5,
    },
    textRecentLoan: {
        color: '#FFFFFF'
    },
    totalContainer: {
        width: '100%',
        flexDirection: 'row',
        justifyContent:'center',
        marginTop: 20
    },
    totalItemContainer: {
        alignItems: 'center',
        width: '20%',
        marginHorizontal: 30,
    },
    btnWithdraw: {
        width: '40%',
        borderRadius: 20,
        padding: 5,
        marginTop: 5,
        borderWidth: 1,
        marginLeft:15,
        marginRight:15,
        borderColor:'#ff0033',
        alignItems: 'center'
    },
    textWithdraw: {
        fontSize: 15,
        color: '#ff0033',
        fontWeight: 'bold',
    },

    secondView: {
        width: '100%',
        backgroundColor: '#FFFFFF',
        marginTop: 10,
        padding: 10
    },
    textViewTitle: {
        fontWeight: 'bold',
        fontSize: 15,
    },
    textLoan: {
        color: '#BDBBC2',
        marginTop: 5,
        alignSelf: 'center'
    },
    bankCardContainer: {
        marginTop: 10,
        borderRadius: 10,
        height:150,
    },
    textCardNum: {
        margin: 15,
        marginBottom:5,
        marginTop:5,
        fontSize:24,
        color: '#FFFFFF'
    },
    textBtn: {
        fontSize: 14,
        color: '#ffffff',
        fontWeight: 'bold'
    },
    btnLoan: {
        width: 'auto',
        borderRadius: 10,
        padding: 15,
        marginLeft: 10,
        marginRight:10,
        marginTop:10,
        backgroundColor:'#ff0033',
        alignItems: 'center'
    },
    verify:{
        color:"#00ff00"
    },
    not_veri:{
        color: "#808080"
    },
})