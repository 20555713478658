import 'react-native-gesture-handler';
import { StatusBar } from 'expo-status-bar';
import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View ,AppState} from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import Login from './main/screens/login';
import Root from './main/screens/root';
import Register from './main/screens/register';
import { Provider } from 'react-redux';
import { Store } from './main/redux/store';
import LoanPreview from './main/screens/loanPreview';
import LoanDetails from './main/screens/loanDetails';
import Support from './main/screens/support';

import Profile from './main/screens/profile';
import EditProfileScreen from './main/screens/editprofile';
import ForgotPassword from './main/screens/ForgotPasswordScreen';
import NewPasswordScreen from './main/screens/NewPasswordScreen';
import IdentityScreen from './main/screens/Screen/IdentityScreen';
import CashBankScreen from './main/screens/Screen/CashBankScreen';
import DataScreen from './main/screens/Screen/DataScreen';
import MobileAuthenScreen from './main/screens/Screen/MobileAuthenScreen';
import SignScreen from './main/screens/Screen/SignScreen';
import Additional from './main/screens/Screen/Additional';
import LoadSetting from './main/screens/Screen/LoanSettingScreen';
import Payslip from './main/screens/Screen/PayslipScreen';
import Withdrawal from './main/screens/Screen/WithdrawalScreen';
import myWithdrawal from './main/screens/withdrawal';
import Repayment from './main/screens/repayment';
import OneSignal from 'react-native-onesignal';
import Paynow from './main/screens/Screen/PaynowScreen';
import Transaction from './main/screens/Screen/Transaction';

const Stack = createNativeStackNavigator()




export default function App() {
  const [ ids, setId] = useState("")
  OneSignal.setAppId("97e28e9f-8811-4d33-a88f-14f5b956b262");
  OneSignal.setLogLevel(6, 0);

 useEffect(async()=>{
 
 
 },[]);

/**
 * for farmer app 
 
 const config = {
  screens: {
   
    Register: {
      path: 'user'
    },
  },
};

const linking = {
  config,
};*/


  return (
    <Provider store={Store}>
      {/* for farmer app <NavigationContainer linking={linking}> */}
      <NavigationContainer >
      <Stack.Navigator>
      <Stack.Screen name='Root' component={Root} options={{headerShown:false}}/>
        <Stack.Screen name='Login' component={Login} options={{headerShown:false}}/>
        <Stack.Screen name='Register' component={Register} options={{headerShown:false}}/>
        <Stack.Screen name='LoanPreview' component={LoanPreview} options={{headerShown:false}}/>
        <Stack.Screen name='ProfileScreen' component={Profile} options={{headerShown:false}}/>
        <Stack.Screen name='EditProfileScreen' component={EditProfileScreen} options={{headerShown:false}}/>
        <Stack.Screen name='ForgotPasswordScreen' component={ForgotPassword} options={{headerShown:false}}/>
        <Stack.Screen name='NewPasswordScreen' component={NewPasswordScreen} options={{headerShown:false}}/>
        <Stack.Screen name='IdentityScreen' component={IdentityScreen} options={{headerShown:false}}/>
        <Stack.Screen name='DataScreen' component={DataScreen} options={{headerShown:false}}/>
        <Stack.Screen name='CashBankScreen' component={CashBankScreen} options={{headerShown:false}}/>  
        <Stack.Screen name='MobileAuthenScreen' component={MobileAuthenScreen} options={{headerShown:false}}/>       
        <Stack.Screen name='SignScreen' component={SignScreen} options={{headerShown:false}}/>
        <Stack.Screen name='Additional' component={Additional} options={{headerShown:false}}/>
        <Stack.Screen name='LoadSetting' component={LoadSetting} options={{headerShown:false}}/>
        <Stack.Screen name='loanDetails' component={LoanDetails} options={{headerShown:false}}/>
        <Stack.Screen name='Repayment' component={Repayment} options={{headerShown:false}}/>
        <Stack.Screen name='Support' component={Support} options={{headerShown:false}}/>
        <Stack.Screen name='Payslip' component={Payslip} options={{headerShown:false}}/>
        <Stack.Screen name='myWithdrawal' component={myWithdrawal} options={{headerShown:false}}/>
        <Stack.Screen name='Withdrawal' component={Withdrawal} options={{headerShown:false}}/>
        <Stack.Screen name='Paynow' component={Paynow} options={{headerShown:false}}/>
        <Stack.Screen name='Transaction' component={Transaction} options={{headerShown:false}}/>
    </Stack.Navigator>
    </NavigationContainer>
    </Provider>
  );
}