export const CREATE_NEW_USER = "CREATE_NEW_USER";
export const SET_IS_LOGGED_IN = "SET_IS_LOGGED_IN";

const API_URL = "http://192.168.1.131:3001/api/v1/user"

export const createNewUser = createUserModel => {
    try {
        return async dispatch => {
            console.log(createUserModel.name)
            const result = await fetch(API_URL + "/create", {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(
                    {
                        name: createUserModel.name,
                        phoneNumber: createUserModel.phoneNumber,
                        password:createUserModel.password,
                        identificationNumber: createUserModel.identificationNumber
                    }
                )
            })
            const json = await result.json()
            if (json) {
                console.log(json)
                dispatch({
                    type: 'CREATE_NEW_USER',
                    payload: json
                })
            } else {
                console.log("Unable to fetch")
            }
        }
    } catch (error) {
        console.log(error)
    }
}

export const setIsLoggedIn = isLogged => dispatch => {
    dispatch({
        type:SET_IS_LOGGED_IN,
        payload: isLogged
    })
}
