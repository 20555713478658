import React, { useState,useRef } from 'react';
import { Alert, Dimensions, Image, Pressable, StyleSheet, Text, TextInput, ScrollView, 
    useWindowDimensions, View } from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import FontAwesome from '@expo/vector-icons/FontAwesome';
import { useDispatch, useSelector } from 'react-redux';
import * as ImagePicker from 'expo-image-picker';
import {
    Appbar , Provider as PaperProvider,
} from 'react-native-paper';
import * as Constant from '../../utils/constant'
import InternetConnectionAlert from "react-native-internet-connection-alert";
import Signature from "react-native-signature-canvas";
const windowHeight = Dimensions.get('window').height
// const dimensionHeight = useWindowDimensions()
//import RNSketchCanvas  from '@terrylinla/react-native-sketch-canvas';
//import { SketchCanvas } from '@terrylinla/react-native-sketch-canvas';
export default function Sign({ navigation }) {

    const dispatch = useDispatch()
    const [createUserModel, setCreateUserModel] = useState({
        name: '',
        phoneNumber: '',
        password: '',
        identificationNumber: ''
    })
    const [selectedImage, setSelectedImage] = useState(null)

    const onHomeClicked = () => {
        navigation.navigate('ProfileScreen');
    }


   

    const renderICbackView = () => {

        if (selectedImage === null) {
            return <Text style={{ color: '#FFFFFF' }}>Upload your Back IC photo here</Text>
        } else {
            console.log(selectedImage.localUri)
            return <Image source={{ uri: selectedImage.localUri }} style={styles.imageIC}></Image>
        }
    }
    const [signature, setSign] = useState(null);

    const handleOK = (signature) => {
      //console.log(signature);
      setSign(signature);
    };
  
    const handleEmpty = () => {
      console.log("Empty");
    };
  
    const style = `.m-signature-pad--footer
      .button {
        background-color: red;
        color: #FFF;
      }`;
      const [scrollEnabled, setScrollEnabled] = useState(true);
    return (
        <InternetConnectionAlert
        onChange={(connectionState) => {
            console.log("Connection State: ", connectionState);
        }}
        >
        {/* <View style={styles.body}>
            <View >
                <Appbar.Header>
            <Appbar.BackAction onPress={onHomeClicked} />
            <Appbar.Content
                    title="Update Identity"
                    />
            </Appbar.Header> */}
            {/* </View>
            {/* <ScrollView scrollEnabled={scrollEnabled}>
            <View style={{ flex: 1,height: 300 }}>
                <View style={styles.preview}>
                {signature ? (
                <Image
                    resizeMode={"contain"}
                    style={{ width: 335, height: 114 }}
                    source={{ uri: signature }}
                />
                ) : null}
            </View>
            <Signature
                onOK={handleOK}
                onEmpty={handleEmpty}
                descriptionText="Sign"
                clearText="Clear"
                confirmText="Save"
                webStyle={style}
                onBegin={() => setScrollEnabled(false)}
                onEnd={() => setScrollEnabled(true)}
            />
            </View>
            </ScrollView> */}
            {/* <Image
                    
                    style={{ width: 335, height: 314 }}
                    source={{ uri: "file:///"+ signature }}
                />
            <RNSketchCanvas
            containerStyle={{ backgroundColor: 'transparent', flex: 1 }}
            canvasStyle={{ backgroundColor: 'transparent', flex: 1 }}
            defaultStrokeIndex={0}
            defaultStrokeWidth={5}
            // closeComponent={<View style={styles.functionButton}><Text style={{color: 'white'}}>Close</Text></View>}
            undoComponent={<View style={styles.functionButton}><Text style={{color: 'white'}}>Undo</Text></View>}
            clearComponent={<View style={styles.functionButton}><Text style={{color: 'white'}}>Clear</Text></View>} *
            // eraseComponent={<View style={styles.functionButton}><Text style={{color: 'white'}}>Eraser</Text></View>}
            // strokeComponent={color => (
            //   <View style={[{ backgroundColor: color }, styles.strokeColorButton]} />
            // )}
            // strokeSelectedComponent={(color, index, changed) => {
            //   return (
            //     <View style={[{ backgroundColor: color, borderWidth: 2 }, styles.strokeColorButton]} />
            //   )
            // }}
            strokeWidthComponent={(w) => {
              return (<View style={styles.strokeWidthButton}>
                <View  style={{
                  backgroundColor: 'white', marginHorizontal: 2.5,
                  width: Math.sqrt(w / 3) * 10, height: Math.sqrt(w / 3) * 10, borderRadius: Math.sqrt(w / 3) * 10 / 2
                }} />
              </View>
            )}}
            saveComponent={<View style={styles.functionButton}><Text style={{color: 'white'}}>Save</Text></View>}
            savePreference={() => {
              return {
                folder: 'RNSketchCanvas',
                filename: String(Math.ceil(Math.random() * 100000000)),
                transparent: false,
                imageType: 'png'
              }
            }}
            onSketchSaved={(success, path) => {
                Alert.alert(success ? 'Image saved!' : 'Failed to save image!', path)
                console.log("🚀 ~ file: SignScreen.js ~ line 141 ~ Sign ~ path", path)
                setSign(path)
              }}
              onPathsChange={(pathsCount) => {
                console.log('pathsCount', pathsCount)
              }}
          />

        </View> */}
        </InternetConnectionAlert>
    )
}

const styles = StyleSheet.create({
    body: {
        flex: 1,
        backgroundColor: '#F7F7F7'
    },
    titleContainer: {
        width: '100%',
        height: 150,
        position: 'relative',
        backgroundColor: '#FEE16E',
        alignItems: 'center',
        fontWeight: 'bold',
        height: 50,
        backgroundColor: '#6200ee',
        alignItems: 'center',
        fontWeight: 'bold',
        bottom: 240,
    },
    title: {
        fontSize: 20,
        color: '#FFFFFF',
        marginTop: 35
    },
    loginContainer: {
        flex: 1,
        width: '95%',
        backgroundColor: '#FFFFFF',
        position: 'absolute',
        alignSelf: 'center',
        justifyContent: 'center',
        top: 100,
    },
    inputContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 10,
        marginVertical: 5
    },
    input: {
        flex: 1,
        height: 50,
        borderBottomWidth: 0.5,
        paddingStart: 10
    },
    uploadIcContainer: {
        marginTop: 10,
        height: 200,
        borderRadius: 10,
        borderColor: '#000000',
        borderWidth: 0.5,
        backgroundColor: '#FEE16E',
        justifyContent: 'center',
        alignItems: 'center'
    },
    imageIC: {
        width: '100%',
        height: undefined,
        maxHeight: 200,
        aspectRatio: 1,
        resizeMode: 'contain'
    },
    registerBtn: {
        marginTop: 5,
        alignSelf: 'flex-end',
        marginEnd: 5
    },
    registerText: {
        fontSize: 13,
        color: '#98A5FD',
        fontWeight: 'bold'
    },
    loginBtn: {
        width: '95%',
        borderRadius: 20,
        backgroundColor: '#6200ee',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        marginTop: 20,
        paddingVertical: 10
    },
    loginText: {
        fontSize: 15,
        color: '#FFFFFF'
    },
    footer: {
        width: '100%',
        position: 'absolute',
        backgroundColor: '#F7F7F7',
        bottom: -50,
        alignItems: 'center'
    },
    footerText: {
        fontSize: 15,
        color: '#FEE16E'
    },
    text: {
        color: 'gray',
        marginVertical:10,
    },
    link: {
        color: '#FDB075'
    },
    container: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
    },
    checkboxContainer: {
        flexDirection: "row",
        marginBottom: 5,
    },
    
    checkbox: {
        alignSelf: "center",
    },
container: {
    flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: '#F5FCFF',
  },
  strokeColorButton: {
    marginHorizontal: 2.5, marginVertical: 8, width: 30, height: 30, borderRadius: 15,
  },
  strokeWidthButton: {
    marginHorizontal: 2.5, marginVertical: 8, width: 30, height: 30, borderRadius: 15,
    justifyContent: 'center', alignItems: 'center', backgroundColor: '#39579A'
  },
  functionButton: {
    marginHorizontal: 2.5, marginVertical: 8, height: 30, width: 60,
    backgroundColor: '#39579A', justifyContent: 'center', alignItems: 'center', borderRadius: 5,
  }
})