import { StyleSheet } from "react-native";

export const globalStyles = StyleSheet.create({
    body: {
        flex: 1,
        backgroundColor: '#F7F7F7',
    },
    appHeader:{
        backgroundColor:"#ff0033",
        marginTop:0
    },
    Header:{
        justifyContent: 'center',
        width: '100%',
        padding:5,
        backgroundColor:'#ff0033',
        flexDirection:'row'
    },
    Headtitle:{
        color:"#ffffff",
        fontSize:20,
        fontWeight:"bold"
    },
    titleContainer: {
        width: '100%',
        height: 150,
        position: 'relative',
        backgroundColor: '#FEE16E',
        alignItems: 'center',
        fontWeight: 'bold',
        height: 50,
        backgroundColor: '#6200ee',
        alignItems: 'center',
        fontWeight: 'bold',
        bottom: 240,
    },
    title: {
        fontSize: 20,
        color: '#FFFFFF',
        marginTop: 35
    },
    userInfoSection: {
        paddingHorizontal: 70,
        marginBottom: 0,
        backgroundColor: "#ffffff",
        borderBottomColor: '#eeeeee',
        borderBottomWidth: 1,
    },
    userContainer: {
        width: '100%',
        flexDirection: 'row',
        marginBottom:5
    },
    whiteText:{
        color: '#ffffff' ,
        textAlign:'center'
    },
    blackText:{
        color:"#000000",
        textAlign:'center'
    },
    bodycontainer:{
        flex: 1,
        width: '95%',
        backgroundColor: '#FFFFFF',
        position: 'absolute',
        alignSelf: 'center',
        justifyContent: 'center',
        top: 60,
    },
    inputContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 10,
        marginVertical: 5
    },
    row: {
        flexDirection: 'row',
    },
    loginContainer: {
        flex: 1,
        width: '95%',
        backgroundColor: '#FFFFFF',
        position: 'absolute',
        alignSelf: 'center',
        justifyContent: 'center',
        top: 100,
    },
    inputContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 10,
        marginVertical: 5,
        width:"100%"
    },
    input: {
        flex: 1,
        height: 50,
        borderBottomWidth: 0.5,
        paddingStart: 10
    },
    uploadIcContainer: {
        flexDirection: 'row',
        alignItems: 'flex-end',
        paddingHorizontal: 10,
        marginVertical: 5,
        width:"100%"
    },
    imageIC: {
        width: '200%',
        height: undefined,
        maxHeight: 200,
        aspectRatio: 1,
        resizeMode: 'contain'
    },
    registerBtn: {
        marginTop: 5,
        alignSelf: 'flex-end',
        marginEnd: 5
    },
    registerText: {
        fontSize: 13,
        color: '#98A5FD',
        fontWeight: 'bold'
    },
    outlineBtn: {
        width: '95%',
        borderRadius: 20,
        borderWidth:1,
        borderColor:"#ff0033",
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        marginTop: 20,
        padding: 10,
    },
    outlineText: {
        fontSize: 15,
        color: '#ff0033'
    },
    updateText: {
        fontSize: 15,
        color: '#ffffff'
    },
    footer: {
        width: '100%',
        position: 'absolute',
        backgroundColor: '#F7F7F7',
        bottom: -50,
        alignItems: 'center'
    },
    footerText: {
        fontSize: 15,
        color: '#FEE16E'
    },
    text: {
        color: 'gray',
        marginVertical:10,
    },
    link: {
        color: '#FDB075'
    },
    container: {
        flex: 1,
        
    },
    checkboxContainer: {
        flexDirection: "row",
        marginBottom: 5,
    },
    
    checkbox: {
        alignSelf: "center",
    },
    chooseBtn: {
        borderWidth:1,
        padding:3,
        borderColor:"#ff0033",
        backgroundColor:"#ff0033",
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        alignSelf: 'flex-start',
        borderRadius:5,
    },
    updateBtn: {
        borderWidth:1,
        padding:3,
        borderColor:"#ff0033",
        backgroundColor:"#ff0033",
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        alignSelf: 'flex-end',
        borderRadius:5,
    },
    progressContainer: {
        flex: 1,
        flexDirection: "column", //column direction
        justifyContent: 'center',
        alignItems: 'center',
        padding: 8,
        margin:10
      },
       progressBar: {
        height: 20,
        width: '80%',
        backgroundColor: '#ff0033',
        borderColor: '#000',
        borderWidth: 1,
        borderRadius: 20
      },
    firstView: {
        flex: 1.5,
        alignItems: 'center',
        width: '95%',
        borderRadius:10,
        backgroundColor:'#ff0033',
        margin: 10,
    },
    textAccount: {
        marginStart: 10,
        fontWeight:"bold",
        fontSize:18,
        marginTop:5,
    },
    footer:{
        alignSelf: 'center',
        marginTop: 20,
        marginBottom:10,
    },
})